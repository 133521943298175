<template>
  <MenuCard class="rounded" name="dataLayers" icon="layer-group">
    <template #header="{ collapsed }">
      <div class="flex items-center space-x-2">
        <span class="flex-1">
          {{ $t("dataLayers.title") }}
        </span>
        <span v-if="collapsed">
          <DataLayerValue
            :layer="currentDataLayer"
            :value="getMeanForVisibleSites(currentDataLayer)"
          />
        </span>
        <v-icon ref="blip" name="circle" class="blip" />
      </div>
    </template>

    <template #footer>
      <div v-if="draggableDataLayers.length">
        <draggable v-model="draggableDataLayers" handle=".handle">
          <div
            v-for="layer of draggableDataLayers"
            :key="layer.id"
            class="flex h-8 px-1 space-x-2 items-stretch rounded from-surface-accented to-transparent hover:bg-opacity-50 hover:bg-surface-accented"
            :class="{ 'bg-gradient-to-r': layer.id === currentDataLayer.id }"
          >
            <div class="handle flex items-center text-muted opacity-25 cursor-grab">
              <v-icon name="grip-vertical" />
            </div>
            <DataLayerRow
              :layer="layer"
              :value="getMeanForVisibleSites(layer)"
              class="flex-1 justify-between cursor-pointer rounded transition-all"
              @click="currentDataLayer = layer"
            />
            <FcButton sm icon="cog" class="p-0" @click="() => toggleEditModal(layer)" />
          </div>
        </draggable>
      </div>
    </template>
    <div class="flex-1 text-left">
      <FcButton
        xs
        class="hover:bg-primary hover:bg-opacity-20"
        icon="layer-group"
        @click="toggleAddModal()"
      >
        {{ $t("dataLayers.form.add") }}
      </FcButton>
      <FcButton
        xs
        class="hover:bg-primary hover:bg-opacity-20"
        icon="layer-group"
        @click="toggleResetModal()"
      >
        {{ $t("dataLayers.reset") }}
      </FcButton>
      <FcModal md ref="modalAddLayer" v-model="isAddModalShown" v-slot="{ modal }">
        <FcCard>
          <template #header>
            <div class="flex items-center">
              <div class="flex-1">
                {{ $t("dataLayers.form.add") }}
              </div>
              <FcButton
                sm
                icon="times"
                class="p-1 opacity-50 hover:opacity-100"
                icon-class="text-muted"
                @click="modal.close()"
              />
            </div>
          </template>
          <FormDataLayer @submit="onSubmitAddDataLayer" />
        </FcCard>
      </FcModal>
      <FcModal md ref="modalEditLayer" v-model="isEditModalShown" v-slot="{ modal }">
        <FcCard>
          <template #header>
            <div class="flex items-center">
              <div class="flex-1">
                {{ $t("dataLayers.form.edit") }}
              </div>
              <FcButton
                sm
                icon="times"
                class="p-1 opacity-50 hover:opacity-100"
                icon-class="text-muted"
                @click="modal.close()"
              />
            </div>
          </template>
          <FormDataLayer
            :base-layer="editLayer"
            @submit="onSubmitEditDataLayer"
            @delete="onDeleteEditDataLayer"
          />
        </FcCard>
      </FcModal>
      <FcModal md ref="modalReset" v-model="isResetModalShown" v-slot="{ modal }">
        <FcCard>
          <template #header>
            <div class="flex items-center">
              <div class="flex-1">
                {{ $t("dataLayers.reset") }}
              </div>
              <FcButton
                sm
                icon="times"
                class="p-1 opacity-50 hover:opacity-100"
                icon-class="text-muted"
                @click="modal.close()"
              />
            </div>
          </template>
          <div class="text-center py-3">
            {{ $t("dataLayers.resetConfirm") }}
          </div>
          <div class="flex">
            <FcButton
              icon="times"
              class="flex-1 justify-center hover:bg-danger hover:bg-opacity-20 px-2"
              icon-class="text-danger"
              @click="() => toggleResetModal(false)"
            >
              {{ $t("actions.cancel") }}
            </FcButton>
            <FcButton
              class="flex-1 justify-center hover:bg-success hover:bg-opacity-20 px-2"
              icon-class="text-success"
              icon="check"
              @click="resetToDefaultLayers"
            >
              {{ $t("actions.reset") }}
            </FcButton>
          </div>
        </FcCard>
      </FcModal>
    </div>
  </MenuCard>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import draggable from "vuedraggable"
import FormDataLayer from "~/components/form/FormDataLayer.vue"
import MenuCard from "~/components/menus/MenuCard.vue"
import DataLayerRow from "./DataLayerRow.vue"
import DataLayerValue from "./DataLayerValue.vue"

import { sleep } from "~/utils"

export default {
  components: { DataLayerRow, FormDataLayer, draggable, MenuCard, DataLayerValue },

  data: () => ({
    isAddModalShown: false,
    isResetModalShown: false,
    editLayer: null,
  }),

  computed: {
    ...mapState("layers", {
      lastUpdated: "lastUpdated",
    }),
    ...mapGetters({
      sitesVisible: "map/sitesVisible",
      dataLayers: "layers/dataLayerList",
    }),
    draggableDataLayers: {
      get() {
        return this.dataLayers
      },
      set(val) {
        this.$store.commit(
          "layers/setDataLayerOrder",
          val.map(l => l.id)
        )
      },
    },
    isEditModalShown: {
      get() {
        return this.editLayer !== null
      },
      set(val) {
        if (!val) {
          this.editLayer = null
        }
      },
    },
    currentDataLayer: {
      get() {
        return this.$store.getters["layers/currentDataLayer"] || {}
      },
      set(val) {
        this.$store.commit("layers/setCurrentDataLayer", val)
      },
    },
  },

  watch: {
    async lastUpdated() {
      this.$refs.blip.$el.classList.remove("blip-animation")
      await sleep(1)
      this.$refs.blip.$el.classList.add("blip-animation")
    },
  },

  methods: {
    getMeanForVisibleSites(layer) {
      const siteValues = this.sitesVisible
        .map(site => layer.getValue(site))
        .filter(m => !isNaN(m.value))
      const value = siteValues.reduce((acc, mean) => acc + mean.value, 0) / siteValues.length
      return value
    },

    toggleAddModal(open = null) {
      this.isAddModalShown = open ?? !this.isAddModalShown
    },

    toggleResetModal(open = null) {
      this.isResetModalShown = open ?? !this.isResetModalShown
    },

    toggleEditModal(layer) {
      this.editLayer = layer ? layer : null
    },

    onSubmitAddDataLayer(dataLayer) {
      this.$store.commit("layers/createDataLayer", dataLayer)
      this.toggleAddModal(false)
    },

    onSubmitEditDataLayer(dataLayer) {
      dataLayer.resetData()
      this.$store.commit("layers/updateDataLayer", dataLayer)
      this.toggleEditModal(false)
    },

    onDeleteEditDataLayer() {
      this.$store.commit("layers/removeDataLayer", this.editLayer)
      this.toggleEditModal(false)
    },

    resetToDefaultLayers() {
      this.$store.commit("layers/resetToDefaultLayers")
      this.toggleResetModal(false)
    },
  },
}
</script>

<style lang="postcss" scoped>
.blip {
  color: theme("colors.surface.accented");
  filter: drop-shadow(0 0 1px theme("colors.surface.accented"));
  font-size: 0.7em;
}

.blip-animation {
  animation: blip 1s ease-in 0s;
}

@keyframes blip {
  from {
    color: rgb(246, 255, 193);
    filter: drop-shadow(0 0 6px rgb(165, 255, 47));
  }
}
</style>
