var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "layerForm",
      staticClass: "py-2 space-y-4",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "space-y-2" },
        [
          _c("div", { staticClass: "text-xs font-bold uppercase" }, [
            _vm._v(" " + _vm._s(_vm.$t("dataLayers.form.info")) + " ")
          ]),
          _c(
            "div",
            [
              _c(
                "FcInputToggle",
                {
                  model: {
                    value: _vm.active,
                    callback: function($$v) {
                      _vm.active = $$v
                    },
                    expression: "active"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("dataLayers.form.active")) + " ")]
              )
            ],
            1
          ),
          _c("FcInputText", {
            staticClass: "block",
            attrs: { label: _vm.$t("dataLayers.form.name") },
            model: {
              value: _vm.layerName,
              callback: function($$v) {
                _vm.layerName = $$v
              },
              expression: "layerName"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "space-y-3" },
        [
          _c("div", { staticClass: "text-xs font-bold uppercase" }, [
            _vm._v(" " + _vm._s(_vm.$tc("dataLayers.form.dataSources")) + " ")
          ]),
          _c("FcInputSelect", {
            staticClass: "block",
            attrs: {
              items: _vm.moduleItems,
              label: _vm.$t("dataLayers.form.dataModule")
            },
            model: {
              value: _vm.layerModule,
              callback: function($$v) {
                _vm.layerModule = $$v
              },
              expression: "layerModule"
            }
          }),
          _vm._l(_vm.dataSources.entries(), function(ref) {
            var index = ref[0]
            var s = ref[1]
            return _c(
              "div",
              { key: s.__key },
              [
                _c("div", { staticClass: "text-xs uppercase font-bold" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$tc("dataLayers.form.dataSource")) + " "
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dataSources.length > 1,
                          expression: "dataSources.length > 1"
                        }
                      ]
                    },
                    [_vm._v(" x"), _c("sub", [_vm._v(_vm._s(index + 1))])]
                  )
                ]),
                _c("DataSourceInput", {
                  staticClass:
                    "pt-1 ml-1 pl-2 border-l-2 border-surface-accented",
                  attrs: { value: s },
                  on: {
                    input: function(n) {
                      return _vm.updateDataSource(s, n)
                    }
                  }
                })
              ],
              1
            )
          }),
          _c("FcInputSelect", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.dataSources.length > 1,
                expression: "dataSources.length > 1"
              }
            ],
            staticClass: "block",
            attrs: {
              items: _vm.calculationItems,
              label: _vm.$t("dataLayers.form.calculation")
            },
            scopedSlots: _vm._u([
              {
                key: "entry",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("span", { domProps: { innerHTML: _vm._s(item.text) } })
                  ]
                }
              }
            ]),
            model: {
              value: _vm.calculation,
              callback: function($$v) {
                _vm.calculation = $$v
              },
              expression: "calculation"
            }
          }),
          _c(
            "FcButton",
            {
              staticClass:
                "w-full border-2 border-dashed border-input-accented hover:bg-primary hover:bg-opacity-20",
              attrs: { sm: "", icon: "plus" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.addDataSource.apply(null, arguments)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$tc("dataLayers.form.addSource")) + " ")]
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "space-y-2" },
        [
          _c("div", { staticClass: "text-xs font-bold uppercase" }, [
            _vm._v(" " + _vm._s(_vm.$tc("dataLayers.form.thresholds")) + " ")
          ]),
          _vm._l(_vm.thresholds, function(t) {
            return _c(
              "div",
              { key: t.__key, staticClass: "flex items-center space-x-2" },
              [
                _c("ThresholdInput", {
                  staticClass: "flex-1",
                  attrs: { value: t.value.toString(), type: t.type, op: t.op },
                  on: {
                    input: function(n) {
                      return _vm.updateThreshold(t, n)
                    }
                  }
                }),
                _c("FcButton", {
                  staticClass:
                    "hover:bg-danger hover:bg-opacity-20 rounded-full flex self-center justify-center w-8 h-8",
                  attrs: { icon: "times", "icon-class": "text-danger" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.removeThreshold(t)
                    }
                  }
                })
              ],
              1
            )
          }),
          _c(
            "FcButton",
            {
              staticClass:
                "w-full border-2 border-dashed border-input-accented hover:bg-primary hover:bg-opacity-20",
              attrs: { sm: "", icon: "plus" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.addThreshold.apply(null, arguments)
                }
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$tc("dataLayers.form.addThreshold")) + " "
              )
            ]
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "flex justify-end" },
        [
          _c(
            "FcButton",
            {
              staticClass:
                "order-last hover:bg-success hover:bg-opacity-20 px-2",
              attrs: {
                icon: "save",
                "icon-class": "text-success",
                type: "submit"
              }
            },
            [_vm._v(" " + _vm._s(_vm.$tc("actions.save")) + " ")]
          ),
          !_vm.isNewLayer
            ? _c(
                "FcButton",
                {
                  staticClass: "hover:bg-danger hover:bg-opacity-20 px-2",
                  attrs: {
                    icon: "trash-alt",
                    "icon-class": "text-danger",
                    type: "submit",
                    value: "delete"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$tc("actions.delete")) + " ")]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }