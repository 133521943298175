<template>
  <form ref="layerForm" @submit.prevent="onSubmit" class="py-2 space-y-4">
    <div class="space-y-2">
      <div class="text-xs font-bold uppercase">
        {{ $t("dataLayers.form.info") }}
      </div>
      <div>
        <FcInputToggle v-model="active">
          {{ $t("dataLayers.form.active") }}
        </FcInputToggle>
      </div>
      <FcInputText v-model="layerName" class="block" :label="$t('dataLayers.form.name')" />
    </div>
    <div class="space-y-3">
      <div class="text-xs font-bold uppercase">
        {{ $tc("dataLayers.form.dataSources") }}
      </div>
      <FcInputSelect
        v-model="layerModule"
        class="block"
        :items="moduleItems"
        :label="$t('dataLayers.form.dataModule')"
      />
      <div v-for="[index, s] of dataSources.entries()" :key="s.__key" class="">
        <div class="text-xs uppercase font-bold">
          {{ $tc("dataLayers.form.dataSource") }}
          <span v-show="dataSources.length > 1">
            x<sub>{{ index + 1 }}</sub>
          </span>
        </div>
        <DataSourceInput
          :value="s"
          @input="n => updateDataSource(s, n)"
          class="pt-1 ml-1 pl-2 border-l-2 border-surface-accented"
        />
      </div>
      <FcInputSelect
        v-show="dataSources.length > 1"
        v-model="calculation"
        class="block"
        :items="calculationItems"
        :label="$t('dataLayers.form.calculation')"
      >
        <template #entry="{item}">
          <span v-html="item.text" />
        </template>
      </FcInputSelect>
      <FcButton
        sm
        class="w-full border-2 border-dashed border-input-accented hover:bg-primary hover:bg-opacity-20"
        icon="plus"
        @click.prevent="addDataSource"
      >
        {{ $tc("dataLayers.form.addSource") }}
      </FcButton>
    </div>
    <div class="space-y-2">
      <div class="text-xs font-bold uppercase">
        {{ $tc("dataLayers.form.thresholds") }}
      </div>
      <div v-for="t of thresholds" :key="t.__key" class="flex items-center space-x-2">
        <ThresholdInput
          :value="t.value.toString()"
          :type="t.type"
          :op="t.op"
          @input="n => updateThreshold(t, n)"
          class="flex-1"
        />
        <FcButton
          icon="times"
          class="hover:bg-danger hover:bg-opacity-20 rounded-full flex self-center justify-center w-8 h-8"
          icon-class="text-danger"
          @click.prevent="removeThreshold(t)"
        />
      </div>
      <FcButton
        sm
        class="w-full border-2 border-dashed border-input-accented hover:bg-primary hover:bg-opacity-20"
        icon="plus"
        @click.prevent="addThreshold"
      >
        {{ $tc("dataLayers.form.addThreshold") }}
      </FcButton>
    </div>
    <div class="flex justify-end">
      <FcButton
        class="order-last hover:bg-success hover:bg-opacity-20 px-2"
        icon="save"
        icon-class="text-success"
        type="submit"
      >
        {{ $tc("actions.save") }}
      </FcButton>
      <FcButton
        v-if="!isNewLayer"
        class="hover:bg-danger hover:bg-opacity-20 px-2"
        icon="trash-alt"
        icon-class="text-danger"
        type="submit"
        value="delete"
      >
        {{ $tc("actions.delete") }}
      </FcButton>
    </div>
  </form>
</template>

<script>
import ThresholdInput from "./ThresholdInput.vue"
import DataSourceInput from "./DataSourceInput.vue"

import { DataLayer, DataSource, Threshold } from "~/models"

export default {
  components: { ThresholdInput, DataSourceInput },

  props: {
    baseLayer: {
      type: DataLayer,
      default: null,
    },
  },

  data: () => ({
    active: true,
    layerName: "",
    layerModule: "fiksu",
    dataSources: [],
    calculation: DataLayer.CALC_MEAN,
    thresholds: [],
    dataSourceKey: 0,
    thresholdKey: 0,
  }),

  computed: {
    calculationItems() {
      return [
        {
          text:
            this.$t("dataLayers.form.mean") +
            " <small>(x<sub>1</sub> + x<sub>2</sub> + ... + x<sub>n</sub>) / n</small>",
          value: DataLayer.CALC_MEAN,
        },
        {
          text:
            this.$t("dataLayers.form.sum") +
            " <small>x<sub>1</sub> + x<sub>2</sub> + ... + x<sub>n</sub></small>",
          value: DataLayer.CALC_SUM,
        },
        {
          text:
            this.$t("dataLayers.form.difference") +
            " <small>x<sub>1</sub> - x<sub>2</sub> - ... - x<sub>n</sub></small>",
          value: DataLayer.CALC_DIFFERENCE,
        },
      ]
    },

    moduleItems() {
      return ["fiksu"]
    },

    isNewLayer() {
      return this.baseLayer === null
    },
  },

  created() {
    if (!this.isNewLayer) {
      this.active = this.baseLayer.active
      this.layerName = this.baseLayer.name
      this.layerModule = this.baseLayer.module
      this.calculation = this.baseLayer.calculation
      this.dataSources = this.baseLayer.dataSources.map(s => DataSource.load(s))
      for (const s of this.dataSources) {
        s.__key = ++this.dataSourceKey
      }
      this.thresholds = this.baseLayer.thresholds.map(t => Threshold.load(t))
      for (const t of this.thresholds) {
        t.__key = ++this.thresholdKey
      }
    }
  },

  methods: {
    onSubmit(evt) {
      if (evt.submitter.value === "delete") {
        this.$emit("delete")
      } else {
        const dataLayer = DataLayer.load({
          id: this.baseLayer?.id ?? null,
          active: this.active,
          name: this.layerName,
          dataSources: this.dataSources,
          module: this.layerModule,
          calculation: this.calculation,
          thresholds: this.thresholds,
        })
        if (!this.isNewLayer) {
          dataLayer._ver = this.baseLayer._ver + 1
        }
        this.$emit("submit", dataLayer)
      }
    },
    reset() {
      this.$refs.layerForm.reset()
    },
    addThreshold() {
      const threshold = Threshold.load({})
      threshold.__key = ++this.thresholdKey
      this.thresholds.push(threshold)
    },
    removeThreshold(t) {
      const i = this.thresholds.findIndex(x => x === t)
      if (i > -1) {
        this.thresholds.splice(i, 1)
      }
    },
    updateThreshold(oldThreshold, newThreshold) {
      oldThreshold.type = newThreshold.type
      oldThreshold.op = newThreshold.op
      oldThreshold.value = newThreshold.value
      this.thresholds = [...this.thresholds]
    },
    addDataSource() {
      const dataSource = DataSource.load({ priorityList: [{}] })
      dataSource.__key = ++this.dataSourceKey
      this.dataSources.push(dataSource)
    },
    removeDataSource(dataSource) {
      const i = this.dataSources.indexOf(dataSource)
      this.dataSources = [...this.dataSources.slice(0, i), ...this.dataSources.slice(i + 1)]
    },
    updateDataSource(oldDataSource, newDataSource) {
      if (newDataSource.priorityList.length > 0) {
        oldDataSource.priorityList = newDataSource.priorityList
        this.dataSources = [...this.dataSources]
      } else {
        this.removeDataSource(oldDataSource)
      }
    },
  },
}
</script>
