var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FcButton",
        {
          staticClass: "bg-surface text-accented pr-2 rounded",
          attrs: { sm: "" },
          on: {
            click: function() {
              return _vm.toggleSiteSummaryModal()
            }
          }
        },
        [
          _c("v-icon", {
            staticClass: "text-accented pr-1",
            attrs: { name: "table" }
          }),
          _vm._v(" Site Summary ")
        ],
        1
      ),
      _c("FcModal", {
        attrs: {
          xl: "",
          "div-class": "bg-surface divide-y divide-surface-accented"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var modal = ref.modal
              return [
                _c(
                  "FcCard",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { staticClass: "flex items-center" },
                                [
                                  _c("div", { staticClass: "flex-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("dataLayers.form.summary")
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("FcButton", {
                                    staticClass:
                                      "p-1 opacity-50 hover:opacity-100",
                                    attrs: {
                                      sm: "",
                                      icon: "times",
                                      "icon-class": "text-muted"
                                    },
                                    on: {
                                      click: function($event) {
                                        return modal.close()
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c("div", [
                      _vm.dataLayers.length
                        ? _c("div", [
                            _c(
                              "table",
                              { staticClass: "table table-auto text-left" },
                              [
                                _c(
                                  "thead",
                                  {
                                    staticClass:
                                      "sticky top-0 items-start bg-primary-muted"
                                  },
                                  [
                                    _c(
                                      "tr",
                                      [
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "text-sm font-medium px-6 py-2",
                                            attrs: { scope: "col" }
                                          },
                                          [_vm._v("Site Names")]
                                        ),
                                        _vm._l(_vm.dataLayers, function(layer) {
                                          return _c(
                                            "th",
                                            {
                                              key: layer.id,
                                              staticClass:
                                                "text-sm font-medium px-6 py-2",
                                              attrs: { scope: "col" }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(layer.name) + " "
                                              )
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                ),
                                _c(
                                  "tbody",
                                  {},
                                  _vm._l(_vm.sitesOnline, function(site) {
                                    return _c(
                                      "tr",
                                      { key: site.id },
                                      [
                                        _c(
                                          "td",
                                          { staticClass: "text-sm px-6 py-2" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(site.name) + " "
                                            )
                                          ]
                                        ),
                                        _vm._l(_vm.dataLayers, function(layer) {
                                          return _c(
                                            "td",
                                            {
                                              key: layer.id,
                                              staticClass: "text-sm px-6 py-2"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getData(layer, site)
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.isSiteSummaryShown,
          callback: function($$v) {
            _vm.isSiteSummaryShown = $$v
          },
          expression: "isSiteSummaryShown"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }