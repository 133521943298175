<template>
  <div>
    <FcButton
      sm
      class="bg-surface text-accented pr-2 rounded"
      @click="() => toggleSiteSummaryModal()"
    >
      <v-icon name="table" class="text-accented pr-1" />
      Site Summary
    </FcButton>
    <FcModal
      xl
      div-class="bg-surface divide-y divide-surface-accented"
      v-model="isSiteSummaryShown"
      v-slot="{ modal }"
    >
      <FcCard>
        <template #header>
          <div class="flex items-center">
            <div class="flex-1">
              {{ $t("dataLayers.form.summary") }}
            </div>
            <FcButton
              sm
              icon="times"
              class="p-1 opacity-50 hover:opacity-100"
              icon-class="text-muted"
              @click="modal.close()"
            />
          </div>
        </template>
        <div>
          <div v-if="dataLayers.length">
            <table class="table table-auto text-left">
              <thead class="sticky top-0 items-start bg-primary-muted">
                <tr>
                  <th scope="col" class="text-sm font-medium px-6 py-2">Site Names</th>
                  <th
                    scope="col"
                    class="text-sm font-medium px-6 py-2"
                    v-for="layer of dataLayers"
                    :key="layer.id"
                  >
                    {{ layer.name }}
                  </th>
                </tr>
              </thead>
              <tbody class="">
                <tr v-for="site of sitesOnline" :key="site.id">
                  <td class="text-sm px-6 py-2">
                    {{ site.name }}
                  </td>
                  <td class="text-sm px-6 py-2" v-for="layer of dataLayers" :key="layer.id">
                    {{ getData(layer, site) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </FcCard>
    </FcModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data: () => ({
    isSiteSummaryShown: false,
  }),
  computed: {
    ...mapGetters({
      sitesOnline: "map/sitesOnline",
      dataLayerList: "layers/dataLayerList",
    }),
    dataLayers: {
      get() {
        return this.dataLayerList
      },
    },
  },
  methods: {
    toggleSiteSummaryModal(open = null) {
      this.isSiteSummaryShown = open ?? !this.isSiteSummaryShown
    },
    getData(layer, site) {
      const siteValues = layer.getValue(site)
      return isNaN(siteValues.value)
        ? "-"
        : Number.parseFloat(siteValues.value.toFixed(2)) + " " + siteValues.unit
    },
  },
}
</script>

<style></style>
