import deepClone from "./deepClone"

class ResourceError extends Error {
  constructor(response, content) {
    super(content.message)
    this.response = response
    this.status = response.status
    this.content = content
  }
}

class Resource {
  constructor(parent, name) {
    this._parent = parent
    this._name = `${name}`
    this._children = {}
  }

  get conf() {
    return this._parent.conf
  }
  get interceptors() {
    return this._parent.interceptors
  }

  _addChild(name, shortcut = this.conf.shortcuts) {
    this._children[name] = new Resource(this, name)
    if (shortcut) {
      this[name] = this._children[name]
    }
    return this._children[name]
  }

  define(resources, shortcut = this.conf.shortcuts) {
    const addResource = function(parent, path, shortcut) {
      const resource = new Resource(parent, path)
      if (shortcut) {
        if (path in parent) {
          throw new Error(
            `Shortcut name conflict: Resource "${parent._name}" already has property "${path}"`
          )
        }
        parent[path] = function(id) {
          const subResource = new Resource(resource, id)
          for (const name of Object.keys(resource._children)) {
            subResource._addChild(name, shortcut)
          }
          return subResource
        }
        const methods = p => typeof resource[p] === "function"
        const propNames = Object.getOwnPropertyNames(resource.constructor.prototype)
        for (const i of propNames.filter(methods)) {
          parent[path][i] = resource[i].bind(resource)
        }
      }
      parent._children[path] = resource
      return resource
    }

    if (typeof resources === "string") {
      return addResource(this, resources, shortcut)
    } else if (Array.isArray(resources)) {
      return resources.map(name => addResource(this, name, shortcut))
    } else if (typeof resources === "object") {
      const res = {}
      for (const name in resources) {
        const resource = addResource(this, name, shortcut)
        if (resources[name]) {
          resource.define(resources[name], shortcut)
        }
        res[name] = resource
      }
      return res
    }
    throw new TypeError("Invalid resource name argument type")
  }

  getURL(searchParams = {}) {
    const trailingSlash = /\/$/
    const parentURL = this._parent?.getURL() ?? ""
    const path = [parentURL.toString().replace(trailingSlash, ""), this._name]
      .filter(Boolean)
      .join("/")
      .replace(trailingSlash, "")
    const url = new URL(path, this.conf.host)
    for (const [key, value] of Object.entries(searchParams)) {
      if (this.conf.autoSearchParamBrackets && Array.isArray(value)) {
        const arrayKey = !key.endsWith("[]") ? key + "[]" : key
        value.forEach(v => url.searchParams.append(arrayKey, v))
      } else {
        url.searchParams.append(key, value)
      }
    }
    return url
  }

  equalsURL(url) {
    try {
      const url1 = this.getURL()
      const url2 = new URL(url)
      return url1.origin + url1.pathname === url2.origin + url2.pathname
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async _request(requestProps = {}) {
    const props = deepClone({ ...this.conf.fetch }, requestProps)
    const url = this.getURL(props.searchParams)
    delete props.searchParams

    const req = { url, props }

    let _abort = false
    const abort = () => {
      _abort = true
    }

    for (const interceptor of this.interceptors.request.stack) {
      await interceptor({ req, abort })
      if (_abort) {
        return
      }
    }

    let [mime] = (props.headers["Content-Type"] || "").split(";")
    let schema = this.conf.schemas[mime]
    if (props.body && schema && schema.encode) {
      if (typeof props.body.serialize === "function") {
        props.body = props.body.serialize()
      }
      if (schema && schema.encode) {
        props.body = schema.encode(props.body)
      }
    }
    if (mime === "multipart/form-data") {
      // Allow the browser to handle formdata boundaries
      delete props.headers["Content-Type"]
    }

    const res = await fetch(url, props)
    const text = await res.text()

    mime = (res.headers.get("Content-Type") || "").split(";")
    schema = this.conf.schemas[mime]

    let content = text && schema && schema.decode ? schema.decode(text) : text

    const replay = () => {
      content = this._request(requestProps)
      _abort = true
    }

    for (const interceptor of this.interceptors.response.stack) {
      await interceptor({ res, req, content, abort, replay })
      if (_abort) {
        break
      }
    }

    if (!res.ok) {
      throw new ResourceError(res, content)
    }
    return content
  }

  get(searchParams = {}) {
    return this._request({ method: "GET", searchParams })
  }

  post(body = {}, contentType = this.conf.contentType) {
    return this._request({
      method: "POST",
      body,
      headers: { "Content-Type": contentType },
    })
  }

  put(body = {}, contentType = this.conf.contentType) {
    return this._request({
      method: "PUT",
      body,
      headers: { "Content-Type": contentType },
    })
  }

  patch(body = {}, contentType = this.conf.contentType) {
    return this._request({
      method: "PATCH",
      body,
      headers: { "Content-Type": contentType },
    })
  }

  delete() {
    return this._request({ method: "DELETE" })
  }
}

export default Resource
