var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "flex items-center" }, [
    _c(
      "input",
      _vm._b(
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputValue,
              expression: "inputValue"
            }
          ],
          ref: "check",
          staticClass: "input-check hidden",
          attrs: { type: "checkbox" },
          domProps: {
            value: _vm.checkValue,
            checked: Array.isArray(_vm.inputValue)
              ? _vm._i(_vm.inputValue, _vm.checkValue) > -1
              : _vm.inputValue
          },
          on: {
            change: function($event) {
              var $$a = _vm.inputValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = _vm.checkValue,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.inputValue = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.inputValue = $$c
              }
            }
          }
        },
        "input",
        _vm.$attrs,
        false
      )
    ),
    _c(
      "div",
      {
        staticClass:
          "inline-block cursor-pointer rounded-full h-4 w-7 border-2 border-muted2 relative overflow-hidden"
      },
      [
        _c("div", {
          staticClass:
            "h-full absolute bg-primary-muted opacity-60 transition-all",
          class: !_vm.value ? "w-0" : "w-full"
        }),
        _c("div", {
          staticClass:
            "rounded-full w-1/2 h-full absolute transition-all opacity bg-normal",
          class: !_vm.value ? "right-1/2" : "right-0"
        })
      ]
    ),
    _vm.$slots.default
      ? _c("div", { staticClass: "inline-block ml-1" }, [_vm._t("default")], 2)
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }