import { set } from "~/lib/VuexHelpers"

const state = () => ({
  hasToken: false,
  username: "",
})

const getters = {}

const mutations = {
  setUsername: set("username"),
  setHasToken: set("hasToken"),
}

const actions = {
  setToken({ commit }, token = {}) {
    const username = token.preferred_username ?? token.username ?? ""
    commit("setUsername", username)
    commit("setHasToken", !!username)
  },

  logout({ commit }) {
    commit("setUsername", "")
    commit("setHasToken", false)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
