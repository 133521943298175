// settings
import "vue-awesome/icons/cog"
import "vue-awesome/icons/user"
import "vue-awesome/icons/user-cog"

// login
import "vue-awesome/icons/sign-in-alt"
import "vue-awesome/icons/sign-out-alt"

// user settings
import "vue-awesome/icons/user-cog"

// favorite
import "vue-awesome/icons/star"
import "vue-awesome/icons/regular/star"

// layer
import "vue-awesome/icons/layer-group"

// alarms
import "vue-awesome/icons/exclamation-triangle"
// filter funnel
import "vue-awesome/icons/filter"

// table 
import "vue-awesome/icons/table"

// input related
import "vue-awesome/icons/circle"
import "vue-awesome/icons/regular/circle"
import "vue-awesome/icons/dot-circle"
import "vue-awesome/icons/caret-down"
import "vue-awesome/icons/plus"
import "vue-awesome/icons/check"
import "vue-awesome/icons/times"
import "vue-awesome/icons/save"
import "vue-awesome/icons/trash-alt"
import "vue-awesome/icons/pen"
import "vue-awesome/icons/grip-vertical"

import "vue-awesome/icons/search"
import "vue-awesome/icons/tachometer-alt"
import "vue-awesome/icons/map-marker-alt"
// import "vue-awesome/icons/exclamation-triangle"


