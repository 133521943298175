import { BaseModel, Field } from "~/lib/Model"

export class DataSourcePoint extends BaseModel {
  get fields() {
    return {
      device: Field.String({ defaultValue: "" }),
      position: Field.String({ defaultValue: "" }),
    }
  }
}

export class DataSource extends BaseModel {
  get fields() {
    return {
      priorityList: Field.List(Field.Nested(DataSourcePoint), { defaultValue: [] }),
      // module: Field.String({ defaultValue: "fiksu" }),
    }
  }
}
