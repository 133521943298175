function deepClone(target, ...sources) {
  for (const source of sources) {
    for (const i in source) {
      if (
        i in target &&
        typeof source[i] === 'object' &&
        typeof target[i] === 'object'
      ) {
        deepClone(target[i], source[i])
      } else {
        target[i] = source[i]
      }
    }
  }
  return target
}

export default deepClone
