var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _c("NavLayerMobile", {
        staticClass: "absolute bottom-16 h-18 w-full z-20 flex md:hidden"
      }),
      _c(
        "div",
        { staticClass: "absolute z-30 w-96 max-w-full p-5" },
        [
          _c("FcInputAutoComplete", {
            staticClass: "hidden md:block",
            attrs: { items: _vm.searchItems },
            on: {
              input: function(site) {
                return site && _vm.$map.goToSite(site)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function() {
                  return [
                    _c("v-icon", {
                      staticClass: "mr-1",
                      attrs: { name: "search" }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("siteSearch.label")) + " ")
                  ]
                },
                proxy: true
              },
              {
                key: "entry",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", [_vm._v(_vm._s(item.value.name))]),
                    _c("div", { staticClass: "text-xs" }, [
                      _vm._v(_vm._s(item.value.address))
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "z-20 fixed w-full" },
        [
          _c("DataLayerRow", {
            staticClass:
              "\n        md:hidden\n        justify-between\n        cursor-pointer\n        rounded-full\n        transition-all\n        bg-surface\n        w-3/4\n        m-auto\n        py-1.5\n        px-5\n        relative\n        top-5\n      ",
            attrs: {
              layer: _vm.currentLayer,
              mean: _vm.getMeanForVisibleSites(_vm.currentLayer)
            },
            on: {
              click: function($event) {
                return _vm.showNextLayer()
              }
            }
          })
        ],
        1
      ),
      _c("SiteSummary", {
        staticClass: "hidden md:block absolute top-7 right-7 h-18 w-18 z-30"
      }),
      _c("FavoritesCard", {
        staticClass: "hidden md:block absolute bottom-16 left-4 h-18 w-18 z-20"
      }),
      _c("SiteStatusCard", {
        staticClass: "hidden md:block absolute bottom-2 left-4 h-18 w-18 z-20"
      }),
      _c("div", { ref: "maproot", staticClass: "w-full h-full z-10" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }