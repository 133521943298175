var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "flex flex-col py-3 px-4 space-y-2 md:space-y-3" },
    [
      _c("div", { staticClass: "px-4" }, [
        _c("div", { staticClass: "flex items-center" }, [
          _c(
            "div",
            { staticClass: "flex-1" },
            [
              _c("FiksuCityLogo", {
                staticClass: "w-full max-w-xs m-auto p-2",
                attrs: { dark: _vm.$theme.dark }
              })
            ],
            1
          ),
          _c("div", { staticClass: "flex-0" }, [_c("PreferencesMenu")], 1)
        ]),
        _c(
          "div",
          {
            staticClass:
              "font-mono text-center text-lg uppercase text-muted opacity-60 tracking-wider"
          },
          [_vm._v(" Beta ")]
        )
      ]),
      _c("div", { staticClass: "md:block space-y-2 px-2" }, [
        _c(
          "div",
          { staticClass: "flex mb-4" },
          _vm._l(_vm.menuCards.entries(), function(ref) {
            var i = ref[0]
            var card = ref[1]
            return _c(
              "FcButton",
              {
                key: i,
                staticClass: "hover:bg-normal hover:bg-opacity-10 flex-1",
                class: [
                  { "bg-normal bg-opacity-30": _vm.currentCard === card }
                ],
                attrs: {
                  lg: "",
                  icon: _vm.getCardIcon(card),
                  "icon-class": "text-normal",
                  "text-class": "block text-xs"
                },
                on: {
                  click: function($event) {
                    return _vm.setCard(card)
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t(card + ".title")) + " ")]
            )
          }),
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "md:block",
          class: { "flex-1 overflow-y-auto": _vm.currentCard !== "alarms" }
        },
        [
          _c(
            "FcCard",
            { staticClass: "rounded-lg", attrs: { handle: ".handle" } },
            [_c(_vm.getCardComponent(_vm.currentCard), { tag: "component" })],
            1
          )
        ],
        1
      ),
      _vm.currentCard === "alarms"
        ? _c(
            "div",
            { staticClass: "md:block flex-1 overflow-y-auto" },
            [
              _c(
                "FcCard",
                { staticClass: "rounded-lg", attrs: { handle: ".handle" } },
                [_c("AlarmsList")],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "text-center text-xs text-muted2 px-4" }, [
        _c("div", { staticClass: "text-center md:pb-2" }, [
          _c(
            "a",
            { attrs: { href: _vm.Config.fiksuWebUrl } },
            [
              _c("FiksuWebLogo", {
                staticClass: "hidden md:inline w-40",
                attrs: { dark: _vm.$theme.dark }
              })
            ],
            1
          )
        ]),
        _c("span", [_vm._v(_vm._s(_vm.Config.version))]),
        _vm._v(" | "),
        _c(
          "a",
          {
            staticClass: "cursor-pointer",
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.isReleaseNotesModalShown = !_vm.isReleaseNotesModalShown
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("releaseNotes.title")) + " ")]
        )
      ]),
      _c("FcModal", {
        attrs: { md: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var modal = ref.modal
              return [
                _c(
                  "FcCard",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { staticClass: "flex items-center" },
                                [
                                  _c("div", { staticClass: "flex-1 text-xl" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("releaseNotes.title")) +
                                        " "
                                    )
                                  ]),
                                  _c("FcButton", {
                                    staticClass:
                                      "p-1 opacity-50 hover:opacity-100",
                                    attrs: {
                                      sm: "",
                                      icon: "times",
                                      "icon-class": "text-muted"
                                    },
                                    on: {
                                      click: function($event) {
                                        return modal.close()
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_c("ReleaseNotes")],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.isReleaseNotesModalShown,
          callback: function($$v) {
            _vm.isReleaseNotesModalShown = $$v
          },
          expression: "isReleaseNotesModalShown"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }