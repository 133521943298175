var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    [
      _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue"
              }
            ],
            ref: "radio",
            staticClass: "input-radio hidden",
            attrs: { type: "radio" },
            domProps: {
              value: _vm.radioValue,
              checked: _vm._q(_vm.inputValue, _vm.radioValue)
            },
            on: {
              change: function($event) {
                _vm.inputValue = _vm.radioValue
              }
            }
          },
          "input",
          _vm.$attrs,
          false
        )
      ),
      _c("v-icon", {
        staticClass: "input-radio__circle",
        attrs: { name: "regular/circle" }
      }),
      _c("v-icon", {
        staticClass: "input-radio__checked",
        attrs: { name: "dot-circle" }
      }),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }