<template>
  <FcCard class="rounded" name="siteStatus">
    <i18n path="siteStatus.shortVisibleAndActive" tag="span" class="text-muted2">
      <template #visible>
        <span
          class="text-3xl font-bold"
          :class="tooManySitesVisible ? 'text-danger' : 'text-accented'"
        >
          {{ sitesVisibleCount }}
        </span>
      </template>
      <template #active>
        <span class="text-3xl font-bold text-accented">
          {{ sitesActiveCount }}
        </span>
      </template>
    </i18n>
  </FcCard>
</template>

<script>
import { mapGetters } from "vuex"
import config from "~/config"


export default {
  components: {  },

  computed: {
    ...mapGetters({
      sitesVisible: "map/sitesVisible",
      currentDataLayer: "layers/currentDataLayer",
    }),
    sitesVisibleCount() {
      return this.sitesVisible.length
    },
    sitesActiveCount() {
      return this.sitesVisible.filter(s => this.currentDataLayer?.hasSiteDiscoveredPoints(s)).length
    },
    tooManySitesVisible() {
      return this.sitesVisibleCount > config.siteLimit
    },
  },
}
</script>
