<template>
  <div class="flex items-center min-w-0 space-x-1" @click="$emit('click')">
    <div
      class="flex-1 truncate text-sm"
      :class="{ 'text-muted': layer.active, 'text-muted2': !layer.active }"
    >
      {{ layer.name }}
    </div>
    <DataLayerValue class="flex-0 text-xl font-bold" :layer="layer" :value="value" />
  </div>
</template>

<script>
import DataLayerValue from "./DataLayerValue.vue"
export default {
  components: { DataLayerValue },
  props: {
    layer: {
      type: Object,
      default: null,
    },
    value: {
      type: [String, Number],
      default: null,
    },
  },
}
</script>
