var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "fc-list",
      class: {
        "fc-list--dense": _vm.dense,
        "fc-list--flat": _vm.flat,
        "fc-list--selectable": _vm.selectable
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }