<template>
  <div
    v-show="show"
    class="fc-modal fixed z-10 inset-0"
    aria-labelledby="fc-modal__title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="fc-modal__overlay fixed inset-0 flex items-center justify-center"
      :class="{ 'opacity-0': !fade }"
      aria-hidden="true"
      @click.self="onOverlayClick"
    >
      <div
        class="fc-modal__container min-h-0 w-full max-w-full overflow-auto overscroll-contain rounded"
        :class="[{
          'max-w-screen-sm': sm,
          'max-w-screen-md': md,
          'max-w-screen-lg': lg,
          'max-w-screen-xl': xl,
        }, divClass]"
      >
        <slot v-if="eager || show" :modal="this" />
      </div>
    </div>
  </div>
</template>

<script>
import { sleep } from "~/utils"

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    eager: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    md: {
      type: Boolean,
      default: false,
    },
    lg: {
      type: Boolean,
      default: false,
    },
    xl: {
      type: Boolean,
      default: false,
    },
    divClass: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    fade: false,
    show: false,
  }),

  watch: {
    async value() {
      if (this.value) {
        await sleep(1)
        this.open()
      } else if (this.show) {
        await sleep(1)
        await this.close()
      }
    },
  },

  async mounted() {
    if (this.value) {
      await sleep(1)
      this.open()
    }
  },

  methods: {
    onOverlayClick() {
      if (!this.persistent) {
        this.close()
      }
    },

    async open() {
      this.show = true
      await sleep(1)
      this.fade = true
    },

    async close() {
      this.fade = false
      await sleep(50)
      this.show = false
      this.$emit("input", false)
    },
  },
}
</script>

<style lang="postcss">
.fc-modal__overlay {
  -webkit-backdrop-filter: blur(3px) saturate(0.66);
  backdrop-filter: blur(3px) saturate(0.66);
  background: rgba(0, 0, 0, 0.5);
  transition: all 50ms ease-in-out;
}

.fc-modal__container {
  max-height: calc(100% - theme("spacing.8"));
}
</style>
