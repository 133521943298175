<template>
  <div class="flex space-x-2">
    <FcInputSelect
      v-model="inputType"
      :items="typeItems"
      class="flex-1"
      :label="$t('dataLayers.form.type')"
    >
      <template #entry="{item}">
        <span
          :class="{
            'text-value-good': item.value === typeItems[0].value,
            'text-value-bad': item.value === typeItems[1].value,
          }"
        >
          {{ item.text }}
        </span>
      </template>
    </FcInputSelect>
    <FcInputSelect
      v-model="inputComp"
      :items="comparisonItems"
      class="flex-1"
      :label="$t('dataLayers.form.comparison')"
    />
    <FcInputText
      v-model.lazy="inputValue"
      class="flex-1"
      :label="$t('dataLayers.form.value')"
      type="number"
      step="0.1"
    />
  </div>
</template>

<script>
import { Threshold } from "~/models"

export default {
  props: {
    type: {
      type: String,
      default: Threshold.TYPE_GOOD,
    },
    op: {
      type: String,
      default: Threshold.OP_EQ,
    },
    value: {
      type: String,
      default: "0",
    },
  },
  computed: {
    typeItems() {
      return [
        { text: this.$t("dataLayers.form.good"), value: Threshold.TYPE_GOOD },
        { text: this.$t("dataLayers.form.bad"), value: Threshold.TYPE_BAD },
      ]
    },
    comparisonItems() {
      return [
        { text: "=", value: Threshold.OP_EQ },
        { text: ">", value: Threshold.OP_GT },
        { text: "≥", value: Threshold.OP_GTE },
        { text: "<", value: Threshold.OP_LT },
        { text: "≤", value: Threshold.OP_LTE },
      ]
    },
    inputType: {
      get() {
        return this.type
      },
      set(val) {
        this.$emit(
          "input",
          Threshold.load({ type: val, op: this.inputComp, value: this.inputValue })
        )
      },
    },
    inputComp: {
      get() {
        return this.op
      },
      set(val) {
        this.$emit(
          "input",
          Threshold.load({ type: this.inputType, op: val, value: this.inputValue })
        )
      },
    },
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        if (val) {
          const sanitizedVal = val.replaceAll(",", ".").replaceAll(/[^0-9-.,]/g, "")
          this.$emit(
            "input",
            Threshold.load({
              type: this.inputType,
              op: this.inputComp,
              value: isNaN(Number(sanitizedVal)) ? this.value : sanitizedVal,
            })
          )
          this.$forceUpdate()
        }
      },
    },
  },
}
</script>
