import Resource from "./Resource"
import Interceptor from "./Interceptor"
import deepClone from "./deepClone"
import { objToFormData, objToURLEncoded } from "./encoders"

export class RestClient extends Resource {
  constructor(baseURL, conf = {}) {
    const url = new URL(baseURL)
    super(null, url.pathname)
    this._conf = deepClone(
      {
        host: url.origin,
        shortcuts: true,
        autoSearchParamBrackets: true,
        contentType: "application/json",
        fetch: {
          credentials: "same-origin",
          headers: {},
        },
        schemas: {
          "application/json": { encode: JSON.stringify, decode: JSON.parse },
          "multipart/form-data": { encode: objToFormData },
          "application/x-www-form-urlencoded": { encode: objToURLEncoded },
        },
      },
      conf
    )
    this._interceptors = {
      request: new Interceptor(),
      response: new Interceptor(),
    }
  }

  get conf() {
    return this._conf
  }
  get interceptors() {
    return this._interceptors
  }

  createURL(path = "", searchParams = {}) {
    const url = this.getURL(searchParams)
    url.pathname += path && path !== "/" ? "/" + path : path
    return url
  }
}
