var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col md:flex-row", attrs: { id: "app" } },
    [
      _c("SidebarContainer", {
        staticClass:
          "flex-1 md:flex-initial md:w-96 md:max-w-1/3 overflow-y-auto"
      }),
      _c("MapView", { staticClass: "flex-1 z-0" }),
      _c(
        "FcModal",
        { attrs: { value: _vm.isModalShown, persistent: "", eager: "" } },
        [
          _vm.isLoginDialogShown
            ? _c(
                "div",
                {
                  staticClass:
                    "text-center space-y-6 bg-background p-4 max-w-lg m-auto rounded"
                },
                [
                  _c("FiksuCityLogo", {
                    staticClass: "w-64 max-w-full mx-auto",
                    attrs: { dark: _vm.$theme.dark }
                  }),
                  _c(
                    "FcButton",
                    {
                      staticClass:
                        "inline-block px-3 py-2 text-normal bg-surface bg-opacity-60 hover:text-accented hover:bg-opacity-90",
                      attrs: { href: _vm.$api.loginURL(), icon: "sign-in-alt" }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("login.loginButtonNetwork")) + " "
                      )
                    ]
                  ),
                  _c("LanguageSelector")
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }