import { BaseField } from "./Field"
import SetWithOps from "./SetWithOps"

function getAllowedFields(allFields = [], exclude = [], only = []) {
  const allSet = new SetWithOps(allFields)
  const excludeSet =
    typeof exclude === "string" ? new SetWithOps([exclude]) : new SetWithOps(exclude)
  const onlySet = typeof only === "string" ? new SetWithOps([only]) : new SetWithOps(only)
  if (onlySet.size > 0) {
    return allSet.intersection(onlySet).difference(excludeSet)
  }
  return allSet.difference(excludeSet)
}

class BaseModel {
  constructor(attributes = {}, { exclude = [], only = [] } = {}) {
    if (typeof attributes !== "object") {
      throw new TypeError(
        `The attributes argument must be an object, ${typeof attributes} received.`
      )
    }
    const allowedFields = getAllowedFields(Object.keys(this.fields), exclude, only)
    const fields = [...Object.entries(this.fields)].filter(([name]) => allowedFields.has(name))
    for (const [name, field] of fields) {
      const key = field.dataKey ? field.dataKey : name
      const attribute = attributes[key]
      if (attribute == null) {
        this[name] = null
      } else if (field.type.prototype instanceof BaseField || field.type instanceof BaseField) {
        this[name] = field.type.deserialize(attribute)
      } else if (typeof field.type === "function") {
        this[name] = field.type(attribute)
      } else {
        this[name] = undefined
      }
      if (field.defaultValue != null) {
        this[name] = this[name] || field.defaultValue
      }
    }
  }

  get fields() {
    return {}
  }

  serialize() {
    const serialized = {}
    for (const [name, field] of Object.entries(this.fields)) {
      const key = field.dataKey ? field.dataKey : name
      if (this[name] == null) {
        serialized[key] = null
      } else {
        serialized[key] = field.type.serialize(this[name])
      }
    }
    return serialized
  }

  static load(data, fromDeserialized = false) {
    if (!fromDeserialized) {
      return new this(data)
    } else {
      const obj = new this()
      for (const i in data) {
        obj[i] = data[i]
      }
      return obj
    }
  }

  static loadFromDeserialized(data) {
    return this.load(data, true)
  }

  static loadMany(data, fromDeserialized = false) {
    return data.map(d => this.load(d, fromDeserialized))
  }

  static loadManyFromDeserialized(data) {
    return this.loadMany(data, true)
  }
}

export { BaseModel }
