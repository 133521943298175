import { BaseModel, Field } from "~/lib/Model"

export class Threshold extends BaseModel {
  static TYPE_GOOD = "good"
  static TYPE_BAD = "bad"

  static OP_EQ = "eq"
  static OP_GT = "gt"
  static OP_GTE = "gte"
  static OP_LT = "lt"
  static OP_LTE = "lte"

  get fields() {
    return {
      type: Field.String({ defaultValue: Threshold.TYPE_GOOD }),
      op: Field.String({ defaultValue: Threshold.OP_EQ }),
      value: Field.Number({ defaultValue: 0 }),
    }
  }

  get isGood() {
    return this.type === Threshold.TYPE_GOOD
  }

  get isBad() {
    return this.type === Threshold.TYPE_BAD
  }

  compare(toValue) {
    switch (this.op) {
      case Threshold.OP_EQ:
        return toValue === this.value
      case Threshold.OP_GT:
        return toValue > this.value
      case Threshold.OP_GTE:
        return toValue >= this.value
      case Threshold.OP_LT:
        return toValue < this.value
      case Threshold.OP_LTE:
        return toValue <= this.value
      default:
        return undefined
    }
  }
}
