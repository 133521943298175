import Vue from "vue"

import store from "./store"
import { i18n } from "./i18n"
import loadPlugins from "~/plugins"

import App from "./App.vue"

import "./componentLib"
import "./faIcons"

import "leaflet/dist/leaflet.css"
import "./assets/tailwind.css"
import "./assets/index.css"

Vue.config.productionTip = false

loadPlugins(store).then(plugins => {
  // TODO: probably needs a better place to put this in
  plugins.$api.rest.interceptors.response.use(({ res }) => {
    if (!plugins.$api.rest.authenticate.getURL().href === res.url) {
      if (res.status === 401 || res.status === 403) {
        store.commit("user/setToken", {})
      }
    }
  })

  new Vue({
    store,
    i18n,
    ...plugins,
    render: h => h(App),
  }).$mount("#app")
})
