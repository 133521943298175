var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.href ? "a" : _vm.tag,
    {
      tag: "component",
      staticClass: "fc-list-item flex items-center",
      class:
        ((_obj = {
          "fc-list-item--link": _vm.isLink
        }),
        (_obj[_vm.activeClass] = _vm.isActive),
        _obj),
      attrs: { href: _vm.href },
      on: {
        click: function($event) {
          return _vm.$emit("click", _vm.value)
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }