export default {
  locales: {
    title: "Language",
    en: "English",
    fi: "Finnish",
  },
  themes: {
    title: "Theme",
    current: "Device theme",
    light: "Light",
    dark: "Dark",
  },
  actions: {
    cancel: "Cancel",
    save: "Save",
    delete: "Delete",
    reset: "Reset",
  },
  login: {
    pleaseLogin: "Please log in to use Fiksu City.",
    loginButtonNetwork: "Log in via Fiksu Network",
    logout: "Log out",
  },
  siteStatus: {
    title: "Site status",
    site: "site | sites",
    visibleAndActive: "{visible} {sitesVisible} visible, {active} active on current layer",
    shortVisibleAndActive: "{visible} visible, {active} active",
  },
  dataLayers: {
    title: "Data layers",
    form: {
      add: "Add a new data layer",
      edit: "Edit a data layer",
      info: "Layer info",
      active: "Active",
      name: "Layer name",
      dataSources: "Data sources",
      dataModule: "Data module",
      addSource: "Add a new data source",
      dataSource: "Data source",
      device: "Device",
      position: "Position",
      addPoint: "Add point",
      calculation: "Calculation",
      mean: "Mean",
      sum: "Sum",
      difference: "Difference",
      thresholds: "Thresholds",
      addThreshold: "Add a new threshold",
      type: "Type",
      good: "Good",
      bad: "Bad",
      comparison: "Comparison",
      value: "Value",
      summary: "Summary",
    },
    reset: "Reset to default layers",
    resetConfirm:
      "Are you sure you want to reset the layers to default settings? This action cannot be undone.",
  },
  geoLayers: {
    title: "Geodata layers",
    manage: "Manage geodata layers",
    minZoom: "Minimum geodata layer zoom level",
  },
  favorites: {
    title: "Favorites",
    guide: "Tap a site on the map to add it to your favorites.",
  },
  sitePopup: {
    sitesHidden: "{n} site hidden | {n} sites hidden",
    showAlarms: "Show {n} alarm | Show {n} alarms",
    hideAlarms: "Hide {n} alarm | Hide {n} alarms",
    clientId: "Client ID",
  },
  siteSearch: {
    label: "Site search",
  },
  mobileMenu: {
    gauge: "Gauges",
    map: "Map",
    layer: "Layers",
    alarm: "Alarms",
  },
  alarms: {
    title: "Active alarms",
    noAlarms: "No active alarms.",
    searchLabel: "Search active alarms",
    alarmName: "Alarm device or message",
    siteName: "Site name or address",
  },
  releaseNotes: {
    title: "Release Notes",
    onlyEnglish: "Release notes are only available in English.",
  },
}
