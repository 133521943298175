import Vue from "vue"

import VIcon from "vue-awesome/components/Icon"
Vue.component("VIcon", VIcon)

import FcButton from "~/components/lib/FcButton.vue"
Vue.component("FcButton", FcButton)

import FcCard from "~/components/lib/FcCard.vue"
Vue.component("FcCard", FcCard)

import FcModal from "~/components/lib/FcModal.vue"
Vue.component("FcModal", FcModal)

import FcList from "~/components/lib/FcList.vue"
Vue.component("FcList", FcList)

import FcListItem from "~/components/lib/FcListItem.vue"
Vue.component("FcListItem", FcListItem)

import FcMenu from "~/components/lib/FcMenu.vue"
Vue.component("FcMenu", FcMenu)

import FcInputText from "~/components/lib/FcInputText.vue"
Vue.component("FcInputText", FcInputText)

import FcInputTextArea from "~/components/lib/FcInputTextArea.vue"
Vue.component("FcInputTextArea", FcInputTextArea)

import FcInputToggle from "~/components/lib/FcInputToggle.vue"
Vue.component("FcInputToggle", FcInputToggle)

import FcInputRadio from "~/components/lib/FcInputRadio.vue"
Vue.component("FcInputRadio", FcInputRadio)

import FcInputSelect from "~/components/lib/FcInputSelect.vue"
Vue.component("FcInputSelect", FcInputSelect)

import FcInputAutoComplete from "~/components/lib/FcInputAutoComplete.vue"
Vue.component("FcInputAutoComplete", FcInputAutoComplete)
