<template>
  <div class="flex">
    <div class="flex-grow px-2 space-y-1">
      <div class="flex text-lg leading-tight items-start">
        <v-icon label="Favorite" class="mr-2 mt-0.5 cursor-pointer" @click="toggleFavorite">
          <v-icon v-show="isFavorite" name="star" class="text-favorite-fill" />
          <v-icon
            name="regular/star"
            :class="[isFavorite ? 'text-favorite-outline' : 'text-muted2']"
          />
        </v-icon>
        <span>{{ site.name }}</span>
      </div>
      <div class="flex divide-x divide-muted2">
        <a :href="siteURL(site)" target="_blank" class="pr-1"> #{{ site.id }} </a>
        <span class="px-1">{{ site.address }}</span>
      </div>
      <div v-if="site.clientId">
        <span class="text-muted">{{ $t("sitePopup.clientId") }}:</span>
        {{ site.clientId }}
      </div>
      <div v-if="site.urlEnergyScreen || site.urlFiksuWeb" class="divide-x divide-muted2">
        <a
          v-if="site.urlEnergyScreen"
          :href="site.urlEnergyScreen"
          class="px-1 first:pl-0 last:pr-0"
        >
          Energy Screen
        </a>
        <a v-if="site.urlFiksuWeb" :href="site.urlFiksuWeb" class="px-1 first:pl-0 last:pr-0">
          FiksuWeb
        </a>
      </div>
      <div v-if="isAlarmActive">
        <div v-if="alarms.length">
          <FcButton
            xs
            icon="exclamation-triangle"
            icon-class="text-alert"
            class="hover:bg-alert hover:bg-opacity-20 bipika"
            @click="showAlarms = !showAlarms"
          >
            {{ $tc(!showAlarms ? "sitePopup.showAlarms" : "sitePopup.hideAlarms", alarms.length) }}
          </FcButton>
          <div v-if="showAlarms" class="text-muted mt-1 ml-3">
            <div
              v-for="[index, alarm] of alarms.entries()"
              :key="index"
              class="border-alert border-opacity-30 border-l pl-2"
            >
              <v-icon name="exclamation-triangle" class="text-alert" />
              <span class="ml-1 font-bold">{{ alarm.device }}</span>
              <div class="ml-5">{{ alarm.message }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-shrink flex justify-center items-start">
      <div
        class="text-3xl font-bold"
        :class="{
          'text-value-unknown': isUnknown,
          'text-value-bad': isBad,
          'text-value-good': isGood,
          'text-value-average': isAverage,
        }"
      >
        <div>{{ value }}{{ unit }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Config from "~/config"

export default {
  props: {
    site: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    showAlarms: false,
  }),

  computed: {
    ...mapGetters({
      currentDataLayer: "layers/currentDataLayer",
      alarmsBySite: "alarm/alarmsBySite",
      isAlarmActive: "alarm/getAlarmActive",
    }),
    alarms() {
      return (this.alarmsBySite[this.site.id] ?? []).slice().sort((a, b) => b.date - a.date)
    },
    isFavorite() {
      return this.$store.state.preferences.favoriteSites.includes(this.site.id)
    },
    siteValue() {
      return this.currentDataLayer?.getValue(this.site)
    },
    isUnknown() {
      return (
        !this.currentDataLayer ||
        !this.currentDataLayer.hasSiteDiscoveredPoints(this.site) ||
        this.currentDataLayer.isSiteValueNaN(this.site)
      )
    },
    isBad() {
      return !this.isUnknown && this.currentDataLayer?.isSiteValueBad(this.site)
    },
    isGood() {
      return !this.isUnknown && !this.isBad && this.currentDataLayer?.isSiteValueGood(this.site)
    },
    isAverage() {
      return !this.isUnknown && !this.isBad && !this.isGood
    },
    value() {
      return !isNaN(this.siteValue?.value) ? this.siteValue.value.toFixed(1) : "—"
    },
    unit() {
      return !isNaN(this.siteValue?.value) ? this.siteValue.unit : ""
    },
  },
  methods: {
    toggleFavorite() {
      if (this.isFavorite) {
        return this.$store.dispatch("preferences/removeFavoriteSite", this.site.id)
      } else {
        return this.$store.dispatch("preferences/addFavoriteSite", this.site.id)
      }
    },
    siteURL(site) {
      return `${Config.fiksuNetworkUrl}/site/${site.module}/${site.id}`
    },
  },
}
</script>

<style lang="postcss">
.is-favorite {
  filter: drop-shadow(0 0 1px theme("colors.favorite.outline"));
}
</style>
