const state = () => ({
  sites: [],
  siteIndex: {},
  bounds: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
})

const getters = {
  sitesOnline: state => state.sites.filter(s => s.isOnline),
  sitesVisible: state =>
    state.sites.filter(
      s =>
        s.isOnline &&
        s.isLocatedWithin(
          state.bounds.top,
          state.bounds.left,
          state.bounds.bottom,
          state.bounds.right
        )
    ),
  getSite: state => id => state.sites[state.siteIndex[id]] || null,
}

const mutations = {
  setSites(state, sites) {
    state.sites = sites || []
    state.siteIndex = sites.reduce((acc, site, i) => {
      acc[site.id] = i
      return acc
    }, {})
  },
  setBounds(state, { top, left, bottom, right }) {
    state.bounds = { top, left, bottom, right }
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
