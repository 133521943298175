var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg-surface divide-y divide-surface-accented" },
    [
      _vm.$slots.header
        ? _c(
            "div",
            { staticClass: "py-2 px-4 text-left text-sm font-bold uppercase" },
            [_vm._t("header")],
            2
          )
        : _vm._e(),
      _vm.$slots.default
        ? _c(
            "div",
            { staticClass: "py-2 px-4", class: _vm.divClass },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm.$slots.footer
        ? _c("div", { staticClass: "py-2 px-4" }, [_vm._t("footer")], 2)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }