var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FcCard",
    { staticClass: "rounded", attrs: { name: "siteStatus" } },
    [
      _c("i18n", {
        staticClass: "text-muted2",
        attrs: { path: "siteStatus.shortVisibleAndActive", tag: "span" },
        scopedSlots: _vm._u([
          {
            key: "visible",
            fn: function() {
              return [
                _c(
                  "span",
                  {
                    staticClass: "text-3xl font-bold",
                    class: _vm.tooManySitesVisible
                      ? "text-danger"
                      : "text-accented"
                  },
                  [_vm._v(" " + _vm._s(_vm.sitesVisibleCount) + " ")]
                )
              ]
            },
            proxy: true
          },
          {
            key: "active",
            fn: function() {
              return [
                _c(
                  "span",
                  { staticClass: "text-3xl font-bold text-accented" },
                  [_vm._v(" " + _vm._s(_vm.sitesActiveCount) + " ")]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }