var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-container relative",
      class: { populated: _vm.textValue }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "textarea-container py-4 bg-input rounded focus-within:ring-2 ring-input-accented transition"
        },
        [
          _c(
            "textarea",
            _vm._b(
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.textValue,
                    expression: "textValue"
                  }
                ],
                staticClass: "w-full px-4 bg-input outline-none",
                class: { "resize-none": _vm.noResize },
                domProps: { value: _vm.textValue },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.textValue = $event.target.value
                  }
                }
              },
              "textarea",
              Object.assign({}, _vm.$attrs, _vm.$props),
              false
            )
          )
        ]
      ),
      _c(
        "label",
        {
          staticClass:
            "absolute top-0 left-0 p-4 text-muted pointer-events-none transition-all"
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }