<template>
  <FcCard>
    <template #header>
      <div class="cursor-pointer flex space-x-2">
        <div class="flex-grow">
          <slot name="header" />
        </div>
      </div>
    </template>
    <slot />
    <template #footer>
      <slot name="footer" />
    </template>
  </FcCard>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
  },
}
</script>
