var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "input-container relative",
      class: { populated: _vm.populated },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return function() {
            return _vm.toggleOpen(true)
          }.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "div",
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue"
              }
            ],
            staticClass:
              "w-full p-4 bg-input rounded focus:outline-none focus:ring-2 ring-input-accented transition",
            class: _vm.inputClass,
            domProps: { value: _vm.inputValue },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.inputValue = $event.target.value
              }
            }
          }),
          _c(
            "label",
            {
              staticClass:
                "absolute left-0 p-4 text-muted pointer-events-none transition-all"
            },
            [
              _vm._t("label", function() {
                return [_vm._v(" " + _vm._s(_vm.label) + " ")]
              })
            ],
            2
          ),
          _c("FcButton", {
            staticClass:
              "absolute top-3 right-2 rounded-full h-8 w-8 flex justify-center hover:bg-input-accented",
            class: _vm.populated ? "opacity-100" : "opacity-0",
            attrs: { sm: "", icon: "times", "icon-class": "text-muted" },
            on: {
              click: function() {
                return _vm.removeSearchText()
              }
            }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active,
              expression: "active"
            }
          ],
          ref: "list",
          staticClass:
            "fixed bg-input w-min h-min overflow-y-auto overscroll-contain rounded ring-2 ring-input-accented"
        },
        _vm._l(_vm.filteredItems.entries(), function(ref) {
          var i = ref[0]
          var item = ref[1]
          return _c(
            "div",
            {
              key: i,
              ref: "listItems",
              refInFor: true,
              staticClass:
                "px-4 py-2 hover:bg-input-accented focus:bg-input-accented focus:outline-none w-full cursor-pointer",
              attrs: { value: i, tabindex: "-1" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return function() {
                    return _vm.onSelect(item)
                  }.apply(null, arguments)
                }
              }
            },
            [
              _vm._t(
                "entry",
                function() {
                  return [_vm._v(" " + _vm._s(item.text) + " ")]
                },
                { item: item }
              )
            ],
            2
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }