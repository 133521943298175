import { deserialize } from "~/lib/VuexPersist"
import { removeFromList, removeFromMap, set, updateList, updateMap } from "~/lib/VuexHelpers"

import defaultLayers from "~/models/defaultLayers"
import { DataLayer } from "~/models"
import { mapObject } from "~/utils"

const state = () => ({
  dataLayers: {},
  dataLayerOrder: [],
  geoLayers: {},
  hiddenGeoLayers: [],
  currentId: null,
  lastUpdated: null,
})

const getters = {
  serialized: state => {
    // exclude some data from serialization
    // eslint-disable-next-line no-unused-vars
    const { dataLayers, lastUpdated, geoLayers, ...rest } = state
    return {
      ...rest,
      dataLayers: mapObject(dataLayers, layer => layer.serialize()),
    }
  },

  // dataLayerList: state => Array.from(Object.values(state.dataLayers)),
  dataLayerList: state => state.dataLayerOrder.map(id => state.dataLayers[id]),
  currentDataLayer: state => state.dataLayers[state.currentId] ?? null,
  geoLayerList: state =>
    Array.from(Object.values(state.geoLayers)).sort((a, b) => (a.name < b.name ? -1 : 1)),
  isGeoLayerHidden: state => geodata => state.hiddenGeoLayers.includes(geodata.path),
}

const resetToDefaultLayers = state => {
  state.dataLayers = Object.assign({}, ...defaultLayers.map(layer => ({ [layer.id]: layer })))
  state.dataLayerOrder = defaultLayers.map(layer => layer.id)
  state.currentId = Object.values(state.dataLayers)[0]?.id ?? null
}

const mutations = {
  deserialize(state, loadedState) {
    const { dataLayers, dataLayerOrder = [], ...rest } = loadedState
    state.dataLayerOrder = dataLayerOrder
    if (!dataLayers) {
      resetToDefaultLayers(state)
    } else {
      state.dataLayers = mapObject(dataLayers, layer => DataLayer.load(layer))
    }
    for (const id in state.dataLayers) {
      if (!state.dataLayerOrder.includes(id)) {
        state.dataLayerOrder.push(id)
      }
    }
    deserialize(state, rest)
  },

  resetToDefaultLayers,

  createDataLayer: updateMap("dataLayers", "id", (state, layer) => {
    if (Object.keys(state.dataLayers).length === 1) {
      state.currentId = layer.id
    }
    updateList("dataLayerOrder", "id")(state, layer)
  }),

  updateDataLayer: (state, layer) => {
    if (layer.id in state.dataLayers && state.dataLayers[layer.id]._ver <= layer._ver) {
      state.dataLayers = { ...state.dataLayers, [layer.id]: layer }
    }
  },

  removeDataLayer: removeFromMap("dataLayers", "id", (state, layer) => {
    if (layer.id === state.currentId) {
      state.currentId = state.dataLayerOrder[0] ?? null
    }
    removeFromList("dataLayerOrder", "id")(state, layer)
  }),

  setDataLayerOrder: set("dataLayerOrder"),

  setCurrentDataLayer(state, layer) {
    state.currentId = layer.id
  },

  update(state) {
    state.lastUpdated = new Date()
  },

  setGeoLayer(state, geodata) {
    state.geoLayers = { ...state.geoLayers, [geodata.path]: geodata }
  },

  setGeoLayerVisibility(state, { geodata, visible }) {
    const i = state.hiddenGeoLayers.indexOf(geodata.path)
    if (visible && i === -1) {
      state.hiddenGeoLayers.push(geodata.path)
    } else if (!visible && i !== -1) {
      state.hiddenGeoLayers = [
        ...state.hiddenGeoLayers.slice(0, i),
        ...state.hiddenGeoLayers.slice(i + 1),
      ]
    }
  },
}

const actions = {}

export default {
  namespaced: true,
  persist: true,
  state,
  getters,
  mutations,
  actions,
}
