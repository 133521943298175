import L from "leaflet"
import config from "~/config"
import { chunks } from "~/utils"

import { SiteMarkerManager } from "./SiteMarkerManager"

export default class MapService {
  constructor($api, $store) {
    this.$api = $api
    this.$store = $store
    this.options = {}
    this.map = null
    this.popup = null
    this.markerManager = new SiteMarkerManager(this)
    this.discovering = new Set()
    this.maxPointsPerRequest = 100
    this.geoLayerGroup = L.layerGroup()
    this.geoLayers = new Map()

    this._unsubscribeFromStore = this.$store.subscribe(async (mutation, _state) => {
      if (mutation.type === "layers/setGeoLayerVisibility") {
        await this.fetchGeoLayers()
        this.updateGeoLayers()
      }
    })
  }

  get ready() {
    return this.map !== null
  }

  get popupNode() {
    return this.popup?.getContent()
  }

  get dataLayers() {
    return this.$store.getters["layers/dataLayerList"]
  }

  get currentDataLayer() {
    return this.$store.getters["layers/currentDataLayer"] ?? null
  }

  get sitesOnline() {
    return this.$store.getters["map/sitesOnline"]
  }

  get geodataItems() {
    return this.$store.getters["layers/geoLayerList"]
  }

  get alarmsBySite() {
    return this.$store.getters["alarm/alarmsBySite"]
  }

  get geoLayerMinZoom() {
    return this.$store.state.preferences.minGeoZoom
  }

  get markersLayer() {
    return this.markerManager.markers
  }

  getSitesVisible() {
    // to avoid possible conflicts in case the visibility changes mid-action
    return [...this.$store.getters["map/sitesVisible"]]
  }

  getSiteLatLng(site) {
    const loc = site.primaryLocation
    return L.latLng(loc.latitude, loc.longitude)
  }

  isGeoLayerHidden(geodata) {
    return this.$store.getters["layers/isGeoLayerHidden"](geodata)
  }

  initialize(node, options = {}) {
    if (this.map !== null) {
      throw new Error("Map has already been initialized")
    }

    const defaultOptions = {
      popup: {},
      coordinates: this.$store.state.preferences.lastCoordinates,
      zoom: this.$store.state.preferences.lastZoom,
    }
    this.options = { ...defaultOptions, ...options }

    this.popup = L.popup(this.options.popup).setContent(document.createElement("div"))

    const streets = L.tileLayer(
      config.mapData.streetLayer.urlTemplate,
      config.mapData.streetLayer.options
    )
    // Construct the map object
    const map = L.map(node, {
      zoomControl: false,
      layers: [streets],
      maxBounds: [
        [-85.0, -180.0],
        [85.0, 180.0],
      ],
      minZoom: 2,
    })
    // Move the zoom controls to the bottom right corner
    L.control.zoom({ position: "bottomright" }).addTo(map)

    // Update map coordinate information on load and on drag/zoom end
    map.on("load moveend", this.updateBounds, this)

    map.on("load zoomend", this.updateMarkers, this)
    map.on("load zoomend", this.updateGeoLayerGroup, this)

    // Lets user press ctrl+c to copy the coordinates under the mouse position
    const mouse = { x: 0, y: 0 }
    const trackMouse = evt => {
      mouse.x = evt.originalEvent.clientX
      mouse.y = evt.originalEvent.clientY
    }
    const copyCoords = async evt => {
      if (!this.popup.isOpen() && evt.originalEvent.ctrlKey && evt.originalEvent.code === "KeyC") {
        const rect = map.getContainer().getBoundingClientRect()
        const latLng = map.containerPointToLatLng(L.point(mouse.x - rect.left, mouse.y - rect.y))
        const coords = `${latLng.lat}, ${latLng.lng}`
        console.info(coords)
        navigator.clipboard.writeText(coords)
      }
    }
    map.on({
      mousemove: trackMouse,
      keydown: copyCoords,
    })

    this.map = map
    this.markerManager.clear()

    // Set default location
    map.setView(this.options.coordinates, this.options.zoom ?? map.getZoom())
  }

  tearDown() {
    this._unsubscribeFromStore()
    this.markerManager.clear()
    this.map.off()
    this.map = null
  }
  loadMarkers(sites = null) {
    this.markerManager.load(this.map, sites ?? this.sitesOnline)
    this.updateMarkers()
  }
  updateMarkers() {
    this.markerManager.updateMarkers(this.map?.getBounds(), this.map?.getZoom())
  }

  toggleAlarmLayer(on) {
    this.markerManager.toggleAlarmLayer(this.map, on)
  }

  updateGeoLayerGroup() {
    if (this.map?.getZoom() >= this.geoLayerMinZoom) {
      this.map.addLayer(this.geoLayerGroup)
    } else if (this.map) {
      this.map.removeLayer(this.geoLayerGroup)
    }
  }

  goToLocation(latitude, longitude, zoom = null) {
    this.map.setView([latitude, longitude], zoom ?? this.map.getZoom())
  }

  goToSite(site, zoom = null) {
    const loc = site.primaryLocation
    const zoomTo = zoom ?? config.mapData.siteZoom
    return this.goToLocation(loc.latitude, loc.longitude, zoomTo)
  }

  showSitePopup(latlng) {
    this.popup.setLatLng(latlng).openOn(this.map)
  }

  getVisibleSitesWithinRange(latLng, radius = 2) {
    const point = this.map.latLngToLayerPoint(latLng)
    const getSitePoint = s => this.map.latLngToLayerPoint(this.getSiteLatLng(s))
    const sitesInBounds = this.getSitesVisible().filter(
      s => point.distanceTo(getSitePoint(s)) <= radius
    )
    return sitesInBounds
  }

  updateBounds() {
    const bounds = this.map.getBounds()
    this.$store.commit("map/setBounds", {
      top: bounds.getNorth(),
      left: bounds.getWest(),
      bottom: bounds.getSouth(),
      right: bounds.getEast(),
    })
    const center = this.map.getCenter()
    this.$store.commit("preferences/setMapPosition", {
      coordinates: [center.lat, center.lng],
      zoom: this.map.getZoom(),
    })
  }

  async getSites() {
    const sitesOk = await this.$api.getSiteIndex()
    this.$store.commit("map/setSites", sitesOk)
  }

  async getGeodata() {
    if (config.isFeatureEnabled.geoLayers) {
      const res = await this.$api.fetchGeodataIndex()
      for (const geodata of res) {
        this.geoLayers.set(geodata.path, L.geoJSON())
        this.$store.commit("layers/setGeoLayer", geodata)
      }
      await this.fetchGeoLayers()
      this.updateGeoLayers()
    }
  }

  async fetchGeoLayers() {
    const fetchLayers = this.geodataItems.filter(g => !g.hasGeoJSON && !this.isGeoLayerHidden(g))
    const settled = await Promise.allSettled(fetchLayers.map(g => this.$api.fetchGeoJSON(g)))
    const values = settled.filter(s => s.status === "fulfilled").map(s => s.value)
    for (const geodata of values) {
      this.$store.commit("layers/setGeoLayer", geodata)
    }
  }

  updateGeoLayers() {
    for (const geodata of this.geodataItems) {
      const layer = this.geoLayers.get(geodata.path)
      if (this.isGeoLayerHidden(geodata)) {
        layer.remove()
      } else if (geodata.geojson) {
        layer.addData(geodata.geojson).addTo(this.geoLayerGroup)
      }
    }
  }

  async discoverPoints() {
    const sitesVisible = this.getSitesVisible()
    for (const layer of this.dataLayers) {
      if (layer.active && !this.discovering.has(layer) && layer.dataSources?.length > 0) {
        try {
          this.discovering.add(layer)
          const sites = sitesVisible.filter(site => !layer.isSiteDiscovered(site))
          if (sites.length > 0) {
            const chunkedSites = [...chunks(sites, this.maxPointsPerRequest)]
            const points = []
            for (const chunk of chunkedSites) {
              const res = await this.$api.discoverPoints(layer.dataSources, chunk)
              points.push(...res)
            }
            layer.processDiscoveredPoints(points)
            this.$store.commit("layers/updateDataLayer", layer)
          }
        } catch (error) {
          // TODO: error handling
          console.error(error)
        } finally {
          this.discovering.delete(layer)
        }
      }
    }
  }

  async updateAllDataLayers() {
    const reqPoints = new Map()
    const sitesVisible = this.getSitesVisible()
    const activeLayers = this.dataLayers.filter(l => l.active)
    // Get a set of all individual points for the visible sites in active layers
    for (const layer of activeLayers) {
      const sites = sitesVisible.filter(site => layer.hasSiteDiscoveredPoints(site))
      const sitePoints = sites.map(site => layer.getPointsForSite(site)).flat()
      for (const point of sitePoints) {
        reqPoints.set(point.path, point)
      }
    }
    // Separate the points into chunks
    const points = [...reqPoints.values()]
    const reqPointsChunked = [...chunks(points, this.maxPointsPerRequest)]
    // Fetch point values for each chunk in sequence and map them
    const resPoints = new Map()
    for (const chunk of reqPointsChunked) {
      const res = await this.$api.fetchPointValues(chunk)
      for (const point of res) {
        resPoints.set(point.path, point)
      }
    }
    // Update the points in each layer with the fetched data
    for (const layer of activeLayers) {
      for (const path of layer.points.keys()) {
        if (resPoints.has(path)) {
          layer.updatePoint(resPoints.get(path))
        }
      }
      this.$store.commit("layers/updateDataLayer", layer)
      if (layer === this.currentDataLayer) {
        this.updateMarkers()
      }
    }
  }
}
