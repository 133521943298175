<template>
  <component
    :is="href ? 'a' : tag"
    :href="href"
    class="fc-list-item flex items-center"
    :class="{
      'fc-list-item--link': isLink,
      [activeClass]: isActive,
    }"
    @click="$emit('click', value)"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    href: {
      // Turns the component into an <a> element with this value as its href attribute.
      type: String,
      default: null,
    },
    link: {
      // Applies link styling without changing the component into an actual <a> element.
      type: Boolean,
      default: false,
    },
    tag: {
      // The root element tag.
      type: String,
      default: "div",
    },
    value: {
      // An optional value that gets emitted on a click event.
      type: null,
      default: null,
    },
  },

  inject: ["list"],

  data: () => ({
    isActive: false,
    activeClass: "fc-list-item--active",
  }),

  computed: {
    isLink() {
      return this.link || this.href
    },
  },

  created() {
    this.list?.register(this)
  },

  beforeDestroy() {
    this.list?.unregister(this)
  },
}
</script>

<style lang="postcss">
.fc-list-item--link {
  cursor: pointer;
  user-select: none;
}
</style>
