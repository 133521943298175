var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.buttonType,
    _vm._b(
      {
        tag: "component",
        staticClass:
          "fc-button rounded transition font-bold uppercase items-center space-x-2",
        class: {
          "text-base": _vm.base,
          "text-xs": _vm.xs,
          "text-sm": _vm.sm,
          "text-lg": _vm.lg,
          "text-xl": _vm.xl,
          "text-3xl": _vm.xl3
        },
        attrs: { type: "button" },
        on: {
          click: function(e) {
            return _vm.$emit("click", e)
          }
        }
      },
      "component",
      _vm.optional,
      false
    ),
    [
      _vm.icon
        ? _c(
            "span",
            { staticClass: "fc-button-icon-wrapper text-primary" },
            [_c("v-icon", { class: _vm.iconClass, attrs: { name: _vm.icon } })],
            1
          )
        : _vm._e(),
      _vm.$slots.default
        ? _c(
            "span",
            {
              staticClass: "fc-button-text-wrapper",
              class: (_vm.icon ? "ml-2 " : "") + _vm.textClass
            },
            [_vm._t("default")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }