<template>
  <component
    :is="tag"
    class="fc-list"
    :class="{
      'fc-list--dense': dense,
      'fc-list--flat': flat,
      'fc-list--selectable': selectable,
    }"
  >
    <slot />
  </component>
</template>

<script>
// Simplified from Vuetify's VItemGroup
export default {
  props: {
    activeClass: {
      // The class applied to the active list item
      type: String,
      default: "fc-list-item--active",
    },
    dense: {
      // Removes the default vertical padding on the list items.
      type: Boolean,
      default: false,
    },
    flat: {
      // Prevents the default hovering effect on the list items.
      type: Boolean,
      default: false,
    },
    mandatory: {
      // Mandates one of the list values to be selected.
      type: Boolean,
      default: false,
    },
    tag: {
      // The root element tag.
      type: String,
      default: "div",
    },
    value: {
      // The value of the selected list item. Makes the list items selectable.
      type: null,
      default: undefined,
    },
  },

  data: () => ({
    items: [],
  }),

  computed: {
    internalValue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit("input", v)
      },
    },
    selectable() {
      return this.internalValue !== undefined
    },
    getActive() {
      return v => this.internalValue === v
    },
  },

  provide() {
    return {
      list: this,
    }
  },

  watch: {
    internalValue: "updateItemsState",
    items: "updateItemsState",
  },

  methods: {
    getValue(item, i) {
      return item.value == null || item.value === "" ? i : item.value
    },
    onClick(item) {
      const value = this.getValue(item, this.items.indexOf(item))
      this.updateInternalValue(value)
      this.$emit("change", value)
    },
    register(item) {
      if (!this.selectable) {
        return
      }

      const index = this.items.push(item) - 1

      item.$on("click", () => this.onClick(item))

      if (this.mandatory && this.internalValue === undefined) {
        this.updateMandatory()
      }

      this.updateItem(item, index)
    },
    unregister(item) {
      if (this._isDestroyed) {
        return
      }

      const index = this.items.indexOf(item)
      const value = this.getValue(item, index)
      this.items.splice(index, 1)

      if (this.internalValue === value) {
        if (!this.mandatory) {
          this.updateInternalValue(value)
        } else {
          this.internalValue = undefined
          this.updateMandatory(true)
        }
      }
    },
    updateItem(item, index) {
      item.isActive = this.getActive(this.getValue(item, index))
      item.activeClass = this.activeClass
    },
    updateItemsState() {
      this.$nextTick(() => {
        if (this.mandatory && this.internalValue === undefined) {
          this.updateMandatory()
        } else {
          this.items.forEach(this.updateItem)
        }
      })
    },
    updateMandatory(last = false) {
      if (this.items.length > 0) {
        const items = last ? this.items.slice() : this.items.slice().reverse()
        const item = items.find(item => !item.disabled)
        if (item) {
          this.updateInternalValue(this.getValue(item, this.items.indexOf(item)))
        }
      }
    },
    updateInternalValue(value) {
      const isSame = value === this.internalValue
      if (!(this.mandatory && isSame)) {
        this.internalValue = isSame ? undefined : value
      }
    },
  },
}
</script>

<style lang="postcss">
.fc-list {
  @apply bg-surface;
}

.fc-list.fc-list--selectable .fc-list-item {
  @apply cursor-pointer;
}

.fc-list:not(.fc-list--flat) .fc-list-item:hover {
  @apply bg-surface-accented bg-opacity-50;
}

.fc-list:not(.fc-list--flat) .fc-list-item.fc-list-item--active {
  @apply bg-surface-accented bg-opacity-100;
}

.fc-list .fc-list-item {
  @apply px-4;
}

.fc-list:not(.fc-list--dense) .fc-list-item {
  @apply py-2;
}
</style>
