<template>
  <div
    class="fc-input relative flex"
    :class="{ populated, 'fc-input__labelled': label, 'fc-input__sm': sm }"
  >
    <input
      v-bind="{ ...$attrs, ...$props }"
      :class="classProps"
      :value="value"
      @input="onInput"
      :type="type"
      class="w-full bg-input rounded focus:outline-none focus:ring-2 ring-input-accented transition"
    />
    <label
      :class="classProps"
      class="absolute left-2 text-muted pointer-events-none transition-all"
    >
      {{ label }}
    </label>
    <div
      v-if="clearable"
      :class="{
        'text-opacity-0 pointer-events-none': !populated,
        'text-opacity-50 hover:text-opacity-100': populated,
      }"
      class="fc-input__clear absolute right-0 w-8 text-muted h-full flex items-center justify-center cursor-pointer transition duration-100"
      @click="clearInput"
    >
      <v-icon name="times" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    populated() {
      return this.value !== ""
    },
    classProps() {
      return {
        "p-2 text-sm": this.sm,
        "p-4": !this.sm,
        "pr-8": this.clearable,
      }
    },
  },
  methods: {
    onInput(evt) {
      return this.$emit("input", evt.target.value)
    },
    clearInput() {
      return this.$emit("input", "")
    },
  },
}
</script>

<style lang="postcss" scoped>
.fc-input__labelled.populated > label,
.fc-input__labelled:focus-within > label {
  @apply pt-1 text-xs;
}
.fc-input__labelled.populated > input,
.fc-input__labelled:focus-within > input {
  @apply pt-6 pb-2;
}
.fc-input__labelled.fc-input__sm.populated > label,
.fc-input__labelled.fc-input__sm:focus-within > label {
  @apply pt-0;
}
.fc-input__labelled.fc-input__sm.populated > input,
.fc-input__labelled.fc-input__sm:focus-within > input {
  @apply pt-3 pb-1 text-xs;
}
</style>
