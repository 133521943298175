<template>
  <FcMenu>
    <template #activator="{on}">
      <FcButton
        v-on="on"
        icon="user-cog"
        class="p-2 hover:bg-surface"
        icon-class="text-normal hover:text-accented"
        xl
      />
    </template>
    <FcList
      flat
      class="overflow-y-auto h-full rounded border border-surface-accented w-96 max-w-full"
    >
      <FcListItem v-show="isLoggedIn" class="space-x-4 border-b border-surface-accented">
        <div class="flex-1 text-muted text-sm truncate py-1 space-x-2">
          <v-icon name="user" />
          <span v-text="username" />
        </div>
      </FcListItem>
      <FcListItem class="space-x-4">
        <div class="flex-1">
          {{ $t("locales.title") }}
        </div>
        <div class="flex-1 flex justify-end">
          <FcInputSelect v-model="locale" :items="localeItems" class="min-w-full w-max">
            <template #entry="{item}">
              <div class="flex items-center space-x-1">
                <img :src="item.flag" class="w-4" />
                <span v-text="$t(`locales.${item.value}`)" />
              </div>
            </template>
          </FcInputSelect>
        </div>
      </FcListItem>
      <FcListItem class="space-x-4">
        <div class="flex-1">
          {{ $t("themes.title") }}
        </div>
        <div class="flex-1 flex justify-end">
          <FcInputSelect v-model="theme" :items="themeItems" class="min-w-full w-max" />
        </div>
      </FcListItem>
      <FcListItem class="space-x-4">
        <div class="flex-1">
          {{ $t("geoLayers.minZoom") }}
        </div>
        <div class="flex-1 flex justify-end">
          <FcInputSelect v-model="minGeoZoom" :items="minGeoZoomItems" class="min-w-full w-max" />
        </div>
      </FcListItem>
      <FcListItem v-show="isLoggedIn" class="border-t border-surface-accented">
        <FcButton
          :href="$api.logoutURL()"
          icon="sign-out-alt"
          class="text-normal hover:text-accented hover:bg-primary hover:bg-opacity-20"
        >
          {{ $t("login.logout") }}
        </FcButton>
      </FcListItem>
    </FcList>
  </FcMenu>
</template>

<script>
import { locales } from "~/i18n"

export default {
  computed: {
    locale: {
      get() {
        return this.$store.state.preferences.locale
      },
      set(val) {
        this.$store.commit("preferences/setLocale", val)
      },
    },
    localeItems() {
      return locales.map(l => ({
        value: l.code,
        flag: l.flag,
      }))
    },
    theme: {
      get() {
        return this.$store.state.preferences.theme
      },
      set(val) {
        this.$theme.setTheme(val)
        this.$store.commit("preferences/setTheme", val)
      },
    },
    themeItems() {
      return this.$theme.themes.map(value => ({
        value,
        text: this.$t(`themes.${value}`),
      }))
    },
    minGeoZoom: {
      get() {
        return this.$store.state.preferences.minGeoZoom
      },
      set(val) {
        this.$store.commit("preferences/setMinGeoZoom", val)
        this.$map.updateGeoLayerGroup()
      },
    },
    minGeoZoomItems() {
      return Array.from({ length: 16 }, (_, i) => ({
        value: i + 1,
        text: i + 1,
      }))
    },
    isLoggedIn() {
      return this.$store.state.user.hasToken
    },
    username() {
      return this.$store.state.user.username
    },
  },
}
</script>
