var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FcCard",
    {
      scopedSlots: _vm._u(
        [
          {
            key: "header",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass: "cursor-pointer flex space-x-2",
                    on: { click: _vm.toggleCollapse }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-grow" },
                      [_vm._t("header", null, { collapsed: _vm.collapsed })],
                      2
                    )
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [!_vm.collapsed ? _vm._t("footer") : _vm._e()]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [!_vm.collapsed ? _vm._t("default") : _vm._e()],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }