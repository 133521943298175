<template>
  <label class="flex items-center">
    <input
      type="checkbox"
      ref="check"
      v-bind="$attrs"
      v-model="inputValue"
      :value="checkValue"
      class="input-check hidden"
    />
    <div
      class="inline-block cursor-pointer rounded-full h-4 w-7 border-2 border-muted2 relative overflow-hidden"
    >
      <div
        class="h-full absolute bg-primary-muted opacity-60 transition-all"
        :class="!value ? 'w-0' : 'w-full'"
      ></div>
      <div
        class="rounded-full w-1/2 h-full absolute transition-all opacity bg-normal"
        :class="!value ? 'right-1/2' : 'right-0'"
      />
    </div>
    <div v-if="$slots.default" class="inline-block ml-1">
      <slot />
    </div>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    checkValue: {
      type: String,
      default: "",
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
  },
}
</script>

<style scoped>
.input-check:checked ~ .input-check__circle {
  display: none;
}
.input-check:not(:checked) ~ .input-check__checked {
  display: none;
}
</style>
