import Vue from "vue"
import VueI18n from "vue-i18n"

import en from "@/locales/en"
import fi from "@/locales/fi"

import flagFI from "flag-icon-css/flags/4x3/fi.svg"
import flagGB from "flag-icon-css/flags/4x3/gb.svg"

Vue.use(VueI18n)

export const locales = [
  {
    code: "en",
    flag: flagGB,
  },
  {
    code: "fi",
    flag: flagFI,
  },
]

const localeCodes = locales.map(l => l.code)

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: { en, fi },
})

export function setLocale(locale) {
  if (localeCodes.includes(locale)) {
    i18n.locale = locale
    document.documentElement.setAttribute("lang", locale)
    return true
  }
  return false
}
