import { commit, set } from "~/lib/VuexHelpers"
import { deserialize } from "~/lib/VuexPersist"

const state = () => ({
  alarms: [],
  searchInputSite: "",
  searchInputAlarm: "",
  isAlarmActive: false,
})
const getters = {
  alarmsBySite: state =>
    state.alarms.reduce((acc, cur) => {
      if (!(cur.siteId in acc)) {
        acc[cur.siteId] = []
      }
      acc[cur.siteId].push(cur)
      return acc
    }, {}),
  getSearchInputSite: state => state.searchInputSite,
  getSearchInputAlarm: state => state.searchInputAlarm,
  getSearchedAlarmsBySiteId: (state, getters) => siteId => {
    const searchInputAlarm = state.searchInputAlarm.toLowerCase()
    const alarms = getters.alarmsBySite[siteId] ?? []
    return alarms.filter(alarm =>
      (alarm.message + alarm.device).toLowerCase().includes(searchInputAlarm)
    )
  },
  getSearchedSites: (state, getters) => alarmSites => {
    const searchInputSite = state.searchInputSite.toLowerCase()
    const searchInputAlarm = state.searchInputAlarm.toLowerCase()
    const noAlarmFilter = !searchInputAlarm
    const hasAlarm = alarm =>
      (alarm.message + alarm.device).toLowerCase().includes(searchInputAlarm)
    const filterSites = site =>
      (site.name + site.address).toLowerCase().includes(searchInputSite) &&
      (noAlarmFilter || (getters.alarmsBySite[site.id] ?? []).some(hasAlarm))
    return alarmSites.filter(filterSites)
  },
  getAlarmActive: state => state.isAlarmActive,
}

const mutations = {
  deserialize,
  setAlarms: set("alarms"),
  setSearchInputSite: (state, searchInputSite) => {
    state.searchInputSite = searchInputSite
  },
  setSearchInputAlarm: (state, searchInputAlarm) => {
    state.searchInputAlarm = searchInputAlarm
  },
  setAlarmActive: (state, payload) => {
    state.isAlarmActive = payload
  },
}

const actions = {
  setAlarms: commit("setAlarms"),
  setSearchInputSite: (context, searchInputSite) => {
    context.commit("setSearchInputSite", searchInputSite)
  },
  setSearchInputAlarm: (context, searchInputAlarm) => {
    context.commit("setSearchInputAlarm", searchInputAlarm)
  },
  setAlarmActive: (context, payload) => {
    context.commit("setAlarmActive", payload)
  },
}

export default {
  namespaced: true,
  persist: true,
  state,
  getters,
  mutations,
  actions,
}
