var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MenuCard",
    {
      staticClass: "rounded",
      attrs: { name: "dataLayers", icon: "layer-group" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function(ref) {
            var collapsed = ref.collapsed
            return [
              _c(
                "div",
                { staticClass: "flex items-center space-x-2" },
                [
                  _c("span", { staticClass: "flex-1" }, [
                    _vm._v(" " + _vm._s(_vm.$t("dataLayers.title")) + " ")
                  ]),
                  collapsed
                    ? _c(
                        "span",
                        [
                          _c("DataLayerValue", {
                            attrs: {
                              layer: _vm.currentDataLayer,
                              value: _vm.getMeanForVisibleSites(
                                _vm.currentDataLayer
                              )
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-icon", {
                    ref: "blip",
                    staticClass: "blip",
                    attrs: { name: "circle" }
                  })
                ],
                1
              )
            ]
          }
        },
        {
          key: "footer",
          fn: function() {
            return [
              _vm.draggableDataLayers.length
                ? _c(
                    "div",
                    [
                      _c(
                        "draggable",
                        {
                          attrs: { handle: ".handle" },
                          model: {
                            value: _vm.draggableDataLayers,
                            callback: function($$v) {
                              _vm.draggableDataLayers = $$v
                            },
                            expression: "draggableDataLayers"
                          }
                        },
                        _vm._l(_vm.draggableDataLayers, function(layer) {
                          return _c(
                            "div",
                            {
                              key: layer.id,
                              staticClass:
                                "flex h-8 px-1 space-x-2 items-stretch rounded from-surface-accented to-transparent hover:bg-opacity-50 hover:bg-surface-accented",
                              class: {
                                "bg-gradient-to-r":
                                  layer.id === _vm.currentDataLayer.id
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "handle flex items-center text-muted opacity-25 cursor-grab"
                                },
                                [
                                  _c("v-icon", {
                                    attrs: { name: "grip-vertical" }
                                  })
                                ],
                                1
                              ),
                              _c("DataLayerRow", {
                                staticClass:
                                  "flex-1 justify-between cursor-pointer rounded transition-all",
                                attrs: {
                                  layer: layer,
                                  value: _vm.getMeanForVisibleSites(layer)
                                },
                                on: {
                                  click: function($event) {
                                    _vm.currentDataLayer = layer
                                  }
                                }
                              }),
                              _c("FcButton", {
                                staticClass: "p-0",
                                attrs: { sm: "", icon: "cog" },
                                on: {
                                  click: function() {
                                    return _vm.toggleEditModal(layer)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "flex-1 text-left" },
        [
          _c(
            "FcButton",
            {
              staticClass: "hover:bg-primary hover:bg-opacity-20",
              attrs: { xs: "", icon: "layer-group" },
              on: {
                click: function($event) {
                  return _vm.toggleAddModal()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("dataLayers.form.add")) + " ")]
          ),
          _c(
            "FcButton",
            {
              staticClass: "hover:bg-primary hover:bg-opacity-20",
              attrs: { xs: "", icon: "layer-group" },
              on: {
                click: function($event) {
                  return _vm.toggleResetModal()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("dataLayers.reset")) + " ")]
          ),
          _c("FcModal", {
            ref: "modalAddLayer",
            attrs: { md: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var modal = ref.modal
                  return [
                    _c(
                      "FcCard",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "flex items-center" },
                                    [
                                      _c("div", { staticClass: "flex-1" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("dataLayers.form.add")
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c("FcButton", {
                                        staticClass:
                                          "p-1 opacity-50 hover:opacity-100",
                                        attrs: {
                                          sm: "",
                                          icon: "times",
                                          "icon-class": "text-muted"
                                        },
                                        on: {
                                          click: function($event) {
                                            return modal.close()
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("FormDataLayer", {
                          on: { submit: _vm.onSubmitAddDataLayer }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.isAddModalShown,
              callback: function($$v) {
                _vm.isAddModalShown = $$v
              },
              expression: "isAddModalShown"
            }
          }),
          _c("FcModal", {
            ref: "modalEditLayer",
            attrs: { md: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var modal = ref.modal
                  return [
                    _c(
                      "FcCard",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "flex items-center" },
                                    [
                                      _c("div", { staticClass: "flex-1" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("dataLayers.form.edit")
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c("FcButton", {
                                        staticClass:
                                          "p-1 opacity-50 hover:opacity-100",
                                        attrs: {
                                          sm: "",
                                          icon: "times",
                                          "icon-class": "text-muted"
                                        },
                                        on: {
                                          click: function($event) {
                                            return modal.close()
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("FormDataLayer", {
                          attrs: { "base-layer": _vm.editLayer },
                          on: {
                            submit: _vm.onSubmitEditDataLayer,
                            delete: _vm.onDeleteEditDataLayer
                          }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.isEditModalShown,
              callback: function($$v) {
                _vm.isEditModalShown = $$v
              },
              expression: "isEditModalShown"
            }
          }),
          _c("FcModal", {
            ref: "modalReset",
            attrs: { md: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var modal = ref.modal
                  return [
                    _c(
                      "FcCard",
                      {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "flex items-center" },
                                    [
                                      _c("div", { staticClass: "flex-1" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("dataLayers.reset")) +
                                            " "
                                        )
                                      ]),
                                      _c("FcButton", {
                                        staticClass:
                                          "p-1 opacity-50 hover:opacity-100",
                                        attrs: {
                                          sm: "",
                                          icon: "times",
                                          "icon-class": "text-muted"
                                        },
                                        on: {
                                          click: function($event) {
                                            return modal.close()
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("div", { staticClass: "text-center py-3" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("dataLayers.resetConfirm")) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex" },
                          [
                            _c(
                              "FcButton",
                              {
                                staticClass:
                                  "flex-1 justify-center hover:bg-danger hover:bg-opacity-20 px-2",
                                attrs: {
                                  icon: "times",
                                  "icon-class": "text-danger"
                                },
                                on: {
                                  click: function() {
                                    return _vm.toggleResetModal(false)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("actions.cancel")) + " "
                                )
                              ]
                            ),
                            _c(
                              "FcButton",
                              {
                                staticClass:
                                  "flex-1 justify-center hover:bg-success hover:bg-opacity-20 px-2",
                                attrs: {
                                  "icon-class": "text-success",
                                  icon: "check"
                                },
                                on: { click: _vm.resetToDefaultLayers }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("actions.reset")) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.isResetModalShown,
              callback: function($$v) {
                _vm.isResetModalShown = $$v
              },
              expression: "isResetModalShown"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }