var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hidden md:flex space-x-2 px-2 justify-center" },
    _vm._l(_vm.langs, function(lang) {
      return _c("img", {
        key: lang.locale,
        staticClass: "h-4 cursor-pointer",
        class: _vm.isCurrentLocale(lang.locale) ? "opacity-100" : "opacity-50",
        attrs: { src: lang.flag, alt: lang.name },
        on: {
          click: function($event) {
            return _vm.setLocale(lang.locale)
          }
        }
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }