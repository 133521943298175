<template>
  <div class="hidden md:flex space-x-2 px-2 justify-center">
    <img
      v-for="lang of langs"
      :key="lang.locale"
      :src="lang.flag"
      :alt="lang.name"
      class="h-4 cursor-pointer"
      :class="isCurrentLocale(lang.locale) ? 'opacity-100' : 'opacity-50'"
      @click="setLocale(lang.locale)"
    />
  </div>
</template>

<script>
import flagFI from "flag-icon-css/flags/4x3/fi.svg"
import flagGB from "flag-icon-css/flags/4x3/gb.svg"

export default {
  computed: {
    currentLocale() {
      return this.$store.state.preferences.locale
    },
    langs() {
      return [
        {
          name: "English",
          locale: "en",
          flag: flagGB,
        },
        {
          name: "Finnish",
          locale: "fi",
          flag: flagFI,
        },
      ]
    },
  },

  methods: {
    isCurrentLocale(locale) {
      return locale === this.currentLocale
    },
    setLocale(locale) {
      this.$store.commit("preferences/setLocale", locale)
    },
  },
}
</script>

<style></style>
