<template>
  <FcCard class="rounded">
    <div class="space-y-2 flex flex-col">
      <div class="space-y-2 divide-y divide-surface-accented">
        <div v-for="site of searchedSites" :key="site.id" class="pt-2">
          <FcButton
            class="w-full p-0 normal-case"
            text-class="text-left items-center w-full font-normal space-y-2 text-xs"
            @click="goToSite(site)"
          >
            <div class="flex items-start space-x-2">
              <div class="flex-1">
                <div class="font-bold text-sm">{{ site.name }}</div>
                <div>{{ site.address }}</div>
              </div>
              <div class="flex-none text-lg font-bold">
                {{ alarmsBySite[site.id].length }}
              </div>
            </div>
            <div class="border-alert border-opacity-30 border-l space-y-2 ml-2 pl-2">
              <div
                v-for="[index, alarm] of searchedAlarmsBySiteId(site.id).entries()"
                :key="index"
                class="flex-1"
              >
                <v-icon name="exclamation-triangle" class="text-alert" />
                <span class="ml-1 font-bold">{{ alarm.device }}</span>
                <div class="ml-5 text-clip overflow-hidden">{{ alarm.message }}</div>
              </div>
            </div>
          </FcButton>
        </div>
      </div>
      <div v-if="!alarmSites.length" class="text-muted text-sm italic">
        {{ $t("alarms.noAlarms") }}
      </div>
    </div>
  </FcCard>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      active: true,
    }
  },
  computed: {
    ...mapGetters({
      alarmsBySite: "alarm/alarmsBySite",
      getSite: "map/getSite",
      searchInputSite: "alarm/getSearchInputSite",
      searchInputAlarm: "alarm/getSearchInputAlarm",
      getSearchedAlarmsBySiteId: "alarm/getSearchedAlarmsBySiteId",
      getSearchedSites: "alarm/getSearchedSites",
    }),
    alarmSites() {
      return Object.keys(this.alarmsBySite)
        .map(id => this.getSite(id))
        .filter(Boolean)
    },
    searchedSites() {
      return this.getSearchedSites(this.alarmSites)
    },
    totalAlarms() {
      return this.$store.state.alarm?.alarms?.length ?? 0
    },
  },

  watch: {
    searchInputSite() {
      this.loadAlarmedMarkers()
      this.loadAlarmedSite()
    },
    searchInputAlarm() {
      this.loadAlarmedMarkers()
    },
  },
  methods: {
    goToSite(site) {
      this.$map.goToSite(site)
    },
    searchedAlarmsBySiteId(siteId) {
      return this.getSearchedAlarmsBySiteId(siteId)
    },
    loadAlarmedMarkers() {
      this.$map.loadMarkers(this.searchedSites)
    },
    loadAlarmedSite() {
      this.goToSite(this.searchedSites[0])
    },
  },
}
</script>
