<template>
  <MenuCard class="rounded" name="geoLayers" icon="map-marker-alt">
    <template #header>
      {{ $t("geoLayers.title") }}
    </template>
    <FcButton
      xs
      icon="cog"
      class="hover:bg-primary hover:bg-opacity-20"
      @click="isModalShown = !isModalShown"
    >
      {{ $t("geoLayers.manage") }}
    </FcButton>
    <FcModal md ref="modalGeoLayer" v-model="isModalShown" v-slot="{ modal }">
      <FcCard class="normal-case font-normal">
        <template #header>
          <div class="flex items-center">
            <div class="flex-1">
              {{ $t("geoLayers.title") }}
            </div>
            <FcButton
              sm
              icon="times"
              class="p-1 opacity-50 hover:opacity-100"
              icon-class="text-muted"
              @click="modal.close()"
            />
          </div>
        </template>
        <div class="py-2">
          <div v-for="layer of geoLayerList" :key="layer.path" class="space-y-2">
            <FcInputToggle
              class="inline"
              :value="!isGeoLayerHidden(layer)"
              @input="val => toggleGeoLayerVisibility(layer, val)"
            >
              {{ layer.name }}
            </FcInputToggle>
          </div>
        </div>
      </FcCard>
    </FcModal>
  </MenuCard>
</template>

<script>
import { mapGetters } from "vuex"
import MenuCard from "~/components/menus/MenuCard.vue"

export default {
  components: { MenuCard },

  data: () => ({
    isModalShown: false,
  }),

  computed: {
    ...mapGetters({
      geoLayerList: "layers/geoLayerList",
      isGeoLayerHidden: "layers/isGeoLayerHidden",
    }),
  },

  methods: {
    toggleGeoLayerVisibility(geodata, value) {
      this.$store.commit("layers/setGeoLayerVisibility", { geodata, visible: !value })
    },
  },
}
</script>
