<template>
  <nav class="flex flex-col py-3 px-4 space-y-2 md:space-y-3">
    <div class="px-4">
      <div class="flex items-center">
        <div class="flex-1">
          <FiksuCityLogo class="w-full max-w-xs m-auto p-2" :dark="$theme.dark" />
        </div>
        <div class="flex-0">
          <PreferencesMenu />
        </div>
      </div>
      <div class="font-mono text-center text-lg uppercase text-muted opacity-60 tracking-wider">
        Beta
      </div>
    </div>
    <div class="md:block space-y-2 px-2">
      <div class="flex mb-4">
        <FcButton
          lg
          v-for="[i, card] of menuCards.entries()"
          :key="i"
          :icon="getCardIcon(card)"
          @click="setCard(card)"
          :class="[{ 'bg-normal bg-opacity-30': currentCard === card }]"
          class="hover:bg-normal hover:bg-opacity-10 flex-1"
          icon-class="text-normal"
          text-class="block text-xs"
        >
          {{ $t(`${card}.title`) }}
        </FcButton>
      </div>
    </div>
    <div class="md:block" :class="{ 'flex-1 overflow-y-auto': currentCard !== 'alarms' }">
      <FcCard handle=".handle" class="rounded-lg">
        <component :is="getCardComponent(currentCard)" />
      </FcCard>
    </div>
    <div v-if="currentCard === 'alarms'" class="md:block flex-1 overflow-y-auto">
      <FcCard handle=".handle" class="rounded-lg">
        <AlarmsList />
      </FcCard>
    </div>

    <div class="text-center text-xs text-muted2 px-4">
      <div class="text-center md:pb-2">
        <a :href="Config.fiksuWebUrl">
          <FiksuWebLogo class="hidden md:inline w-40" :dark="$theme.dark" />
        </a>
      </div>
      <span>{{ Config.version }}</span> |
      <a
        @click.prevent="isReleaseNotesModalShown = !isReleaseNotesModalShown"
        class="cursor-pointer"
      >
        {{ $t("releaseNotes.title") }}
      </a>
    </div>
    <FcModal md v-model="isReleaseNotesModalShown" v-slot="{ modal }">
      <FcCard>
        <template #header>
          <div class="flex items-center">
            <div class="flex-1 text-xl">
              {{ $t("releaseNotes.title") }}
            </div>
            <FcButton
              sm
              icon="times"
              class="p-1 opacity-50 hover:opacity-100"
              icon-class="text-muted"
              @click="modal.close()"
            />
          </div>
        </template>
        <ReleaseNotes />
      </FcCard>
    </FcModal>
  </nav>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import DataLayerCard from "./datalayers/DataLayerCard.vue"
import AlarmsCard from "./alarms/AlarmsCard.vue"
import AlarmsList from "./alarms/AlarmsList.vue"
import GeoLayerCard from "./geolayers/GeoLayerCard.vue"
import FiksuCityLogo from "../logo/FiksuCityLogo.vue"
import FiksuWebLogo from "../logo/FiksuWebLogo.vue"
import PreferencesMenu from "./PreferencesMenu.vue"
import Config from "~/config"

const CARD_COMPONENTS = {
  dataLayers: DataLayerCard,
  alarms: AlarmsCard,
  geoLayers: GeoLayerCard,
}
const CARD_ICONS = {
  dataLayers: "layer-group",
  alarms: "exclamation-triangle",
  geoLayers: "map-marker-alt",
}

export default {
  mixins: [Config.Mixin],

  components: {
    FiksuCityLogo,
    FiksuWebLogo,
    PreferencesMenu,
    ReleaseNotes: () => import("../ReleaseNotes.vue"),
    AlarmsList,
  },

  data: () => ({
    isReleaseNotesModalShown: false,
    currentCard: "dataLayers",
  }),

  computed: {
    ...mapState({
      cardOrder: state => state.preferences.cardOrder,
    }),
    ...mapState("view", {
      currentView: "view",
    }),
    ...mapGetters({
      geoLayerList: "layers/geoLayerList",
    }),
    showSiteStatus() {
      return Config.isFeatureEnabled.siteStatus
    },
    showDataLayers() {
      return Config.isFeatureEnabled.dataLayers
    },
    showFavorites() {
      return Config.isFeatureEnabled.favorites
    },
    showAlarms() {
      return Config.isFeatureEnabled.alarms
    },
    showGeoLayers() {
      return Config.isFeatureEnabled.geoLayers && this.geoLayerList.length > 0
    },
    menuCards: {
      get() {
        const enabled = new Set([
          this.showDataLayers && "dataLayers",
          this.showAlarms && "alarms",
          this.showGeoLayers && "geoLayers",
        ])
        return this.cardOrder.filter(card => enabled.has(card))
      },
      set(value) {
        this.$store.dispatch("preferences/setCardOrder", value)
      },
    },
  },

  methods: {
    getCardComponent(key) {
      return CARD_COMPONENTS[key]
    },
    getCardIcon(key) {
      return CARD_ICONS[key]
    },
    setCard(card) {
      this.currentCard = card
    },
  },
}
</script>

<style scoped></style>
