import Vue from "vue"
import store from "~/store"

import pApi from "~/plugins/api"
import pMap from "~/plugins/map"
import pAlarm from "~/plugins/alarm"
import pTheme from "~/plugins/theme"

const plugins = [pApi, pMap, pAlarm, pTheme]

async function loadPlugins() {
  const ctx = { $store: store }
  const inject = (name, instance) => {
    const prefixed = "$" + name
    ctx[prefixed] = instance
    Vue.mixin({
      beforeCreate: function() {
        const options = this.$options
        if (options[prefixed]) {
          this[prefixed] = instance
        } else if (options.parent && options.parent[prefixed]) {
          this[prefixed] = options.parent[prefixed]
        }
      },
    })
  }
  for (const plugin of plugins) {
    if (typeof plugin === "function") {
      plugin(ctx, inject)
    }
  }
  return ctx
}

export default loadPlugins
