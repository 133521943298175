import Vue from "vue"

export const findInMap = (prop, key) => state => state[prop]?.[key] ?? null

export const findInList = (prop, key) => (state, val) =>
  state[prop].find(x => x[key] === val) || null

export const set = (prop, then = () => {}) => (state, val) => {
  state[prop] = val
  then(state, val)
}

export const toggle = (prop, then = () => {}) => state => {
  state[prop] = !state[prop]
  then(state, state[prop])
}

export const updateMap = (prop, key, then = () => {}) => (state, val) => {
  state[prop] = { ...state[prop], [val[key]]: val }
  then(state, val)
}

export const updateList = (prop, key = null, then = () => {}) => (state, val) => {
  const value = key !== null ? val[key] : val
  const i = state[prop].findIndex(x => x === value)
  if (i < 0) {
    state[prop].push(value)
  } else {
    state[prop].splice(i, 1, value)
  }
  then(state, val)
}

export const pushToList = (prop, then = () => {}) => (state, val) => {
  state[prop].push(val)
  then(state, val)
}

export const removeFromMap = (prop, key, then = () => {}) => (state, val) => {
  Vue.delete(state[prop], val[key])
  then(state, val)
}

export const removeFromList = (prop, key = null, then = () => {}) => (state, val) => {
  const value = key !== null ? val[key] : val
  const i = state[prop].indexOf(value)
  if (i >= 0) {
    state[prop] = [...state[prop].slice(0, i), ...state[prop].slice(i + 1)]
  }
  then(state, val)
}

export const commit = key => ({ commit }, value) => commit(key, value)
