<template>
  <div>
    <div class="w-full flex justify-center space-x-2">
      <FcButton class="rounded-full bg-surface px-4" icon="star" @click="toggleFavoriteModal()">
        {{ $t("favorites.title") }}
      </FcButton>
      <FcButton class="rounded-full bg-surface px-4" icon="search" @click="toggleSearchModal()">
        {{ $t("siteSearch.label") }}
      </FcButton>
    </div>
    <FcModal md v-model="isFavoriteModalShown">
      <FavoritesCard class="m-auto w-4/5" />
    </FcModal>
    <FcModal md v-model="isSearchModalShown">
      <FcInputAutoComplete
        class="m-auto w-4/5"
        @input="site => site && $map.goToSite(site)"
        :items="searchItems"
      >
        <template #label>
          <v-icon name="search" class="mr-1" /> {{ $t("siteSearch.label") }}
        </template>
        <template #entry="{item}">
          <div>{{ item.value.name }}</div>
          <div class="text-xs">{{ item.value.address }}</div>
        </template>
      </FcInputAutoComplete>
    </FcModal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import FavoritesCard from "~/components/favorites/FavoritesCard.vue"

export default {
  components: { FavoritesCard },
  data: () => ({
    isFavoriteModalShown: false,
    isSearchModalShown: false,
  }),

  computed: {
    ...mapState("layers", {
      lastUpdated: "lastUpdated",
    }),
    ...mapGetters({
      sitesOnline: "map/sitesOnline",
    }),
    searchItems() {
      return this.sitesOnline
        .map(i => ({
          text: `${i.name} ${i.address}`,
          value: i,
        }))
        .sort((a, b) => (a.text < b.text ? -1 : 1))
    },
  },

  watch: {
    async lastUpdated() {},
  },

  methods: {
    toggleFavoriteModal(open = null) {
      this.isFavoriteModalShown = open ?? !this.isFavoriteModalShown
    },

    toggleSearchModal(open = null) {
      this.isSearchModalShown = open ?? !this.isSearchModalShown
    },
  },
}
</script>
