import { BaseModel, Field } from "~/lib/Model"

export class Alarm extends BaseModel {
  get fields() {
    return {
      module: Field.String(),
      siteId: Field.String(),
      device: Field.String(),
      position: Field.String(),
      message: Field.String(),
      date: Field.ISODate(),
      isNoResponse: Field.Boolean(),
      disabled: Field.Boolean(),
    }
  }

  constructor(...args) {
    super(...args)

    this._messages = {}
    if (!this.message.startsWith("\x01")) {
      this._messages["en-gb"] = this.message
    } else {
      const stripCtrl = /^\x01+|\x01+$/g // eslint-disable-line no-control-regex
      const parts = this.message.replace(stripCtrl, "").split("\x01")
      for (let i = 0; i < parts.length; i += 2) {
        this._messages[parts[i]] = parts[i + 1]
      }
    }
  }

  getMessage(locale = "en-gb") {
    const order = new Set([locale, "en-gb", "fi-fi"])
    for (const lang of order) {
      if (lang in this._messages) {
        return this._messages[lang]
      }
    }
    return ""
  }
}
