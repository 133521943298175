var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CollapsibleCard",
    {
      staticClass: "rounded",
      attrs: { name: "favorites" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.$t("favorites.title")) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 lg:grid-cols-2 gap-1 lg:gap-3" },
        [
          _vm._l(_vm.favoriteSites, function(site) {
            return _c(
              "FcButton",
              {
                key: site.id,
                staticClass:
                  "hover:bg-primary hover:bg-opacity-20 p-1 min-w-min flex items-start text-left justify-start lg:flex-col lg:text-center lg:justify-center lg:space-x-0",
                attrs: {
                  icon: "regular/star",
                  "text-class": "font-normal text-xs"
                },
                on: {
                  click: function($event) {
                    return _vm.goToSite(site)
                  }
                }
              },
              [_vm._v(" " + _vm._s(site.name) + " ")]
            )
          }),
          !_vm.favoriteSites.length
            ? _c(
                "div",
                { staticClass: "col-span-2 text-muted text-sm italic" },
                [_vm._v(" " + _vm._s(_vm.$t("favorites.guide")) + " ")]
              )
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }