var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "space-y-2" },
    [
      _c(
        "draggable",
        {
          staticClass: "space-y-2",
          attrs: { handle: ".handle" },
          model: {
            value: _vm.priorityList,
            callback: function($$v) {
              _vm.priorityList = $$v
            },
            expression: "priorityList"
          }
        },
        _vm._l(_vm.priorityList, function(point) {
          return _c(
            "div",
            { key: point.__key, staticClass: "flex space-x-2 items-stretch" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "handle text-muted opacity-25 cursor-grab flex items-center"
                },
                [
                  _c("v-icon", {
                    staticClass: "text-lg",
                    attrs: { name: "grip-vertical" }
                  })
                ],
                1
              ),
              _c("DataPointInput", {
                staticClass: "flex-1 space-x-2",
                attrs: { value: point },
                on: {
                  input: function(n) {
                    return _vm.updatePoint(point, n)
                  }
                }
              }),
              _c("FcButton", {
                staticClass:
                  "flex-0 hover:bg-danger hover:bg-opacity-20 rounded-full flex self-center justify-center w-8 h-8",
                attrs: { icon: "times", "icon-class": "text-danger" },
                on: {
                  click: function() {
                    return _vm.removePoint(point)
                  }
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _c(
        "FcButton",
        {
          staticClass: "rounded hover:bg-primary hover:bg-opacity-20",
          attrs: { xs: "", icon: "plus" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.addPoint.apply(null, arguments)
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("dataLayers.form.addPoint")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }