<template>
  <div class="space-y-2">
    <draggable v-model="priorityList" class="space-y-2" handle=".handle">
      <div v-for="point of priorityList" :key="point.__key" class="flex space-x-2 items-stretch">
        <div class="handle text-muted opacity-25 cursor-grab flex items-center">
          <v-icon name="grip-vertical" class="text-lg" />
        </div>
        <DataPointInput
          :value="point"
          @input="n => updatePoint(point, n)"
          class="flex-1 space-x-2"
        />
        <FcButton
          icon="times"
          class="flex-0 hover:bg-danger hover:bg-opacity-20 rounded-full flex self-center justify-center w-8 h-8"
          icon-class="text-danger"
          @click="() => removePoint(point)"
        />
      </div>
    </draggable>
    <FcButton
      xs
      icon="plus"
      class="rounded hover:bg-primary hover:bg-opacity-20"
      @click.prevent="addPoint"
    >
      {{ $t("dataLayers.form.addPoint") }}
    </FcButton>
  </div>
</template>

<script>
import { DataSource, DataSourcePoint } from "~/models"
import draggable from "vuedraggable"
import DataPointInput from "./DataPointInput.vue"

export default {
  components: { draggable, DataPointInput },
  props: {
    value: {
      type: DataSource,
      default: null,
    },
  },

  computed: {
    priorityList: {
      get() {
        const list = this.value?.priorityList ?? []
        let key = 0
        for (const point of list) {
          point.__key = ++key
        }
        return list
      },
      set(priorityList) {
        this.$emit("input", DataSource.load({ priorityList }))
      },
    },
  },

  methods: {
    updatePoint(oldPoint, newPoint) {
      oldPoint.device = newPoint.device
      oldPoint.position = newPoint.position
      this.priorityList = [...this.priorityList]
    },

    addPoint() {
      this.priorityList.push(DataSourcePoint.load({}))
    },

    removePoint(point) {
      const i = this.priorityList.indexOf(point)
      this.priorityList = [...this.priorityList.slice(0, i), ...this.priorityList.slice(i + 1)]
    },

    movePoint(point, direction) {
      const from = this.priorityList.indexOf(point)
      const to = from + direction
      if (to >= 0 && to < this.priorityList.length) {
        const list = [...this.priorityList]
        list.splice(to, 0, ...list.splice(from, 1))
        this.priorityList = list
      }
    },
  },
}
</script>
