<template>
  <div class="bg-surface divide-y divide-surface-accented">
    <div v-if="$slots.header" class="py-2 px-4 text-left text-sm font-bold uppercase">
      <slot name="header" />
    </div>
    <div v-if="$slots.default" class="py-2 px-4" :class="divClass">
      <slot />
    </div>
    <div v-if="$slots.footer" class="py-2 px-4">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    divClass: {
      type: String,
      default: "",
    },
  },
}
</script>
