<template>
  <FcMenu ref="menu" :width="activatorWidth">
    <template #activator="{on, active}">
      <div
        ref="activator"
        v-on="on"
        class="fc-input-select relative cursor-pointer"
        :class="{ active, 'fc-input-select__labelled': label }"
      >
        <div
          class="flex w-full items-center px-4 bg-input rounded focus:outline-none ring-input-accented transition"
          :class="{ 'ring-2': active }"
        >
          <div class="fc-input-select--selector flex-1">
            <slot name="entry" :item="currentItem">
              <div>{{ currentItem.text }}</div>
            </slot>
            <label
              class="absolute top-0 left-0 pt-1 px-4 text-xs text-muted pointer-events-none transition-all"
            >
              {{ label }}
            </label>
          </div>
          <v-icon
            name="caret-down"
            class="chevron flex-shrink-1 top-0 pl-2 right-4 text-xl text-muted transform transition"
            :class="{ '-scale-y-1': active }"
          />
        </div>
      </div>
    </template>
    <FcList
      ref="list"
      :value="selected"
      @change="onSelect"
      flat
      mandatory
      active-class="bg-input-accented"
      class="bg-input overflow-y-auto rounded ring-2 ring-input-accented"
    >
      <FcListItem
        ref="listItems"
        v-for="[i, item] of selectItems.entries()"
        :key="i"
        :value="item.value"
        class="px-4 py-2 bg-input hover:bg-input-accented focus:bg-input-accented focus:outline-none w-full transition-colors duration-75"
        tabindex="-1"
      >
        <slot name="entry" :item="item">
          <div>{{ item.text }}</div>
        </slot>
      </FcListItem>
    </FcList>
  </FcMenu>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Object],
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    active: false,
    activatorWidth: null,
  }),

  computed: {
    selected: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
    selectItems() {
      return this.items.map(i => {
        if (typeof i === "string") {
          i = { text: i, value: i }
        }
        return {
          text: "",
          value: "",
          disabled: false,
          ...i,
        }
      })
    },
    currentItem() {
      return this.selectItems.find(i => i.value === this.selected) || {}
    },
    currentIndex() {
      return this.selectItems.findIndex(i => i.value === this.selected)
    },
  },

  watch: {
    active() {
      if (this.active) {
        this.addListeners()
        const rect = this.$refs.activator.getBoundingClientRect()
        this.activatorWidth = rect.width
      } else {
        this.removeListeners()
      }
    },
  },

  mounted() {
    this.$watch("$refs.menu.active", active => {
      this.active = active
    })
  },

  beforeDestroy() {
    this.removeListeners()
  },

  methods: {
    addListeners() {
      window.addEventListener("keydown", this.onKeyDown)
    },
    removeListeners() {
      window.removeEventListener("keydown", this.onKeyDown)
    },
    close() {
      this.$refs.menu?.close()
    },
    focusAndSelectIndex(index) {
      if (this.items[index]) {
        this.selected = this.items[index].value
        this.$refs.listItems[index]?.$el?.focus()
      }
    },
    onKeyDown(evt) {
      const nextIndex = Math.min(this.items.length - 1, this.currentIndex + 1)
      const prevIndex = Math.max(0, this.currentIndex - 1)
      switch (evt.code) {
        case "ArrowDown":
          return this.focusAndSelectIndex(nextIndex)
        case "ArrowUp":
          return this.focusAndSelectIndex(prevIndex)
        case "Escape":
        case "Enter":
          return this.close()
      }
    },
    onSelect(item) {
      this.selected = item
      this.close()
    },
  },
}
</script>

<style lang="postcss" scoped>
.fc-input-select .fc-input-select--selector {
  @apply py-2;
}
.fc-input-select.fc-input-select__labelled .fc-input-select--selector {
  @apply pt-6 pb-2;
}
</style>
