import Vue from "vue"
import Vuex from "vuex"

import map from "./map"
import user from "./user"
import layers from "./layers"
import preferences from "./preferences"
import alarm from "./alarm"
import view from "./view"

import createPersistPlugin from "~/lib/VuexPersist"
import createInitPlugin from "~/lib/VuexInit"

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production"

const modules = { map, user, layers, preferences, alarm, view }

const persisted = Object.entries(modules)
  .filter(([_k, v]) => v.persist)
  .map(([k, _v]) => k)

const persistPlugin = createPersistPlugin({ modules: persisted, prefix: "fc:" })
const initPlugin = createInitPlugin()

const store = new Vuex.Store({
  modules,
  strict: debug,
  plugins: [persistPlugin, initPlugin],
})

export default store
