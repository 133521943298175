<template>
  <FcCard>
    <template #header>
      <div @click="toggleCollapse" class="cursor-pointer flex space-x-2">
        <div class="flex-grow">
          <slot name="header" :collapsed="collapsed" />
        </div>
      </div>
    </template>
    <slot v-if="!collapsed" />
    <template #footer>
      <slot name="footer" v-if="!collapsed" />
    </template>
  </FcCard>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapGetters({
      isCardCollapsed: "preferences/isCardCollapsed",
    }),
    collapsed() {
      return this.isCardCollapsed(this.name)
    },
  },

  methods: {
    toggleCollapse() {
      if (this.name) {
        const method = this.collapsed ? "expand" : "collapse"
        this.$store.dispatch(`preferences/${method}Card`, this.name)
      }
    },
  },
}
</script>
