import { RestClient } from "~/lib/RestClient"
import config from "~/config"
import { Point, Site, Geodata, Alarm } from "~/models"

export default class APIClient {
  constructor() {
    this.rest = new RestClient(config.apiBaseUrl, {
      fetch: { credentials: "include" },
    })
    this.rest.define([
      "dispatch",
      "login",
      "logout",
      "authenticate",
      "introspect",
      "refresh",
      "sites",
      "points",
      "geodata",
      "alarms",
    ])
  }

  loginURL() {
    return this.rest.login.getURL().toString()
  }

  logoutURL() {
    return this.rest.logout.getURL().toString()
  }

  async authenticate(searchParams) {
    return this.rest.authenticate.get(Object.fromEntries(searchParams.entries()))
  }

  async introspect() {
    return this.rest.introspect.get()
  }

  async refresh() {
    return this.rest.refresh.post()
  }

  async dispatch(uris) {
    const res = await this.rest.dispatch.post({ uris })
    return res
  }

  async discoverPoints(dataSources, sites = [], module = "fiksu") {
    const res = await this.rest.points("discover").post({
      priorityLists: dataSources.map(s => s.priorityList),
      siteIds: sites.map(s => s.id),
      module,
    })
    return res.map(p => Point.load(p))
  }

  async fetchPointValues(points) {
    const res = await this.rest.points("value").post({
      points: points.map(p => ({
        module: p.module,
        siteId: p.siteId,
        device: p.device,
        position: p.position,
      })),
    })
    return res.map(p => Point.load(p))
  }

  async discoverHaystackPoints(points, sites = [], module = "fiksu") {
    const res = await this.rest.points("haystack").post({
      points,
      siteIds: sites.map(s => s.id),
      module,
    })
    return res.map(p => Point.load(p))
  }

  async getSiteIndex() {
    const index = await this.rest.sites("/").get()
    return index.map(s => Site.load(s))
  }

  async fetchGeodataIndex() {
    const index = await this.rest.geodata("/").get()
    return index.map(g => Geodata.load(g))
  }

  async fetchGeoJSON(geodata) {
    const data = await this.rest.geodata(geodata.path).get()
    return Geodata.load(data)
  }

  async getActiveAlarms(locale = "en-gb") {
    const data = await this.rest.alarms("/").get({ locale })
    return data.map(a => Alarm.load(a))
  }
}
