var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MenuCard",
    {
      staticClass: "rounded",
      attrs: { name: "geoLayers", icon: "map-marker-alt" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.$t("geoLayers.title")) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "FcButton",
        {
          staticClass: "hover:bg-primary hover:bg-opacity-20",
          attrs: { xs: "", icon: "cog" },
          on: {
            click: function($event) {
              _vm.isModalShown = !_vm.isModalShown
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("geoLayers.manage")) + " ")]
      ),
      _c("FcModal", {
        ref: "modalGeoLayer",
        attrs: { md: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var modal = ref.modal
              return [
                _c(
                  "FcCard",
                  {
                    staticClass: "normal-case font-normal",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { staticClass: "flex items-center" },
                                [
                                  _c("div", { staticClass: "flex-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("geoLayers.title")) +
                                        " "
                                    )
                                  ]),
                                  _c("FcButton", {
                                    staticClass:
                                      "p-1 opacity-50 hover:opacity-100",
                                    attrs: {
                                      sm: "",
                                      icon: "times",
                                      "icon-class": "text-muted"
                                    },
                                    on: {
                                      click: function($event) {
                                        return modal.close()
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "py-2" },
                      _vm._l(_vm.geoLayerList, function(layer) {
                        return _c(
                          "div",
                          { key: layer.path, staticClass: "space-y-2" },
                          [
                            _c(
                              "FcInputToggle",
                              {
                                staticClass: "inline",
                                attrs: { value: !_vm.isGeoLayerHidden(layer) },
                                on: {
                                  input: function(val) {
                                    return _vm.toggleGeoLayerVisibility(
                                      layer,
                                      val
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(layer.name) + " ")]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.isModalShown,
          callback: function($$v) {
            _vm.isModalShown = $$v
          },
          expression: "isModalShown"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }