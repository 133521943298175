<template>
  <InlineSvg class="fiksuweb-logo" :class="{ dark }" :src="logo" role="img" :alt="alt" />
</template>

<script>
import InlineSvg from "vue-inline-svg"

export default {
  components: { InlineSvg },

  props: {
    dark: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    logo() {
      return "/img/fiksuweblogo.svg"
    },
    alt() {
      return "Fiksu Web"
    },
  },
}
</script>

<style scoped>
.fiksuweb-logo.dark >>> path.st0 {
  fill: #fafafa !important;
}
.fiksuweb-logo.dark >>> path.st1 {
  fill: #fd1919 !important;
}
.fiksuweb-logo.dark >>> path.st2 {
  fill: #929292 !important;
}
</style>
