var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex" },
    [
      _c("FcInputText", {
        staticClass: "flex-1",
        attrs: { label: _vm.$t("dataLayers.form.device") },
        model: {
          value: _vm.device,
          callback: function($$v) {
            _vm.device = $$v
          },
          expression: "device"
        }
      }),
      _c("FcInputText", {
        staticClass: "flex-1",
        attrs: { label: _vm.$t("dataLayers.form.position") },
        model: {
          value: _vm.position,
          callback: function($$v) {
            _vm.position = $$v
          },
          expression: "position"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }