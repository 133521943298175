import happyPin from "@/assets/icons/happypin.svg"
import sadPin from "@/assets/icons/sadpin.svg"
import averagePin from "@/assets/icons/averagepin.svg"
import grayPin from "@/assets/icons/graypin.svg"
import grayCrossPin from "@/assets/icons/graycrosspin.svg"

const pinDefaults = {
  iconSize: [40, 40],
  iconAnchor: { x: 20, y: 40 },
}

export default {
  pin: {
    good: {
      ...pinDefaults,
      iconUrl: happyPin,
    },
    average: {
      ...pinDefaults,
      iconUrl: averagePin,
    },
    bad: {
      ...pinDefaults,
      iconUrl: sadPin,
    },
    unknown: {
      ...pinDefaults,
      iconUrl: grayPin,
    },
    nodata: {
      ...pinDefaults,
      iconUrl: grayCrossPin,
    },
  },
}
