var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FcMenu",
    {
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "FcButton",
                _vm._g(
                  {
                    staticClass: "p-2 hover:bg-surface",
                    attrs: {
                      icon: "user-cog",
                      "icon-class": "text-normal hover:text-accented",
                      xl: ""
                    }
                  },
                  on
                )
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "FcList",
        {
          staticClass:
            "overflow-y-auto h-full rounded border border-surface-accented w-96 max-w-full",
          attrs: { flat: "" }
        },
        [
          _c(
            "FcListItem",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoggedIn,
                  expression: "isLoggedIn"
                }
              ],
              staticClass: "space-x-4 border-b border-surface-accented"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex-1 text-muted text-sm truncate py-1 space-x-2"
                },
                [
                  _c("v-icon", { attrs: { name: "user" } }),
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.username) }
                  })
                ],
                1
              )
            ]
          ),
          _c("FcListItem", { staticClass: "space-x-4" }, [
            _c("div", { staticClass: "flex-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("locales.title")) + " ")
            ]),
            _c(
              "div",
              { staticClass: "flex-1 flex justify-end" },
              [
                _c("FcInputSelect", {
                  staticClass: "min-w-full w-max",
                  attrs: { items: _vm.localeItems },
                  scopedSlots: _vm._u([
                    {
                      key: "entry",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticClass: "flex items-center space-x-1" },
                            [
                              _c("img", {
                                staticClass: "w-4",
                                attrs: { src: item.flag }
                              }),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("locales." + item.value)
                                  )
                                }
                              })
                            ]
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.locale,
                    callback: function($$v) {
                      _vm.locale = $$v
                    },
                    expression: "locale"
                  }
                })
              ],
              1
            )
          ]),
          _c("FcListItem", { staticClass: "space-x-4" }, [
            _c("div", { staticClass: "flex-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("themes.title")) + " ")
            ]),
            _c(
              "div",
              { staticClass: "flex-1 flex justify-end" },
              [
                _c("FcInputSelect", {
                  staticClass: "min-w-full w-max",
                  attrs: { items: _vm.themeItems },
                  model: {
                    value: _vm.theme,
                    callback: function($$v) {
                      _vm.theme = $$v
                    },
                    expression: "theme"
                  }
                })
              ],
              1
            )
          ]),
          _c("FcListItem", { staticClass: "space-x-4" }, [
            _c("div", { staticClass: "flex-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("geoLayers.minZoom")) + " ")
            ]),
            _c(
              "div",
              { staticClass: "flex-1 flex justify-end" },
              [
                _c("FcInputSelect", {
                  staticClass: "min-w-full w-max",
                  attrs: { items: _vm.minGeoZoomItems },
                  model: {
                    value: _vm.minGeoZoom,
                    callback: function($$v) {
                      _vm.minGeoZoom = $$v
                    },
                    expression: "minGeoZoom"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "FcListItem",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isLoggedIn,
                  expression: "isLoggedIn"
                }
              ],
              staticClass: "border-t border-surface-accented"
            },
            [
              _c(
                "FcButton",
                {
                  staticClass:
                    "text-normal hover:text-accented hover:bg-primary hover:bg-opacity-20",
                  attrs: { href: _vm.$api.logoutURL(), icon: "sign-out-alt" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("login.logout")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }