var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: {
        "text-value-unknown": _vm.isUnknown,
        "text-value-bad": _vm.isBad,
        "text-value-good": _vm.isGood,
        "text-value-average": _vm.isAverage
      }
    },
    [_vm._v(" " + _vm._s(_vm.valueText) + _vm._s(_vm.layer.unit) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }