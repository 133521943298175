<template>
  <div id="app" class="flex flex-col md:flex-row">
    <SidebarContainer class="flex-1 md:flex-initial md:w-96 md:max-w-1/3 overflow-y-auto" />
    <MapView class="flex-1 z-0" />
    <FcModal :value="isModalShown" persistent eager>
      <div
        v-if="isLoginDialogShown"
        class="text-center space-y-6 bg-background p-4 max-w-lg m-auto rounded"
      >
        <FiksuCityLogo class="w-64 max-w-full mx-auto" :dark="$theme.dark" />
        <FcButton
          :href="$api.loginURL()"
          icon="sign-in-alt"
          class="inline-block px-3 py-2 text-normal bg-surface bg-opacity-60 hover:text-accented hover:bg-opacity-90"
        >
          {{ $t("login.loginButtonNetwork") }}
        </FcButton>
        <LanguageSelector />
      </div>
    </FcModal>
  </div>
</template>

<script>
import { mapState } from "vuex"

import FiksuCityLogo from "./components/logo/FiksuCityLogo.vue"
import MapView from "./components/MapView.vue"
import LanguageSelector from "./components/LanguageSelector.vue"
import SidebarContainer from "./components/sidebar/SidebarContainer.vue"

export default {
  name: "App",

  components: {
    MapView,
    SidebarContainer,
    LanguageSelector,
    FiksuCityLogo,
  },

  data: () => ({
    isLoginFinished: false,
    isLoginDialogShown: false,
  }),

  computed: {
    ...mapState("view", {
      currentView: "view",
    }),
    isLoggedIn() {
      return this.$store.state.user.hasToken
    },

    isModalShown() {
      return !this.isLoginFinished || this.isLoginDialogShown
    },
  },

  watch: {
    isLoggedIn() {
      this.isLoginDialogShown = !this.isLoggedIn
    },
  },

  async mounted() {
    await this.doLogin()
  },

  methods: {
    async doLogin() {
      this.isLoginFinished = false
      const loc = new URL(window.location)
      const hasCode = loc.searchParams.has("code")
      try {
        if (hasCode) {
          const token = await this.$api.authenticate(loc.searchParams)
          await this.$store.dispatch("user/setToken", token.userinfo)
        } else {
          const introspectToken = await this.$api.introspect()
          const token = introspectToken.active ? introspectToken : await this.$api.refresh()
          if (token.active) {
            await this.$store.dispatch("user/setToken", token)
          }
        }
      } catch (error) {
        // ignore
        console.warn("Login failed:", error)
      } finally {
        if (hasCode) {
          // remove the code param from the current url
          loc.searchParams.delete("code")
          loc.searchParams.delete("state")
          window.history.replaceState(null, "", loc)
        }
        this.isLoginDialogShown = !this.isLoggedIn
        this.isLoginFinished = true
      }
    },
  },
}
</script>

<style src="~/assets/themes.css"></style>
