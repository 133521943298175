export default {
  locales: {
    title: "Kieli",
    en: "Englanti",
    fi: "Suomi",
  },
  themes: {
    title: "Teema",
    current: "Laitteen teema",
    light: "Vaalea",
    dark: "Tumma",
  },
  actions: {
    cancel: "Peruuta",
    save: "Tallenna",
    delete: "Poista",
    reset: "Palauta",
  },
  login: {
    pleaseLogin: "Fiksu Cityn käyttö vaatii sisäänkirjautumisen.",
    loginButtonNetwork: "Kirjaudu sisään Fiksu Networkin kautta",
    logout: "Kirjaudu ulos",
  },
  siteStatus: {
    title: "Kohteiden tila",
    site: "kohde | kohdetta",
    visibleAndActive: "{visible} {sitesVisible} näkyvissä, {active} aktiivisena tällä datatasolla",
  },
  dataLayers: {
    title: "Datatasot",
    form: {
      add: "Lisää uusi datataso",
      edit: "Muokkaa datatasoa",
      info: "Tason tiedot",
      active: "Aktiivinen",
      name: "Tason nimi",
      dataSources: "Datalähteet",
      dataModule: "Datamoduuli",
      addSource: "Lisää uusi datalähde",
      dataSource: "Datalähde",
      device: "Laite",
      position: "Asema",
      addPoint: "Lisää piste",
      calculation: "Laskutoimitus",
      mean: "Keskiarvo",
      sum: "Summa",
      difference: "Erotus",
      thresholds: "Raja-arvot",
      addThreshold: "Lisää uusi raja-arvo",
      type: "Laatu",
      good: "Hyvä",
      bad: "Huono",
      comparison: "Vertailu",
      value: "Arvo",
    },
    reset: "Palauta oletustasot",
    resetConfirm:
      "Oletko varma, että haluat palauttaa datatasot oletusarvoiksi? Tätä toimintoa ei voi peruuttaa jälkikäteen.",
  },
  geoLayers: {
    title: "Geodatatasot",
    manage: "Hallitse geodatatasoja",
    minZoom: "Matalin geodatan zoomaustaso",
  },
  favorites: {
    title: "Suosikit",
    guide: "Avaa kohde kartalla lisätäksesi se suosikkeihisi.",
  },
  sitePopup: {
    sitesHidden: "{n} kohde piilossa | {n} kohdetta piilossa",
    showAlarms: "Näytä {n} hälytys | Näytä {n} hälytystä",
    hideAlarms: "Piilota {n} hälytys | Piilota {n} hälytystä",
    clientId: "Asiakastunniste",
  },
  siteSearch: {
    label: "Hae kohdetta",
  },
  alarms: {
    title: "Aktiiviset hälytykset",
    noAlarms: "Ei aktiivisia hälytyksiä.",
  },
  releaseNotes: {
    title: "Julkaisutiedot",
    onlyEnglish: "Julkaisutiedot ovat saatavilla vain englanniksi.",
  },
}
