<template>
  <label>
    <input
      type="radio"
      ref="radio"
      v-bind="$attrs"
      v-model="inputValue"
      :value="radioValue"
      class="input-radio hidden"
    />
    <v-icon name="regular/circle" class="input-radio__circle" />
    <v-icon name="dot-circle" class="input-radio__checked" />
    <slot />
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: undefined,
      default: undefined,
    },
    radioValue: {
      type: String,
      default: "",
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
  },
}
</script>

<style scoped>
.input-radio:checked ~ .input-radio__circle {
  display: none;
}
.input-radio:not(:checked) ~ .input-radio__checked {
  display: none;
}
</style>
