var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FcCard", {
    staticClass: "rounded",
    attrs: { name: "alarms", icon: "exclamation-triangle" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "flex space-x-2" },
              [
                _c("FcInputToggle", {
                  staticClass: "inline",
                  attrs: { value: _vm.isAlarmActive },
                  on: {
                    input: function(val) {
                      return _vm.toggleAlarmVisibility(val)
                    }
                  }
                }),
                _c("span", { staticClass: "flex-grow" }, [
                  _vm._v(_vm._s(_vm.$t("alarms.title")))
                ]),
                _c(
                  "span",
                  { staticClass: "flex items-center text-xs space-x-1" },
                  [
                    _c("v-icon", {
                      staticClass: "text-alert",
                      attrs: { name: "exclamation-triangle" },
                      on: {
                        click: function($event) {
                          return _vm.showFilter()
                        }
                      }
                    }),
                    _c("span", { staticClass: "text-sm" }, [
                      _vm._v(_vm._s(_vm.totalAlarms))
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "mt-2 space-y-2" }, [
              _c(
                "div",
                { staticClass: "flex space-x-3 items-center" },
                [
                  _c("FcInputText", {
                    staticClass: "w-full",
                    attrs: {
                      sm: "",
                      clearable: "",
                      placeholder: _vm.$t("alarms.siteName")
                    },
                    model: {
                      value: _vm.searchInputSite,
                      callback: function($$v) {
                        _vm.searchInputSite = $$v
                      },
                      expression: "searchInputSite"
                    }
                  }),
                  _c("v-icon", {
                    staticClass: "text-muted",
                    attrs: { name: "filter" },
                    model: {
                      value: _vm.setSearchInputSite,
                      callback: function($$v) {
                        _vm.setSearchInputSite = $$v
                      },
                      expression: "setSearchInputSite"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex space-x-3 items-center" },
                [
                  _c("FcInputText", {
                    staticClass: "w-full",
                    attrs: {
                      sm: "",
                      clearable: "",
                      placeholder: _vm.$t("alarms.alarmName")
                    },
                    model: {
                      value: _vm.searchInputAlarm,
                      callback: function($$v) {
                        _vm.searchInputAlarm = $$v
                      },
                      expression: "searchInputAlarm"
                    }
                  }),
                  _c("v-icon", {
                    staticClass: "text-muted",
                    attrs: { name: "filter" },
                    model: {
                      value: _vm.setSearchInputAlarm,
                      callback: function($$v) {
                        _vm.setSearchInputAlarm = $$v
                      },
                      expression: "setSearchInputAlarm"
                    }
                  })
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }