<template>
  <span
    :class="{
      'text-value-unknown': isUnknown,
      'text-value-bad': isBad,
      'text-value-good': isGood,
      'text-value-average': isAverage,
    }"
  >
    {{ valueText }}{{ layer.unit }}
  </span>
</template>

<script>
import { DataLayer } from "~/models"

export default {
  props: {
    layer: {
      type: DataLayer,
      default: null,
    },
    value: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    valueText() {
      return !isNaN(this.value) ? this.value?.toFixed(1) : "—"
    },
    isUnknown() {
      return isNaN(this.value)
    },
    isBad() {
      return !this.isUnknown && this.layer.isValueBad(this.value)
    },
    isGood() {
      return !this.isUnknown && !this.isBad && this.layer.isValueGood(this.value)
    },
    isAverage() {
      return !this.isUnknown && !this.isBad && !this.isGood
    },
  },
}
</script>

<style></style>
