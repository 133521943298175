import L from "leaflet"
import mapIcons from "~/mapIcons"
import Supercluster from "supercluster"

export class SiteMarkerManager {
  constructor(mapService) {
    this.mapService = mapService
    this.ready = false
    this._siteCoords = {}
    this.index = new Supercluster({
      radius: 40,
      maxZoom: 18,
    })
    this.markers = L.geoJSON(null, {
      pointToLayer: (...args) => this.createIcon(...args),
    })
    this.alarmMarkers = L.featureGroup()
  }
  getSiteIds(feature) {
    const item = feature.properties
    return item.cluster
      ? this.index.getLeaves(item.cluster_id, Infinity).map(f => f.properties.id)
      : [item.id]
  }

  getCoordsForSiteId(siteId) {
    return this._siteCoords[siteId]
  }

  createIcon(feature, latlng) {
    const siteIds = this.getSiteIds(feature)
    const value = this.mapService.currentDataLayer.getMultipleSitesValue(siteIds)
    if (!feature.properties.cluster) {
      const icon = L.icon(mapIcons.pin[value] ?? mapIcons.pin.unknown)
      return L.marker(latlng, { icon })
    } else {
      const count = feature.properties.point_count
      const html = `<div class="site-cluster site-cluster-${value}">${count}</div>`
      const icon = L.divIcon({
        html,
        className: "",
        iconSize: L.point(40, 40),
      })
      return L.marker(latlng, { icon })
    }
  }
  clear() {
    this.markers.off()
    this.markers.clearLayers()
    this.markers.remove()
    this.alarmMarkers.off()
    this.alarmMarkers.clearLayers()
    this.alarmMarkers.remove()
  }
  toggleLayer(map, layer, on = undefined) {
    if (this.ready) {
      if (on === undefined ? !map.hasLayer(layer) : on) {
        map.addLayer(layer)
      } else {
        map.removeLayer(layer)
      }
    }
  }
  toggleSiteLayer(map, on = undefined) {
    this.toggleLayer(map, this.markers, on)
  }

  toggleAlarmLayer(map, on = undefined) {
    this.toggleLayer(map, this.alarmMarkers, on)
  }
  load(map, sites) {
    if (this.ready) {
      this.ready = false
    }
    const features = sites.map(site => site.geoJSON)
    this.index.load(features)
    this.markers.addTo(map)
    this.ready = true
  }

  updateMarkers(bounds, zoom) {
    if (this.ready) {
      // const bbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()]
      const bbox = [-Infinity, -Infinity, Infinity, Infinity]
      const features = this.index.getClusters(bbox, zoom)
      this.markers.clearLayers()
      this.markers.addData(features)
      this.updateAlarmMarkers(features)
    }
  }

  createAlarmMarker(count, isCluster) {
    const html = `<div class="text-black text-center font-bold border-alert w-0 h-0 relative" style="border-left:15px solid transparent;border-right:15px solid transparent;border-bottom-width:26px;"><span class="absolute" style="width:26px; left:-13px; top:7.5px;">${count}</span></div>`
    return L.divIcon({
      html,
      className: "alarmAlert",
      iconSize: L.point(40, 40),
      iconAnchor: [0, isCluster ? 38 : 55],
    })
  }

  updateAlarmMarkers(features) {
    if (this.ready) {
      this.alarmMarkers.clearLayers()
      const alarms = this.mapService.alarmsBySite
      for (const feature of features) {
        const siteIds = this.getSiteIds(feature)
        const alarmsCount = siteIds.reduce((acc, cur) => acc + (alarms[cur] ?? []).length, 0)
        if (alarmsCount) {
          const icon = this.createAlarmMarker(alarmsCount, feature.properties.cluster)
          const coords = feature.geometry.coordinates.slice().reverse()
          L.marker(coords, { icon, interactive: false }).addTo(this.alarmMarkers)
        }
      }
    }
  }
}
