import { BaseModel, Field } from "~/lib/Model"

export class PointValue extends BaseModel {
  get fields() {
    return {
      value: Field.Number(),
      reliability: Field.Number(),
      priority: Field.Number(),
      readTimestamp: Field.ISODate(),
      isOnline: Field.Boolean(),
    }
  }

  get hasData() {
    return this.isOnline && (this.value || !isNaN(this.readTimestamp.getTime()))
  }
}

export class Point extends BaseModel {
  get fields() {
    return {
      id: Field.String(),
      module: Field.String(),
      siteId: Field.String(),
      device: Field.String(),
      position: Field.String(),
      name: Field.String(),
      unit: Field.String(),
      pointValue: Field.Nested(PointValue, { defaultValue: null }),
    }
  }

  get isMatched() {
    return this.id && this.device && this.position
  }

  get hasData() {
    return this.isMatched && this.pointValue?.hasData
  }

  get path() {
    return `${this.module}/${this.siteId}/${this.device}/${this.position}`
  }
}
