<template>
  <div class="divide-y-2 divide-width divide-surface-accented">
    <div class="popup-content-container overflow-y-auto pr-2">
      <SitePopupItem v-for="site of shownSites" :key="site.id" :site="site" class="py-2" />
    </div>
    <div v-if="shownSites.length < sites.length" class="pt-3 text-sm text-muted text-right">
      ({{ $tc("sitePopup.sitesHidden", sites.length - shownSites.length) }})
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import SitePopupItem from "./SitePopupItem.vue"

export default {
  components: { SitePopupItem },

  data: () => ({
    siteIds: () => [],
  }),

  computed: {
    ...mapGetters({
      getSite: "map/getSite",
      currentDataLayer: "layers/currentDataLayer",
    }),
    sites() {
      return this.siteIds.map(id => this.getSite(id))
    },
    sortedSites() {
      return this.sites
        .slice()
        .sort((a, b) => (a.name > b.name ? -1 : 1))
        .sort((a, b) => (this.siteIsFavorite(a) && !this.siteIsFavorite(b) ? -1 : 1))
    },
    sitesWithValue() {
      return this.sortedSites.filter(s => this.siteHasValue(s))
    },
    sitesWithoutValue() {
      return this.sortedSites.filter(s => !this.siteHasValue(s))
    },
    shownSites() {
      const count = this.sitesWithValue.length
      return [...this.sitesWithValue, ...this.sitesWithoutValue.slice(0, Math.max(0, 10 - count))]
      // return this.sortedSites.slice(0, 10)
    },
  },

  methods: {
    siteHasValue(site) {
      return this.currentDataLayer?.hasSiteDiscoveredPoints(site)
    },
    siteIsFavorite(site) {
      return this.$store.state.preferences.favoriteSites.includes(site.id)
    },
  },
}
</script>

<style scoped>
.popup-content-container {
  max-height: 50vh;
}
</style>
