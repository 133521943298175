<template>
  <FcCard class="rounded" name="alarms" icon="exclamation-triangle">
    <template #header>
      <div class="flex space-x-2">
        <FcInputToggle
          class="inline"
          :value="isAlarmActive"
          @input="val => toggleAlarmVisibility(val)"
        >
        </FcInputToggle>
        <span class="flex-grow">{{ $t("alarms.title") }}</span>
        <span class="flex items-center text-xs space-x-1">
          <v-icon name="exclamation-triangle" class="text-alert" @click="showFilter()" />
          <span class="text-sm">{{ totalAlarms }}</span>
        </span>
      </div>
      <div class="mt-2 space-y-2">
        <div class="flex space-x-3 items-center">
          <FcInputText
            v-model="searchInputSite"
            sm
            clearable
            class="w-full"
            :placeholder="$t('alarms.siteName')"
          />
          <v-icon name="filter" class="text-muted" v-model="setSearchInputSite" />
        </div>
        <div class="flex space-x-3 items-center">
          <FcInputText
            v-model="searchInputAlarm"
            sm
            clearable
            class="w-full"
            :placeholder="$t('alarms.alarmName')"
          />
          <v-icon name="filter" class="text-muted" v-model="setSearchInputAlarm" />
        </div>
      </div>
    </template>
  </FcCard>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      searchInputSite: "",
      searchInputAlarm: "",
      active: null,
    }
  },
  computed: {
    ...mapGetters({
      getAlarmActive: "alarm/getAlarmActive",
    }),
    isAlarmActive() {
      return this.active == null ? this.getAlarmActive : this.active
    },
    totalAlarms() {
      return this.$store.state.alarm?.alarms?.length ?? 0
    },
    setSearchInputSite() {
      return this.$store.dispatch("alarm/setSearchInputSite", this.searchInputSite)
    },
    setSearchInputAlarm() {
      return this.$store.dispatch("alarm/setSearchInputAlarm", this.searchInputAlarm)
    },
  },

  mounted() {
    this.updateAlarmLayer()
  },
  watch: {
    active() {
      this.updateAlarmLayer()
    },
  },
  methods: {
    showFilter() {},
    updateAlarmLayer() {
      this.$map.toggleAlarmLayer(this.isAlarmActive)
    },
    toggleAlarmVisibility(val) {
      this.active = val === null ? !this.active : !!val
      this.updateAlarmLayer()
      this.$store.dispatch("alarm/setAlarmActive", val)
    },
  },
}
</script>
