class Interceptor {
  constructor() {
    this.stack = []
  }

  use(listener) {
    this.stack.push(listener)
  }

  eject(listener) {
    const i = this.stack.findIndex(f => f === listener)
    if (i >= 0) {
      this.stack.splice(i, 1)
    }
  }
}

export default Interceptor
