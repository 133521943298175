var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex items-center min-w-0 space-x-1",
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "flex-1 truncate text-sm",
          class: {
            "text-muted": _vm.layer.active,
            "text-muted2": !_vm.layer.active
          }
        },
        [_vm._v(" " + _vm._s(_vm.layer.name) + " ")]
      ),
      _c("DataLayerValue", {
        staticClass: "flex-0 text-xl font-bold",
        attrs: { layer: _vm.layer, value: _vm.value }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }