function strToBool(val) {
  return !!val && val !== "0" && val !== "false"
}

const Config = {
  version: process.env.GIT_VERSION,
  apiBaseUrl: process.env.VUE_APP_API_BASE_URL,
  fiksuWebUrl: process.env.VUE_APP_FIKSUWEB_URL || "https://fiksuweb.com",
  fiksuNetworkUrl: process.env.VUE_APP_FIKSUNETWORK_URL || "https://fiksunetwork.com",
  siteLimit: Number(process.env.VUE_APP_SITE_LIMIT) || Infinity,
  mapData: {
    defaultCoordinates: [62.4935, 27.79],
    siteZoom: Number(process.env.VUE_APP_GOTO_SITE_ZOOM) || 18,
    defaultZoom: 14,
    streetLayer: {
      urlTemplate: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      options: {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        noWrap: true,
      },
    },
  },
  mapUpdateDelay: {
    base: 5000, // the base delay between each update in ms
    max: 60000, // the maximum delay in ms
    factor: 1.2, // the factor by which the delay increases each update while idle (use 1.0 to disable)
  },
  alarmUpdateDelay: {
    base: 60000,
    max: Infinity,
    factor: 2,
  },
  isFeatureEnabled: {
    siteStatus: strToBool(process.env.VUE_APP_FEATURE_SITESTATUS),
    dataLayers: strToBool(process.env.VUE_APP_FEATURE_DATALAYERS),
    favorites: strToBool(process.env.VUE_APP_FEATURE_FAVORITES),
    alarms: strToBool(process.env.VUE_APP_FEATURE_ALARMS),
    geoLayers: strToBool(process.env.VUE_APP_FEATURE_GEOLAYERS),
  },
}

Config.Mixin = {
  created() {
    this.Config = Config
  },
}

export default Config
