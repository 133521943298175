var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex" }, [
    _c("div", { staticClass: "flex-grow px-2 space-y-1" }, [
      _c(
        "div",
        { staticClass: "flex text-lg leading-tight items-start" },
        [
          _c(
            "v-icon",
            {
              staticClass: "mr-2 mt-0.5 cursor-pointer",
              attrs: { label: "Favorite" },
              on: { click: _vm.toggleFavorite }
            },
            [
              _c("v-icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isFavorite,
                    expression: "isFavorite"
                  }
                ],
                staticClass: "text-favorite-fill",
                attrs: { name: "star" }
              }),
              _c("v-icon", {
                class: [
                  _vm.isFavorite ? "text-favorite-outline" : "text-muted2"
                ],
                attrs: { name: "regular/star" }
              })
            ],
            1
          ),
          _c("span", [_vm._v(_vm._s(_vm.site.name))])
        ],
        1
      ),
      _c("div", { staticClass: "flex divide-x divide-muted2" }, [
        _c(
          "a",
          {
            staticClass: "pr-1",
            attrs: { href: _vm.siteURL(_vm.site), target: "_blank" }
          },
          [_vm._v(" #" + _vm._s(_vm.site.id) + " ")]
        ),
        _c("span", { staticClass: "px-1" }, [_vm._v(_vm._s(_vm.site.address))])
      ]),
      _vm.site.clientId
        ? _c("div", [
            _c("span", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.$t("sitePopup.clientId")) + ":")
            ]),
            _vm._v(" " + _vm._s(_vm.site.clientId) + " ")
          ])
        : _vm._e(),
      _vm.site.urlEnergyScreen || _vm.site.urlFiksuWeb
        ? _c("div", { staticClass: "divide-x divide-muted2" }, [
            _vm.site.urlEnergyScreen
              ? _c(
                  "a",
                  {
                    staticClass: "px-1 first:pl-0 last:pr-0",
                    attrs: { href: _vm.site.urlEnergyScreen }
                  },
                  [_vm._v(" Energy Screen ")]
                )
              : _vm._e(),
            _vm.site.urlFiksuWeb
              ? _c(
                  "a",
                  {
                    staticClass: "px-1 first:pl-0 last:pr-0",
                    attrs: { href: _vm.site.urlFiksuWeb }
                  },
                  [_vm._v(" FiksuWeb ")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.isAlarmActive
        ? _c("div", [
            _vm.alarms.length
              ? _c(
                  "div",
                  [
                    _c(
                      "FcButton",
                      {
                        staticClass:
                          "hover:bg-alert hover:bg-opacity-20 bipika",
                        attrs: {
                          xs: "",
                          icon: "exclamation-triangle",
                          "icon-class": "text-alert"
                        },
                        on: {
                          click: function($event) {
                            _vm.showAlarms = !_vm.showAlarms
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc(
                                !_vm.showAlarms
                                  ? "sitePopup.showAlarms"
                                  : "sitePopup.hideAlarms",
                                _vm.alarms.length
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _vm.showAlarms
                      ? _c(
                          "div",
                          { staticClass: "text-muted mt-1 ml-3" },
                          _vm._l(_vm.alarms.entries(), function(ref) {
                            var index = ref[0]
                            var alarm = ref[1]
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass:
                                  "border-alert border-opacity-30 border-l pl-2"
                              },
                              [
                                _c("v-icon", {
                                  staticClass: "text-alert",
                                  attrs: { name: "exclamation-triangle" }
                                }),
                                _c("span", { staticClass: "ml-1 font-bold" }, [
                                  _vm._v(_vm._s(alarm.device))
                                ]),
                                _c("div", { staticClass: "ml-5" }, [
                                  _vm._v(_vm._s(alarm.message))
                                ])
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "flex-shrink flex justify-center items-start" }, [
      _c(
        "div",
        {
          staticClass: "text-3xl font-bold",
          class: {
            "text-value-unknown": _vm.isUnknown,
            "text-value-bad": _vm.isBad,
            "text-value-good": _vm.isGood,
            "text-value-average": _vm.isAverage
          }
        },
        [_c("div", [_vm._v(_vm._s(_vm.value) + _vm._s(_vm.unit))])]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }