<template>
  <div class="flex">
    <FcInputText v-model="device" class="flex-1" :label="$t('dataLayers.form.device')" />
    <FcInputText v-model="position" class="flex-1" :label="$t('dataLayers.form.position')" />
  </div>
</template>

<script>
import { DataSourcePoint } from "~/models"

export default {
  props: {
    value: {
      type: DataSourcePoint,
      default: null,
    },
  },

  computed: {
    device: {
      get() {
        return this.value?.device ?? ""
      },
      set(val) {
        this.$emit("input", DataSourcePoint.load({ device: val, position: this.position }))
      },
    },
    position: {
      get() {
        return this.value?.position ?? ""
      },
      set(val) {
        this.$emit("input", DataSourcePoint.load({ device: this.device, position: val }))
      },
    },
  },
}
</script>

<style></style>
