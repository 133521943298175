var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex space-x-2" },
    [
      _c("FcInputSelect", {
        staticClass: "flex-1",
        attrs: { items: _vm.typeItems, label: _vm.$t("dataLayers.form.type") },
        scopedSlots: _vm._u([
          {
            key: "entry",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "span",
                  {
                    class: {
                      "text-value-good": item.value === _vm.typeItems[0].value,
                      "text-value-bad": item.value === _vm.typeItems[1].value
                    }
                  },
                  [_vm._v(" " + _vm._s(item.text) + " ")]
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.inputType,
          callback: function($$v) {
            _vm.inputType = $$v
          },
          expression: "inputType"
        }
      }),
      _c("FcInputSelect", {
        staticClass: "flex-1",
        attrs: {
          items: _vm.comparisonItems,
          label: _vm.$t("dataLayers.form.comparison")
        },
        model: {
          value: _vm.inputComp,
          callback: function($$v) {
            _vm.inputComp = $$v
          },
          expression: "inputComp"
        }
      }),
      _c("FcInputText", {
        staticClass: "flex-1",
        attrs: {
          label: _vm.$t("dataLayers.form.value"),
          type: "number",
          step: "0.1"
        },
        model: {
          value: _vm.inputValue,
          callback: function($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }