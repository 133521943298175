<template>
  <component
    :is="buttonType"
    v-bind="optional"
    type="button"
    class="fc-button rounded transition font-bold uppercase items-center space-x-2"
    :class="{
      'text-base': base,
      'text-xs': xs,
      'text-sm': sm,
      'text-lg': lg,
      'text-xl': xl,
      'text-3xl': xl3,
    }"
    @click="e => $emit('click', e)"
  >
    <span v-if="icon" class="fc-button-icon-wrapper text-primary">
      <v-icon :name="icon" :class="iconClass" />
    </span>
    <span
      v-if="$slots.default"
      class="fc-button-text-wrapper"
      :class="(icon ? 'ml-2 ' : '') + textClass"
    >
      <slot />
    </span>
  </component>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    iconClass: {
      type: String,
      default: "",
    },
    textClass: {
      type: String,
      default: "",
    },
    xs: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    lg: {
      type: Boolean,
      default: false,
    },
    xl: {
      type: Boolean,
      default: false,
    },
    xl3: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    buttonType() {
      return this.href ? "a" : "button"
    },
    optional() {
      const opts = {}
      if (this.href) {
        opts.href = this.href
      }
      return opts
    },
    base() {
      return !this.xs && !this.sm && !this.lg && !this.xl && !this.xl3
    },
  },
}
</script>

<style lang="postcss">
.fc-button {
  padding: theme("spacing.2");
}
</style>
