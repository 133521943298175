import { BaseModel, Field } from "~/lib/Model"
import { DataSource } from "./DataSource"
import { Threshold } from "./Threshold"

export class DataLayer extends BaseModel {
  static CALC_MEAN = "mean"
  static CALC_SUM = "sum"
  static CALC_DIFFERENCE = "diff"

  static VALUE_NODATA = "nodata"
  static VALUE_UNKNOWN = "unknown"
  static VALUE_AVERAGE = "average"
  static VALUE_GOOD = "good"
  static VALUE_BAD = "bad"

  static VALUE_ORDER_WORST = [
    DataLayer.VALUE_BAD,
    DataLayer.VALUE_AVERAGE,
    DataLayer.VALUE_GOOD,
    DataLayer.VALUE_NODATA,
  ]
  static VALUE_ORDER_BEST = [
    DataLayer.VALUE_GOOD,
    DataLayer.VALUE_AVERAGE,
    DataLayer.VALUE_BAD,
    DataLayer.VALUE_NODATA,
  ]
  static VALUE_ORDER_MODE = "mode"

  static generateId() {
    return Math.random()
      .toString(36)
      .substr(2)
  }

  get fields() {
    return {
      id: Field.String(),
      name: Field.String(),
      icon: Field.String(),
      module: Field.String({ defaultValue: "fiksu" }),
      dataSources: Field.List(Field.Nested(DataSource), { defaultValue: [] }),
      active: Field.Boolean({ defaultValue: false }),
      calculation: Field.String({ defaultValue: DataLayer.CALC_MEAN }),
      thresholds: Field.List(Field.Nested(Threshold), { defaultValue: [] }),
    }
  }

  constructor(...args) {
    super(...args)
    if (!this.id) {
      this.id = DataLayer.generateId()
    }
    this._ver = 1
    for (const source of this.dataSources) {
      source.module = this.module
    }
    this.discovered = new Map()
    this.points = new Map()
  }

  resetData() {
    this.discovered = new Map()
    this.points = new Map()
  }

  isSiteDiscovered(site) {
    return this.discovered.has(site.id ?? site)
  }

  hasSiteDiscoveredPoints(site) {
    return this.discovered.get(site.id ?? site)?.filter(Boolean).length > 0 ?? false
  }

  _addDiscoveredPoint(point) {
    if (!this.isSiteDiscovered(point.siteId)) {
      this.discovered.set(point.siteId, [])
    }
    if (point.hasData) {
      this.discovered.get(point.siteId).push(point.path)
      this.points.set(point.path, point)
    } else {
      this.discovered.get(point.siteId).push(false)
    }
  }

  processDiscoveredPoints(points) {
    for (const point of points) {
      this._addDiscoveredPoint(point)
    }
    // if the site doesn't have all the required discovered points,
    // mark all points of the site as undiscovered
    for (const [siteId, points] of this.discovered.entries()) {
      if (points.filter(Boolean).length < this.dataSources.length) {
        this.discovered.set(siteId, [].fill(false, 0, this.dataSources.length))
      }
    }
  }

  updatePoint(point) {
    const hasData = point?.isMatched && point.pointValue?.isOnline
    if (!hasData) {
      const i = this.discovered.get(point.siteId).indexOf(point.path)
      this.discovered.get(point.siteId).splice(i, 1)
      this.points.delete(point.path)
    } else {
      this.points.set(point.path, point)
    }
  }

  getPointsForSite(site) {
    return (this.discovered.get(site.id ?? site) || [])
      .map(id => id && this.points.get(id))
      .filter(Boolean)
  }

  get unit() {
    return [...this.points.values()]?.[0]?.unit || ""
  }

  getValue(site) {
    switch (this.calculation) {
      case DataLayer.CALC_SUM:
        return this.reduceValueSum(site)
      case DataLayer.CALC_DIFFERENCE:
        return this.reduceValueDifference(site)
      case DataLayer.CALC_MEAN:
      default:
        return this.reduceValueMean(site)
    }
  }

  reduceValue(site, reducer) {
    const points = this.getPointsForSite(site)
    const value = reducer(points)
    // TODO: is this assumption ok? should non-matching units throw an error?
    const unit = points[0]?.unit ?? ""
    return { value, unit }
  }

  reduceValueSum(site) {
    return this.reduceValue(site, points => {
      return points.length > 0
        ? points.reduce((acc, cur) => acc + parseFloat(cur.pointValue.value), 0)
        : NaN
    })
  }

  reduceValueDifference(site) {
    return this.reduceValue(site, points => {
      if (points.length === 0) {
        return NaN
      }
      const initial = parseFloat(points[0].pointValue.value)
      return points.slice(1).reduce((acc, cur) => acc - parseFloat(cur.pointValue.value), initial)
    })
  }

  reduceValueMean(site) {
    return this.reduceValue(site, points => {
      return points.reduce((acc, cur) => acc + parseFloat(cur.pointValue.value), 0) / points.length
    })
  }

  isValueBad(value) {
    const bad = this.thresholds.filter(t => t.isBad)
    return bad.some(t => t.compare(value))
  }

  isValueGood(value) {
    const good = this.thresholds.filter(t => t.isGood)
    return good.length && good.every(t => t.compare(value))
  }

  isSiteValueBad(site) {
    return this.isValueBad(this.getValue(site).value)
  }

  isSiteValueGood(site) {
    return this.isValueGood(this.getValue(site).value)
  }

  isSiteValueNaN(site) {
    return isNaN(this.getValue(site).value)
  }

  getSiteValue(site) {
    if (!this.isSiteDiscovered(site)) {
      return DataLayer.VALUE_UNKNOWN
    } else if (!this.hasSiteDiscoveredPoints(site)) {
      return DataLayer.VALUE_NODATA
    } else if (this.isSiteValueNaN(site)) {
      return DataLayer.VALUE_UNKNOWN
    } else if (this.isSiteValueBad(site)) {
      return DataLayer.VALUE_BAD
    } else if (this.isSiteValueGood(site)) {
      return DataLayer.VALUE_GOOD
    } else {
      return DataLayer.VALUE_AVERAGE
    }
  }

  getMultipleSitesValue(sites, order = DataLayer.VALUE_ORDER_WORST) {
    const values = sites.map(site => this.getSiteValue(site))
    if (order !== DataLayer.VALUE_ORDER_MODE) {
      for (const value of order) {
        if (values.includes(value)) {
          return value
        }
      }
    } else {
      const counts = {}
      let max = 0
      const filtered = values.filter(v => DataLayer.VALUE_ORDER_WORST.includes(v))
      for (const v of filtered) {
        counts[v] = (counts[v] ?? 0) + 1
        if (counts[v] > max) {
          max = counts[v]
        }
      }
      for (const v of DataLayer.VALUE_ORDER_WORST) {
        if (counts[v] === max) {
          return v
        }
      }
    }
    return DataLayer.VALUE_UNKNOWN
  }
}
