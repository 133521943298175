<template>
  <div ref="container" class="fc-menu relative">
    <slot name="activator" :on="on" :active="active" />
    <div ref="content" class="fc-menu--content fixed z-50" v-show="active">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: null,
    },
  },

  data: () => ({
    active: false,
  }),

  updated() {
    // if (this.$refs.content) {
    //   this.$root.$el.appendChild(this.$refs.content)
    // }
  },

  beforeDestroy() {
    // if (this.$refs.content?.parentElement === this.$root.$el) {
    //   this.$root.$el.removeChild(this.$refs.content)
    // }
    this.hideContent()
    this.removeListeners()
  },

  computed: {
    on: function() {
      const vm = this
      return Object.assign({}, this.$listeners, {
        click: function(evt) {
          evt.preventDefault()
          evt.stopPropagation()
          vm.active = !vm.active
        },
      })
    },
  },

  watch: {
    active() {
      if (this.active) {
        this.addListeners()
        this.$nextTick(() => {
          this.showContent()
        })
      } else {
        this.hideContent()
        this.removeListeners()
      }
    },
  },

  methods: {
    addListeners() {
      window.addEventListener("click", this.onOutsideClick)
      window.addEventListener("scroll", this.close)
      window.addEventListener("resize", this.close)
    },
    removeListeners() {
      window.removeEventListener("click", this.onOutsideClick)
      window.removeEventListener("scroll", this.close)
      window.removeEventListener("resize", this.close)
    },
    onOutsideClick(evt) {
      if (
        evt &&
        !this.$refs.container?.contains(evt.target) &&
        !this.$refs.content?.contains(evt.target)
      ) {
        evt.preventDefault()
        evt.stopPropagation()
        this.close()
      }
    },
    showContent() {
      if (this.$refs.content && this.$refs.container) {
        const clamp = (a, b, c) => Math.max(a, Math.min(b, c))
        const content = this.$refs.content.getBoundingClientRect()
        const container = this.$refs.container.getBoundingClientRect()

        const left = clamp(0, container.left, window.innerWidth - content.width)
        const top = clamp(0, container.bottom, window.innerHeight - content.height)
        const right = Math.max(0, window.innerWidth - (left + content.width))
        const bottom = Math.max(0, window.innerHeight - (top + content.height))

        this.$refs.content.style.left = left + "px"
        this.$refs.content.style.top = top + "px"
        this.$refs.content.style.right = right + "px"
        this.$refs.content.style.bottom = bottom + "px"

        if (this.width !== null) {
          this.$refs.content.style.width = isNaN(this.width) ? this.width : this.width + "px"
        }
      }
    },
    hideContent() {
      this.$refs.content.style.left = null
      this.$refs.content.style.top = null
      this.$refs.content.style.right = null
      this.$refs.content.style.bottom = null
      this.$refs.content.style.width = null
    },
    close() {
      this.toggleOpen(false)
    },
    toggleOpen(active = null) {
      this.active = active === null ? !this.active : !!active
    },
  },
}
</script>

<style lang="postcss">
.fc-menu {
  @apply inline relative;
}
.fc-menu__content {
  @apply absolute;
}
</style>
