<template>
  <div
    class="input-container relative"
    :class="{ populated }"
    ref="container"
    @click.stop="() => toggleOpen(true)"
  >
    <div>
      <input
        v-model="inputValue"
        :class="inputClass"
        class="w-full p-4 bg-input rounded focus:outline-none focus:ring-2 ring-input-accented transition"
      />
      <label class="absolute left-0 p-4 text-muted pointer-events-none transition-all">
        <slot name="label">
          {{ label }}
        </slot>
      </label>

      <FcButton
        sm
        icon="times"
        class="absolute top-3 right-2 rounded-full h-8 w-8 flex justify-center hover:bg-input-accented"
        icon-class="text-muted"
        :class="populated ? 'opacity-100' : 'opacity-0'"
        @click="() => removeSearchText()"
      />
    </div>
    <div
      v-show="active"
      ref="list"
      class="fixed bg-input w-min h-min overflow-y-auto overscroll-contain rounded ring-2 ring-input-accented"
    >
      <div
        v-for="[i, item] of filteredItems.entries()"
        :key="i"
        :value="i"
        ref="listItems"
        class="px-4 py-2 hover:bg-input-accented focus:bg-input-accented focus:outline-none w-full cursor-pointer"
        tabindex="-1"
        @click.stop="() => onSelect(item)"
      >
        <slot name="entry" :item="item">
          {{ item.text }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Object],
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    inputClass: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    active: false,
    focusedIndex: null,
    inputValue: "",
  }),
  watch: {
    active() {
      if (this.active) {
        this.addListeners()
        this.focusedIndex = this.filteredItems.findIndex(i => i.value === this.selected)
        this.hideList()
        this.$nextTick(() => this.showList())
      } else {
        this.removeListeners()
        this.hideList()
        this.focusedIndex = null
      }
    },
    focusedIndex() {
      if (
        this.focusedIndex !== null &&
        this.focusedIndex !== -1 &&
        this.filteredItems[this.focusedIndex]
      ) {
        this.selected = this.filteredItems[this.focusedIndex].value
      }
    },
    filteredItems() {
      this.hideList()
      this.$nextTick(() => this.showList())
    },
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      },
    },
    populated() {
      return this.inputValue !== ""
    },
    selectItems() {
      return this.items.map(i => {
        if (typeof i === "string") {
          i = { text: i, value: i }
        }
        return {
          text: "",
          value: "",
          disabled: false,
          ...i,
        }
      })
    },
    currentItem() {
      return this.selectItems.find(i => i.value === this.selected) || {}
    },
    filteredItems() {
      return this.selectItems.filter(i =>
        i.text.toLowerCase().includes(this.inputValue.toLowerCase())
      )
    },
  },
  created() {
    this.selected = this.value
  },
  beforeDestroy() {
    this.removeListeners()
  },
  methods: {
    addListeners() {
      window.addEventListener("click", this.onOutsideClick, true)
      window.addEventListener("scroll", this.close)
      window.addEventListener("resize", this.close)
      window.addEventListener("keydown", this.onKeyDown)
    },
    removeListeners() {
      window.removeEventListener("click", this.onOutsideClick, true)
      window.removeEventListener("scroll", this.close)
      window.removeEventListener("resize", this.close)
      window.removeEventListener("keydown", this.onKeyDown)
    },
    onOutsideClick(evt) {
      if (evt && !this.$refs.container.contains(evt.target)) {
        evt.stopPropagation()
        this.close()
      }
    },
    onKeyDown(evt) {
      const nextIndex = Math.min(this.filteredItems.length - 1, this.focusedIndex + 1)
      const prevIndex = Math.max(0, this.focusedIndex - 1)
      switch (evt.code) {
        case "ArrowDown":
          this.$refs.listItems[nextIndex]?.focus()
          this.focusedIndex = nextIndex
          break
        case "ArrowUp":
          this.$refs.listItems[prevIndex]?.focus()
          this.focusedIndex = prevIndex
          break
        case "Enter":
          // this.close()
          break
      }
    },
    showList() {
      const cRect = this.$refs.container.getBoundingClientRect()
      this.$refs.list.style.left = cRect.left + "px"
      this.$refs.list.style.width = cRect.width + "px"
      // get the list's bounding rect *after* its left position and width is defined
      const lRect = this.$refs.list.getBoundingClientRect()
      this.$refs.list.style.top = cRect.bottom + "px"
      // Math.max(0, Math.min(window.innerHeight - lRect.height, cRect.bottom)) + "px"
      this.$refs.list.style.bottom =
        Math.max(window.innerHeight - cRect.bottom - lRect.height, 0) + "px"
    },
    hideList() {
      this.$refs.list.style.left = null
      this.$refs.list.style.width = null
      this.$refs.list.style.top = null
      this.$refs.list.style.bottom = null
    },
    onSelect(item) {
      this.selected = item.value
      // this.inputValue = item.text
      document.activeElement?.blur()
      this.close()
    },
    close() {
      this.active = false
    },
    toggleOpen(active = null) {
      this.active = active === null ? !this.active : !!active
    },
    removeSearchText() {
      this.inputValue = ""
      this.active = null
      this.close()
    },
  },
}
</script>

<style lang="postcss" scoped>
.input-container.populated label,
.input-container:focus-within label {
  padding-top: theme("spacing.1");
  font-size: theme("fontSize.xs");
}
.input-container.populated input,
.input-container:focus-within input {
  padding-top: theme("spacing.6");
  padding-bottom: theme("spacing.2");
}
</style>
