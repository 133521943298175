var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fc-input relative flex",
      class: {
        populated: _vm.populated,
        "fc-input__labelled": _vm.label,
        "fc-input__sm": _vm.sm
      }
    },
    [
      _c(
        "input",
        _vm._b(
          {
            staticClass:
              "w-full bg-input rounded focus:outline-none focus:ring-2 ring-input-accented transition",
            class: _vm.classProps,
            attrs: { type: _vm.type },
            domProps: { value: _vm.value },
            on: { input: _vm.onInput }
          },
          "input",
          Object.assign({}, _vm.$attrs, _vm.$props),
          false
        )
      ),
      _c(
        "label",
        {
          staticClass:
            "absolute left-2 text-muted pointer-events-none transition-all",
          class: _vm.classProps
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      ),
      _vm.clearable
        ? _c(
            "div",
            {
              staticClass:
                "fc-input__clear absolute right-0 w-8 text-muted h-full flex items-center justify-center cursor-pointer transition duration-100",
              class: {
                "text-opacity-0 pointer-events-none": !_vm.populated,
                "text-opacity-50 hover:text-opacity-100": _vm.populated
              },
              on: { click: _vm.clearInput }
            },
            [_c("v-icon", { attrs: { name: "times" } })],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }