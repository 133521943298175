export function objToFormData(data) {
  return Object.entries(data).reduce((fd, [k, v]) => {
    fd.append(k, v)
    return fd
  }, new FormData())
}

export function objToURLEncoded(data) {
  const encode = window.encodeURIComponent
  return Object.entries(data)
    .map(([k, v]) => (v === undefined ? encode(k) : encode(k) + "=" + encode(v)))
    .join("&")
}
