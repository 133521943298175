var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FcMenu",
    {
      ref: "menu",
      attrs: { width: _vm.activatorWidth },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              var active = ref.active
              return [
                _c(
                  "div",
                  _vm._g(
                    {
                      ref: "activator",
                      staticClass: "fc-input-select relative cursor-pointer",
                      class: {
                        active: active,
                        "fc-input-select__labelled": _vm.label
                      }
                    },
                    on
                  ),
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex w-full items-center px-4 bg-input rounded focus:outline-none ring-input-accented transition",
                        class: { "ring-2": active }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "fc-input-select--selector flex-1" },
                          [
                            _vm._t(
                              "entry",
                              function() {
                                return [
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.currentItem.text))
                                  ])
                                ]
                              },
                              { item: _vm.currentItem }
                            ),
                            _c(
                              "label",
                              {
                                staticClass:
                                  "absolute top-0 left-0 pt-1 px-4 text-xs text-muted pointer-events-none transition-all"
                              },
                              [_vm._v(" " + _vm._s(_vm.label) + " ")]
                            )
                          ],
                          2
                        ),
                        _c("v-icon", {
                          staticClass:
                            "chevron flex-shrink-1 top-0 pl-2 right-4 text-xl text-muted transform transition",
                          class: { "-scale-y-1": active },
                          attrs: { name: "caret-down" }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]
            }
          }
        ],
        null,
        true
      )
    },
    [
      _c(
        "FcList",
        {
          ref: "list",
          staticClass:
            "bg-input overflow-y-auto rounded ring-2 ring-input-accented",
          attrs: {
            value: _vm.selected,
            flat: "",
            mandatory: "",
            "active-class": "bg-input-accented"
          },
          on: { change: _vm.onSelect }
        },
        _vm._l(_vm.selectItems.entries(), function(ref) {
          var i = ref[0]
          var item = ref[1]
          return _c(
            "FcListItem",
            {
              key: i,
              ref: "listItems",
              refInFor: true,
              staticClass:
                "px-4 py-2 bg-input hover:bg-input-accented focus:bg-input-accented focus:outline-none w-full transition-colors duration-75",
              attrs: { value: item.value, tabindex: "-1" }
            },
            [
              _vm._t(
                "entry",
                function() {
                  return [_c("div", [_vm._v(_vm._s(item.text))])]
                },
                { item: item }
              )
            ],
            2
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }