var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "divide-y-2 divide-width divide-surface-accented" },
    [
      _c(
        "div",
        { staticClass: "popup-content-container overflow-y-auto pr-2" },
        _vm._l(_vm.shownSites, function(site) {
          return _c("SitePopupItem", {
            key: site.id,
            staticClass: "py-2",
            attrs: { site: site }
          })
        }),
        1
      ),
      _vm.shownSites.length < _vm.sites.length
        ? _c("div", { staticClass: "pt-3 text-sm text-muted text-right" }, [
            _vm._v(
              " (" +
                _vm._s(
                  _vm.$tc(
                    "sitePopup.sitesHidden",
                    _vm.sites.length - _vm.shownSites.length
                  )
                ) +
                ") "
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }