export const Field = (
  type,
  { defaultValue = null, dataKey = null, exclude = [], only = [] } = {}
) => {
  return {
    type,
    defaultValue,
    dataKey,
    exclude,
    only,
  }
}

export class BaseField {
  static deserialize(data) {
    return data
  }
  static serialize(data) {
    return data
  }
}
class NumberField extends BaseField {
  static deserialize(data) {
    return Number(data)
  }
}
class StringField extends BaseField {
  static deserialize(data) {
    return String(data)
  }
}
class BooleanField extends BaseField {
  static deserialize(data) {
    return Boolean(data)
  }
}
class ArrayField extends BaseField {
  static deserialize(data) {
    if (!Array.isArray(data)) {
      throw new TypeError("Expected an Array.")
    }
    return data
  }
}
class ObjectField extends BaseField {
  static deserialize(data) {
    if (typeof data !== "object") {
      throw new TypeError("Expected an Object.")
    }
    return data
  }
}
class ISODateField extends BaseField {
  static deserialize(data) {
    return new Date(data)
  }
  static serialize(data) {
    return data.toISOString()
  }
}
class TimestampField extends BaseField {
  static SECONDS = "s"
  static MILLISECONDS = "ms"
  constructor({ precision = "" }) {
    super()
    if (precision !== TimestampField.SECONDS && precision !== TimestampField.MILLISECONDS) {
      throw TypeError('Timestamp precision required, must be "s" or "ms"')
    }
    this.precision = precision
  }
  deserialize(data) {
    if (this.precision === "s") {
      return new Date(Number(data) * 1000)
    } else if (this.precision === "ms") {
      return new Date(data)
    }
  }
  serialize(data) {
    if (this.precision === "s") {
      return Math.floor(Number(data) / 1000)
    } else if (this.precision === "ms") {
      return Number(data)
    }
  }
}
class NestedField extends BaseField {
  constructor(Obj, opts) {
    super()
    this.Obj = Obj
    this.opts = opts
  }
  deserialize(data) {
    if (data == null) {
      return null
    } else if (typeof this.Obj === "function") {
      return new this.Obj(data, this.opts)
    } else {
      return data
    }
  }
  serialize(data) {
    if (data == null) {
      return null
    } else if (typeof data.serialize === "function") {
      return data.serialize()
    } else if (this.Obj instanceof BaseField) {
      return this.Obj.serialize(data)
    } else {
      return data
    }
  }
}
class ListField extends BaseField {
  constructor(field) {
    super()
    this.field = field
  }
  deserialize(data) {
    return data.map(d => this.field.type.deserialize(d))
  }
  serialize(data) {
    return data.map(d => this.field.type.serialize(d))
  }
}

Field.Number = args => Field(NumberField, args)
Field.String = args => Field(StringField, args)
Field.Boolean = args => Field(BooleanField, args)
Field.Array = args => Field(ArrayField, args)
Field.Object = args => Field(ObjectField, args)

Field.Nested = (obj, args) => Field(new NestedField(obj, args), args)
Field.List = (field, args) => Field(new ListField(field, args), args)

Field.ISODate = args => Field(ISODateField, args)
Field.Timestamp = args => Field(new TimestampField(args), args)
Field.Timestamp.SECONDS = TimestampField.SECONDS
Field.Timestamp.MILLISECONDS = TimestampField.MILLISECONDS
