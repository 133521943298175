import { BaseModel, Field } from "~/lib/Model"

export class Location extends BaseModel {
  get fields() {
    return {
      longitude: Field.Number(),
      latitude: Field.Number(),
    }
  }
}
