<template>
  <div class="relative">
    <NavLayerMobile class="absolute bottom-16 h-18 w-full z-20 flex md:hidden" />
    <div class="absolute z-30 w-96 max-w-full p-5">
      <FcInputAutoComplete
        @input="site => site && $map.goToSite(site)"
        class="hidden md:block"
        :items="searchItems"
      >
        <template #label>
          <v-icon name="search" class="mr-1" /> {{ $t("siteSearch.label") }}
        </template>
        <template #entry="{ item }">
          <div>{{ item.value.name }}</div>
          <div class="text-xs">{{ item.value.address }}</div>
        </template>
      </FcInputAutoComplete>
    </div>
    <div class="z-20 fixed w-full">
      <DataLayerRow
        :layer="currentLayer"
        :mean="getMeanForVisibleSites(currentLayer)"
        class="
          md:hidden
          justify-between
          cursor-pointer
          rounded-full
          transition-all
          bg-surface
          w-3/4
          m-auto
          py-1.5
          px-5
          relative
          top-5
        "
        @click="showNextLayer()"
      />
    </div>
    <SiteSummary class="hidden md:block absolute top-7 right-7 h-18 w-18 z-30" />
    <FavoritesCard class="hidden md:block absolute bottom-16 left-4 h-18 w-18 z-20" />
    <SiteStatusCard class="hidden md:block absolute bottom-2 left-4 h-18 w-18 z-20" />
    <div ref="maproot" class="w-full h-full z-10" />
  </div>
</template>

<script>
import Vue from "vue"
import { mapState, mapGetters } from "vuex"

import config from "~/config"
import SitePopupContent from "~/components/sitepopup/SitePopupContent"
import NavLayerMobile from "~/components/mobileview/NavLayerMobile.vue"
import DataLayerRow from "~/components/sidebar/datalayers/DataLayerRow.vue"
import SiteSummary from "~/components/sitepopup/SiteSummary.vue"
import FavoritesCard from "~/components/favorites/FavoritesCard.vue"
import SiteStatusCard from "~/components/sitestatus/SiteStatusCard.vue"
const SitePopup = Vue.extend(SitePopupContent)

export default {
  components: {
    NavLayerMobile,
    DataLayerRow,
    SiteSummary,
    FavoritesCard,
    SiteStatusCard,
  },
  data: () => ({
    updating: false,
    updateTimeoutId: null,
    updateTimeoutDelay: config.mapUpdateDelay.base,
    searchSite: null,
  }),

  computed: {
    ...mapState({
      isLoggedIn: state => state.user.hasToken,
      lastUpdated: state => state.layers.lastUpdated,
      currentId: state => state.layers.currentId,
    }),
    ...mapGetters({
      sitesOnline: "map/sitesOnline",
      sitesVisible: "map/sitesVisible",
      siteAlarms: "alarm/alarmsBySite",
      currentLayer: "layers/currentDataLayer",
      dataLayerList: "layers/dataLayerList",
    }),
    searchItems() {
      return this.sitesOnline
        .map(i => ({
          text: `${i.name} ${i.address}`,
          value: i,
        }))
        .sort((a, b) => (a.text < b.text ? -1 : 1))
    },
  },

  watch: {
    async isLoggedIn() {
      if (this.isLoggedIn) {
        this.$map.getSites()
        this.$map.getGeodata()
        if (!this.$alarm.isStarted) {
          this.$alarm.start()
        }
      }
    },
    sitesOnline() {
      if (this.$map.ready) {
        this.$map.loadMarkers()
      }
    },
    async sitesVisible() {
      if (this.sitesVisible.length <= config.siteLimit) {
        await this.$map.discoverPoints()
        if (!this.updateTimeoutId) {
          this.startUpdateCycle()
        }
      } else {
        this.stopUpdateCycle()
      }
    },
    currentId() {
      // on current layer change
      this.$map.updateMarkers()
      if (this.sitesVisible.length <= config.siteLimit) {
        this.startUpdateCycle()
      }
    },
    siteAlarms() {
      this.$map.updateMarkers()
    },
  },

  mounted() {
    if ("visibilityState" in document) {
      window.addEventListener("visibilitychange", this.onWindowVisibilityChange)
    }
    try {
      this.$map.initialize(this.$refs.maproot, {
        popup: { className: "w-96 min-w-popup", maxWidth: "100%" },
      })
      this.$map.map.on("moveend", this.onMapMove)
      // Initialize popup
      const popupData = { siteIds: [] }
      const popupContent = new SitePopup({
        store: this.$store,
        i18n: this.$i18n,
        data: () => popupData,
      })
      popupContent.$mount(this.$map.popupNode)
      this.$map.popup.setContent(popupContent.$el)
      this.$map.markersLayer.on("click", e => {
        popupData.siteIds = this.$map.markerManager.getSiteIds(e.layer.feature).sort()
        this.$map.showSitePopup(e.latlng)
      })
    } catch (error) {
      console.error(error)
    }
  },

  beforeDestroy() {
    window.removeEventListener("visibilitychange", this.onWindowVisibilityChange)
    this.stopUpdateCycle()
    this.$alarm.stop()
    this.$map.tearDown()
  },

  methods: {
    onWindowVisibilityChange() {
      if (document.visibilityState === "hidden") {
        this.stopUpdateCycle()
        this.$alarm.stop()
      } else {
        this.updateTimeoutDelay = config.mapUpdateDelay.base
        this.startUpdateCycle()
        if (this.isLoggedIn && !this.$alarm.isStarted) {
          this.$alarm.start()
        }
      }
    },

    onMapMove() {
      this.updateTimeoutDelay = config.mapUpdateDelay.base
      if (Date.now() - this.lastUpdated > this.updateTimeoutDelay) {
        this.startUpdateCycle()
      }
      this.$alarm.resetDelay()
    },

    startUpdateCycle() {
      if (this.isLoggedIn) {
        this.stopUpdateCycle()
        this.updateTimeoutId = 0
        this.updateMapService()
      }
    },

    stopUpdateCycle() {
      window.clearTimeout(this.updateTimeoutId)
      this.updateTimeoutId = null
    },

    async updateMapService() {
      if (!this.updating) {
        this.updating = true
        await this.$map.updateAllDataLayers()
        this.$store.commit("layers/update")
        this.updateTimeoutDelay = Math.min(
          this.updateTimeoutDelay * config.mapUpdateDelay.factor,
          config.mapUpdateDelay.max
        )
        if (this.updateTimeoutId !== null) {
          window.clearTimeout(this.updateTimeoutId)
          this.updateTimeoutId = window.setTimeout(async () => {
            this.updateMapService()
          }, this.updateTimeoutDelay)
        }
        this.updating = false
      }
    },
    getMeanForVisibleSites(layer) {
      const siteValues = this.sitesVisible
        .map(site => layer.getValue(site))
        .filter(m => !isNaN(m.value))
      const value = siteValues.reduce((acc, mean) => acc + mean.value, 0) / siteValues.length
      const unit = siteValues[0]?.unit ?? ""
      return { value, unit }
    },
    showNextLayer() {
      const index = this.dataLayerList.findIndex(layer => layer.id === this.currentId)
      if (index !== -1) {
        const nextIndex = (index + 1) % this.dataLayerList.length
        this.$store.commit("layers/setCurrentDataLayer", this.dataLayerList[nextIndex])
      } else if (this.dataLayerList.length) {
        this.$store.commit("layers/setCurrentDataLayer", this.dataLayerList[0])
      }
    },
  },
}
</script>
