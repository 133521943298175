import { BaseModel, Field } from "~/lib/Model"

export class Geodata extends BaseModel {
  get fields() {
    return {
      id: Field.String(),
      dir: Field.String(),
      module: Field.String(),
      name: Field.String(),
      bbox: Field.List(Field.Number()),
      geojson: Field.Object({ defaultValue: null }),
    }
  }

  get path() {
    return `${this.dir}/${this.id}`
  }

  get latlngBounds() {
    return [
      [this.bbox[1], this.bbox[0]],
      [this.bbox[3], this.bbox[2]],
    ]
  }

  get hasGeoJSON() {
    return this.geojson !== null
  }
}
