var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "w-full flex justify-center space-x-2" },
        [
          _c(
            "FcButton",
            {
              staticClass: "rounded-full bg-surface px-4",
              attrs: { icon: "star" },
              on: {
                click: function($event) {
                  return _vm.toggleFavoriteModal()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("favorites.title")) + " ")]
          ),
          _c(
            "FcButton",
            {
              staticClass: "rounded-full bg-surface px-4",
              attrs: { icon: "search" },
              on: {
                click: function($event) {
                  return _vm.toggleSearchModal()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("siteSearch.label")) + " ")]
          )
        ],
        1
      ),
      _c(
        "FcModal",
        {
          attrs: { md: "" },
          model: {
            value: _vm.isFavoriteModalShown,
            callback: function($$v) {
              _vm.isFavoriteModalShown = $$v
            },
            expression: "isFavoriteModalShown"
          }
        },
        [_c("FavoritesCard", { staticClass: "m-auto w-4/5" })],
        1
      ),
      _c(
        "FcModal",
        {
          attrs: { md: "" },
          model: {
            value: _vm.isSearchModalShown,
            callback: function($$v) {
              _vm.isSearchModalShown = $$v
            },
            expression: "isSearchModalShown"
          }
        },
        [
          _c("FcInputAutoComplete", {
            staticClass: "m-auto w-4/5",
            attrs: { items: _vm.searchItems },
            on: {
              input: function(site) {
                return site && _vm.$map.goToSite(site)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function() {
                  return [
                    _c("v-icon", {
                      staticClass: "mr-1",
                      attrs: { name: "search" }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("siteSearch.label")) + " ")
                  ]
                },
                proxy: true
              },
              {
                key: "entry",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("div", [_vm._v(_vm._s(item.value.name))]),
                    _c("div", { staticClass: "text-xs" }, [
                      _vm._v(_vm._s(item.value.address))
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }