var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "fc-modal fixed z-10 inset-0",
      attrs: {
        "aria-labelledby": "fc-modal__title",
        role: "dialog",
        "aria-modal": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "fc-modal__overlay fixed inset-0 flex items-center justify-center",
          class: { "opacity-0": !_vm.fade },
          attrs: { "aria-hidden": "true" },
          on: {
            click: function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.onOverlayClick.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "fc-modal__container min-h-0 w-full max-w-full overflow-auto overscroll-contain rounded",
              class: [
                {
                  "max-w-screen-sm": _vm.sm,
                  "max-w-screen-md": _vm.md,
                  "max-w-screen-lg": _vm.lg,
                  "max-w-screen-xl": _vm.xl
                },
                _vm.divClass
              ]
            },
            [
              _vm.eager || _vm.show
                ? _vm._t("default", null, { modal: this })
                : _vm._e()
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }