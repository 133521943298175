import { deserialize } from "~/lib/VuexPersist"
import { commit, set, updateList, removeFromList } from "~/lib/VuexHelpers"

import { setLocale } from "~/i18n"
import Config from "~/config"

const state = () => ({
  locale: "en",
  theme: "current",
  minGeoZoom: 11,
  lastZoom: Config.mapData.defaultZoom,
  lastCoordinates: Config.mapData.defaultCoordinates.slice(),
  favoriteSites: [],
  collapsedCards: [],
  cardOrder: ["siteStatus", "dataLayers", "favorites", "alarms", "geoLayers"],
})

const getters = {
  isCardCollapsed: state => key => state.collapsedCards.includes(key),
}

const mutations = {
  deserialize,

  setTheme: set("theme"),
  setMinGeoZoom: set("minGeoZoom"),

  setMapPosition(state, { coordinates, zoom }) {
    state.lastZoom = zoom
    state.lastCoordinates = coordinates
  },

  setLocale(state, locale) {
    if (setLocale(locale)) {
      state.locale = locale
    }
  },

  addFavoriteSite: updateList("favoriteSites"),
  removeFavoriteSite: removeFromList("favoriteSites"),

  collapseCard: updateList("collapsedCards"),
  expandCard: removeFromList("collapsedCards"),

  setCardOrder: set("cardOrder"),
}

const actions = {
  init({ commit, state }) {
    commit("setLocale", state.locale)
  },
  addFavoriteSite: commit("addFavoriteSite"),
  removeFavoriteSite: commit("removeFavoriteSite"),
  collapseCard: commit("collapseCard"),
  expandCard: commit("expandCard"),
  setCardOrder: commit("setCardOrder"),
}

export default {
  namespaced: true,
  persist: true,
  state,
  getters,
  mutations,
  actions,
}
