<template>
  <div class="input-container relative" :class="{ populated: textValue }">
    <div
      class="textarea-container py-4 bg-input rounded focus-within:ring-2 ring-input-accented transition"
    >
      <textarea
        v-bind="{ ...$attrs, ...$props }"
        v-model="textValue"
        class="w-full px-4 bg-input outline-none"
        :class="{ 'resize-none': noResize }"
      />
    </div>
    <label class="absolute top-0 left-0 p-4 text-muted pointer-events-none transition-all">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    noResize: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    textValue: "",
  }),
  watch: {
    textValue() {
      this.$emit("input", this.textValue)
    },
  },
  created() {
    this.textValue = this.value
  },
}
</script>

<style lang="postcss" scoped>
.input-container.populated label,
.input-container:focus-within label {
  padding-top: theme("spacing.1");
  font-size: theme("fontSize.xs");
}
.input-container.populated .textarea-container,
.input-container:focus-within .textarea-container {
  padding-top: theme("spacing.6");
  padding-bottom: theme("spacing.2");
}
</style>
