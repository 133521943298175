import { DataLayer, Threshold } from "."

const layers = [
  DataLayer.load({
    active: true,
    name: "Ensiö tulolämpötila",
    module: "fiksu",
    dataSources: [
      {
        priorityList: [
          { device: "101VAK01.A1", position: "101VAK01.A1.100TE41.M" },
          { device: "101CB01_A1", position: "101CB01-A1.100TE41.M" },
          { device: "101CB01_A1", position: "101CB01-A Wilot.100TE41.M" },
        ],
      },
    ],
  }),
  DataLayer.load({
    active: true,
    name: "Ensiö paluulämpötila",
    module: "fiksu",
    dataSources: [
      {
        priorityList: [
          { device: "101VAK01.A1", position: "101VAK01.A1.100TE42.M" },
          { device: "101CB01_A1", position: "101CB01-A1.100TE42.M" },
          { device: "101CB01_A1", position: "101CB01-A Wilot.100TE42.M" },
        ],
      },
    ],
    thresholds: [
      { type: Threshold.TYPE_GOOD, op: Threshold.OP_LT, value: 50 },
      { type: Threshold.TYPE_BAD, op: Threshold.OP_GTE, value: 60 },
      { type: Threshold.TYPE_BAD, op: Threshold.OP_LT, value: 0 },
    ],
  }),
  DataLayer.load({
    active: true,
    name: "Ensiö jäähtymä",
    module: "fiksu",
    dataSources: [
      {
        priorityList: [
          { device: "101VAK01.A1", position: "101VAK01.A1.100TE41.M" },
          { device: "101CB01_A1", position: "101CB01-A1.100TE41.M" },
          { device: "101CB01_A1", position: "101CB01-A Wilot.100TE41.M" },
        ],
      },
      {
        priorityList: [
          { device: "101VAK01.A1", position: "101VAK01.A1.100TE42.M" },
          { device: "101CB01_A1", position: "101CB01-A1.100TE42.M" },
          { device: "101CB01_A1", position: "101CB01-A Wilot.100TE42.M" },
        ],
      },
    ],
    calculation: DataLayer.CALC_DIFFERENCE,
    thresholds: [
      { type: Threshold.TYPE_GOOD, op: Threshold.OP_GTE, value: 0 },
      { type: Threshold.TYPE_BAD, op: Threshold.OP_LT, value: 0 },
    ],
  }),
  DataLayer.load({
    active: true,
    name: "Ensiö tulopaine",
    module: "fiksu",
    dataSources: [
      {
        priorityList: [
          { device: "101VAK01.A1", position: "101VAK01.A1.100PE41.1.M" },
          { device: "101CB01_A1", position: "101CB01-A1.100PE41.1.M" },
          { device: "101CB01_A1", position: "101CB01-A Wilot.100PE41.1.M" },
          { device: "FiksuHUB", position: "100PE41.1.M" },
        ],
      },
    ],
  }),
  DataLayer.load({
    active: true,
    name: "Ensiö paluupaine",
    module: "fiksu",
    dataSources: [
      {
        priorityList: [
          { device: "101VAK01.A1", position: "101VAK01.A1.100PE42.M" },
          { device: "101CB01_A1", position: "101CB01-A1.100PE42.M" },
          { device: "101CB01_A1", position: "101CB01-A Wilot.100PE42.M" },
          { device: "FiksuHUB", position: "100PE42.M" },
        ],
      },
    ],
  }),
  DataLayer.load({
    active: true,
    name: "Ensiö paine-ero",
    module: "fiksu",
    dataSources: [
      {
        priorityList: [
          { device: "101VAK01.A1", position: "101VAK01.A1.100PE41.1.M" },
          { device: "101CB01_A1", position: "101CB01-A1.100PE41.1.M" },
          { device: "101CB01_A1", position: "101CB01-A Wilot.100PE41.1.M" },
          { device: "FiksuHUB", position: "100PE41.1.M" },
        ],
      },
      {
        priorityList: [
          { device: "101VAK01.A1", position: "101VAK01.A1.100PE42.M" },
          { device: "101CB01_A1", position: "101CB01-A1.100PE42.M" },
          { device: "101CB01_A1", position: "101CB01-A Wilot.100PE42.M" },
          { device: "FiksuHUB", position: "100PE42.M" },
        ],
      },
    ],
    calculation: DataLayer.CALC_DIFFERENCE,
  }),
  DataLayer.load({
    active: true,
    name: "Ensiö virtaama",
    module: "fiksu",
    dataSources: [
      {
        priorityList: [
          { device: "101VAK01.A1", position: "ScaledValues_100QQ01.ActFlowScaled" },
          { device: "101CB01_A1", position: "ScaledValues_100QQ01.ActFlowScaled" },
          {
            device: "101CB01_A1",
            position: "Teltonika.MBTCP_ADDR192_168_14_1_Teltonika_ActualFlow_100QQ01",
          },
          { device: "FiksuHUB", position: "100QQ01.ActFlowScaled" },
          { device: "101VAK01.A2", position: "HiguelMBus.MB1_ADDR1_HiguelMBus_ActualFlow_100QQ01" },
        ],
      },
    ],
  }),
  DataLayer.load({
    active: true,
    name: "Ensiö teho",
    module: "fiksu",
    dataSources: [
      {
        priorityList: [
          { device: "101VAK01.A1", position: "ScaledValues_100QQ01.ActualPowerScaled" },
          { device: "101CB01_A1", position: "ScaledValues_100QQ01.ActualPowerScaled" },
          {
            device: "101CB01_A1",
            position: "Teltonika.MBTCP_ADDR192_168_14_1_Teltonika_ActualPower_100QQ01",
          },
          { device: "FiksuHUB", position: "100QQ01.ActualPowerScaled" },
          {
            device: "101VAK01.A2",
            position: "HiguelMBus.MB1_ADDR1_HiguelMBus_ActualPower_100QQ01",
          },
        ],
      },
    ],
  }),
  DataLayer.load({
    active: true,
    name: "RTD-info",
    module: "fiksu",
    dataSources: [
      {
        priorityList: [
          { device: "Programmatic points", position: "PP_RTD_FACTOR" },
          { device: "Ohjelmalliset pisteet", position: "PP_RTD_FACTOR" },
        ],
      },
    ],
    thresholds: [
      { type: Threshold.TYPE_GOOD, op: Threshold.OP_GTE, value: 3 },
      { type: Threshold.TYPE_BAD, op: Threshold.OP_LT, value: 2 },
    ],
  }),
  DataLayer.load({
    active: true,
    name: "Ulkolämpötila",
    module: "fiksu",
    dataSources: [
      {
        priorityList: [
          { device: "101VAK01.A2", position: "101VAK01.A2.101TE00.M" },
          { device: "101CB01_A1", position: "101CB01-A Wilot.101TE00.M" },
          { device: "101CB01_A1", position: "101CB01-AW.101TE00.M" },
          { device: "101CB01_A1", position: "101CB01-A1.101TE00.M" },
          { device: "FiksuHUB", position: "OutdoorTempMeas.101TE00.M" },
        ],
      },
    ],
    thresholds: [
      { type: Threshold.TYPE_GOOD, op: Threshold.OP_GT, value: -50 },
      { type: Threshold.TYPE_BAD, op: Threshold.OP_LTE, value: -50 },
    ],
  }),
]

export default layers
