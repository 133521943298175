import { commit, set } from "~/lib/VuexHelpers"

const state = () => ({
  view: "MapView",
})

const getters = {
}

const mutations = {
  setView: set("view"),
}

const actions = {
  setView: commit("setView"),
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
