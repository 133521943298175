var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FcCard", { staticClass: "rounded" }, [
    _c("div", { staticClass: "space-y-2 flex flex-col" }, [
      _c(
        "div",
        { staticClass: "space-y-2 divide-y divide-surface-accented" },
        _vm._l(_vm.searchedSites, function(site) {
          return _c(
            "div",
            { key: site.id, staticClass: "pt-2" },
            [
              _c(
                "FcButton",
                {
                  staticClass: "w-full p-0 normal-case",
                  attrs: {
                    "text-class":
                      "text-left items-center w-full font-normal space-y-2 text-xs"
                  },
                  on: {
                    click: function($event) {
                      return _vm.goToSite(site)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "flex items-start space-x-2" }, [
                    _c("div", { staticClass: "flex-1" }, [
                      _c("div", { staticClass: "font-bold text-sm" }, [
                        _vm._v(_vm._s(site.name))
                      ]),
                      _c("div", [_vm._v(_vm._s(site.address))])
                    ]),
                    _c("div", { staticClass: "flex-none text-lg font-bold" }, [
                      _vm._v(
                        " " + _vm._s(_vm.alarmsBySite[site.id].length) + " "
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "border-alert border-opacity-30 border-l space-y-2 ml-2 pl-2"
                    },
                    _vm._l(
                      _vm.searchedAlarmsBySiteId(site.id).entries(),
                      function(ref) {
                        var index = ref[0]
                        var alarm = ref[1]
                        return _c(
                          "div",
                          { key: index, staticClass: "flex-1" },
                          [
                            _c("v-icon", {
                              staticClass: "text-alert",
                              attrs: { name: "exclamation-triangle" }
                            }),
                            _c("span", { staticClass: "ml-1 font-bold" }, [
                              _vm._v(_vm._s(alarm.device))
                            ]),
                            _c(
                              "div",
                              { staticClass: "ml-5 text-clip overflow-hidden" },
                              [_vm._v(_vm._s(alarm.message))]
                            )
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                ]
              )
            ],
            1
          )
        }),
        0
      ),
      !_vm.alarmSites.length
        ? _c("div", { staticClass: "text-muted text-sm italic" }, [
            _vm._v(" " + _vm._s(_vm.$t("alarms.noAlarms")) + " ")
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }