var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InlineSvg", {
    staticClass: "fiksuweb-logo",
    class: { dark: _vm.dark },
    attrs: { src: _vm.logo, role: "img", alt: _vm.alt }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }