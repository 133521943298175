const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

// https://stackoverflow.com/a/55435856
function* chunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n)
  }
}

function mapObject(obj, op) {
  return Object.keys(obj).reduce((acc, key) => ((acc[key] = op(obj[key], key)), acc), {})
}

export { sleep, chunks, mapObject }
