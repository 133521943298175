const loadState = key => {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (error) {
    return null
  }
}

const persistState = (key, state) => {
  try {
    localStorage.setItem(key, JSON.stringify(state))
  } catch (error) {
    console.error(`Failed to persist state ${key}`, error)
  }
}

export default function createPersistPlugin({ modules = [], prefix = "" } = {}) {
  return store => {
    for (const module of modules) {
      const loaded = loadState(prefix + module)
      // if (loaded !== null) {
      store.commit(`${module}/deserialize`, loaded ?? {})
      // }
    }

    store.subscribe((mutation, state) => {
      const module = mutation.type.slice(0, mutation.type.indexOf("/"))
      if (modules.includes(module)) {
        const data = store.getters[`${module}/serialized`] ?? state[module]
        persistState(prefix + module, data)
      }
    })
  }
}

export function deserialize(state, loadedState) {
  for (const key of Object.keys(state)) {
    if (key in loadedState) {
      state[key] = loadedState[key]
    }
  }
}
