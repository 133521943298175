import { BaseModel, Field } from "~/lib/Model"
import { Location } from "./Location"

export class Site extends BaseModel {
  get fields() {
    return {
      id: Field.String(),
      module: Field.String(),
      name: Field.String(),
      address: Field.String(),
      clientId: Field.String({ defaultValue: "" }),
      urlFiksuWeb: Field.String({ defaultValue: "" }),
      urlEnergyScreen: Field.String({ defaultValue: "" }),
      isOnline: Field.Boolean(),
      location: Field.Nested(Location),
      locationCustom: Field.Nested(Location),
    }
  }

  get primaryLocation() {
    return this.locationCustom || this.location
  }

  get geoJSON() {
    const loc = this.primaryLocation
    return {
      type: "Feature",
      properties: {
        id: this.id,
        name: this.name,
      },
      geometry: {
        type: "Point",
        coordinates: [loc.longitude, loc.latitude],
      },
    }
  }

  isLocatedWithin(top, left, bottom, right) {
    const loc = this.primaryLocation
    return (
      loc.latitude < top && loc.latitude > bottom && loc.longitude > left && loc.longitude < right
    )
  }
}
