<template>
  <CollapsibleCard class="rounded" name="favorites">
    <template #header>
      {{ $t("favorites.title") }}
    </template>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-1 lg:gap-3">
      <FcButton
        v-for="site of favoriteSites"
        :key="site.id"
        icon="regular/star"
        class="hover:bg-primary hover:bg-opacity-20 p-1 min-w-min flex items-start text-left justify-start lg:flex-col lg:text-center lg:justify-center lg:space-x-0"
        text-class="font-normal text-xs"
        @click="goToSite(site)"
      >
        {{ site.name }}
      </FcButton>
      <div v-if="!favoriteSites.length" class="col-span-2 text-muted text-sm italic">
        {{ $t("favorites.guide") }}
      </div>
    </div>
  </CollapsibleCard>
</template>

<script>
import { mapGetters } from "vuex"
import CollapsibleCard from "~/components/menus/CollapsibleCard.vue"

export default {
  components: { CollapsibleCard },
  computed: {
    ...mapGetters({
      sitesVisible: "map/sitesVisible",
      getSite: "map/getSite",
    }),
    favoriteSites() {
      return this.$store.state.preferences.favoriteSites.map(id => this.getSite(id)).filter(Boolean)
    },
  },
  methods: {
    goToSite(site) {
      this.$map.goToSite(site)
    },
  },
}
</script>
