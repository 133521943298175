import Vue from "vue"

const themeMap = new Map()
themeMap.set("light", { dark: false, className: "light" })
themeMap.set("dark", { dark: true, className: "dark" })
themeMap.set("current", { dark: false, className: "current" })

const applyTheme = themeName => {
  const theme = themeMap.get(themeName)
  if (!theme) {
    console.warn(`Theme "${theme}" does not exist.`)
    return
  }

  for (const t of themeMap.values()) {
    document.documentElement.classList.remove(t.className)
  }
  document.documentElement.classList.add(theme.className)

  attrs.current = themeName
  attrs.dark = theme.dark
}

const setTheme = themeName => {
  if (themeName === "current") {
    if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
      applyTheme("dark")
    } else {
      applyTheme("light")
    }
  } else {
    applyTheme(themeName)
  }
}

const attrs = Vue.observable({
  current: "current",
  dark: false,
  themes: [...themeMap.keys()],
  setTheme,
})

export default function({ $store }, inject) {
  setTheme($store.state.preferences.theme)

  try {
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", evt => {
      if (attrs.current === "current") {
        applyTheme(evt.matches ? "dark" : "light")
      }
    })
  } catch (error) {
    console.warn(error)
  }

  inject("theme", attrs)
}
