var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FcCard",
    {
      scopedSlots: _vm._u(
        [
          {
            key: "header",
            fn: function() {
              return [
                _c("div", { staticClass: "cursor-pointer flex space-x-2" }, [
                  _c("div", { staticClass: "flex-grow" }, [_vm._t("header")], 2)
                ])
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [_vm._t("footer")]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }