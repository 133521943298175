export default class SetWithOps extends Set {
  static isValid(set) {
    return set && set instanceof Set
  }

  union(set) {
    if (!SetWithOps.isValid(set)) {
      throw new TypeError()
    }
    return new SetWithOps([...this, ...set])
  }

  intersection(set) {
    if (!SetWithOps.isValid(set)) {
      throw new TypeError()
    }
    return new SetWithOps([...this].filter(x => set.has(x)))
  }

  difference(set) {
    if (!SetWithOps.isValid(set)) {
      throw new TypeError()
    }
    return new SetWithOps([...this].filter(x => !set.has(x)))
  }

  isSubsetOf(set) {
    if (!SetWithOps.isValid(set)) {
      throw new TypeError()
    }
    return this.size <= set.size && [...this].every(x => set.has(x))
  }

  isSupersetOf(set) {
    if (!SetWithOps.isValid(set)) {
      throw new TypeError()
    }
    return this.size >= set.size && [...set].every(x => this.has(x))
  }
}
