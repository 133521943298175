import Config from "~/config"

export default class AlarmService {
  constructor($api, $store) {
    this.$api = $api
    this.$store = $store
    this.isUpdating = false
    this.isStarted = false
    this.timeout = null
    this.currentDelay = Config.alarmUpdateDelay.base

    if (!Config.isFeatureEnabled.alarms) {
      this.start = function() {}
    }
  }

  get locale() {
    switch (this.$store.state.preferences.locale) {
      case "fi":
        return "fi-fi"
      case "en":
      default:
        return "en-gb"
    }
  }

  resetDelay() {
    this.currentDelay = Config.alarmUpdateDelay.base
  }

  start() {
    if (!this.isStarted) {
      this.isStarted = true
      this.isUpdating = false
      window.clearTimeout(this.timeout)
      this.resetDelay()
      this.update()
    }
  }

  stop() {
    if (this.isStarted) {
      this.isStarted = false
      window.clearTimeout(this.timeout)
      this.resetDelay()
    }
  }

  async update() {
    if (!this.isUpdating && this.isStarted) {
      this.isUpdating = true
      const alarms = await this.$api.getActiveAlarms(this.locale)
      const onlineAlarms = alarms.filter(
        a => this.$store.getters["map/getSite"](a.siteId)?.isOnline
      )
      await this.$store.dispatch("alarm/setAlarms", onlineAlarms)
      if (this.isStarted) {
        if (this.timeout !== null) {
          window.clearTimeout(this.timeout)
        }
        this.timeout = window.setTimeout(() => {
          this.update()
        }, this.currentDelay)
        this.currentDelay = Math.min(
          this.currentDelay * Config.alarmUpdateDelay.factor,
          Config.alarmUpdateDelay.max
        )
        this.isUpdating = false
      }
    }
  }
}
